import React, { useEffect } from 'react';
import { DataGrid, GridActionsCellItem, GridColDef } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import SaveAsIcon from '@mui/icons-material/SaveAs';

import { FormMasterData, Rule, RuleFilter } from '../types';
import { constants } from 'common/constants';
import { hasPermission, isStringInArray, sortRows } from 'common/helpers/utils';
import NoRowsOverlay from 'common/components/NoRowsOverlay';
import IconButton from '@mui/material/IconButton';
import RulesFilter from './RulesFilter';
import Card from '@mui/material/Card';
import { useContentStyles } from 'common/styles/useContentStyles';
import { FilterDisplayOption, RuleStatus } from 'common/enums';
import CustomToolbar from 'common/components/CustomToolbar';

type RulesTableProps = {
  data: Rule[];
  handleEditRule: (rule: Rule) => void;
  handleDeleteRule: (rule: Rule) => void;
  handleFavoriteRule: (rule: Rule) => void;
  formMasterData: FormMasterData;
  changeRuleStatus: (rule: Rule) => void;
};

const getFilteredRulesRows = (tableRows: Rule[], filterValues: RuleFilter) => {
  const filteredRules = tableRows.filter((rule) => {
    const isUserGroupsValid =
      filterValues.userGroups.length > 0
        ? filterValues.userGroups.includes(rule.userGroupId)
        : true;

    const isDeviceGroupsValid =
      filterValues.devicesGroups.length > 0
        ? filterValues.devicesGroups.includes(rule.deviceGroupId)
        : true;

    const isAccessProfilesValid =
      filterValues.accessProfiles.length > 0
        ? isStringInArray(filterValues.accessProfiles, rule.accessProfile ?? '')
        : true;

    const isStatusValid =
      filterValues.statuses.length > 0
        ? filterValues.statuses.includes(rule.status ?? '')
        : true;

    return (
      isAccessProfilesValid &&
      isDeviceGroupsValid &&
      isStatusValid &&
      isUserGroupsValid
    );
  });
  return filteredRules;
};

const RulesTable: React.FC<RulesTableProps> = (props) => {
  const classes = useContentStyles();
  const [rows, setRows] = React.useState<Rule[]>(props.data);
  const [filteredRows, setFilteredRows] = React.useState<Rule[]>(props.data);
  const [openFilter, setOpenFilter] = React.useState(false);
  const defaultFilterValues = React.useMemo(
    () => ({
      userGroups: [],
      devicesGroups: [],
      accessProfiles: [],
      statuses: [],
    }),
    [],
  );
  const [filters, setFilters] = React.useState<RuleFilter>(defaultFilterValues);
  const [selectedSortOption, setSelectedSortOption] = React.useState(
    FilterDisplayOption.ALL,
  );
  const getSortedRows = React.useCallback(
    (inputRows: Rule[], selectedValue?: FilterDisplayOption) => {
      const records = sortRows([...inputRows], selectedValue);
      setRows(records as Rule[]);
    },
    [],
  );

  useEffect(() => {
    setFilteredRows(props.data);
    getSortedRows(props.data);
  }, [getSortedRows, props.data]);

  const editRule = React.useCallback(
    (gridRow: any) => () => {
      props.handleEditRule(gridRow.row);
    },
    [props],
  );

  const deleteRule = React.useCallback(
    (gridRow: any) => () => {
      props.handleDeleteRule(gridRow.row);
    },
    [props],
  );

  const handleFavoriteClick = React.useCallback(
    (rule: Rule) => {
      props.handleFavoriteRule(rule);
    },
    [props],
  );

  const getRuleApplicableStatus = (rule: Rule) => {
    const currentDate = new Date();
    if (rule?.startDate && rule?.endDate) {
      const ruleStartDate = new Date(rule?.startDate);
      const ruleEndDate = new Date(rule?.endDate);
      if (currentDate >= ruleStartDate && currentDate <= ruleEndDate) {
        return RuleStatus.ACTIVE;
      } else if (currentDate < ruleStartDate) {
        return RuleStatus.FUTURE;
      } else {
        return RuleStatus.EXPIRED;
      }
    } else {
      return RuleStatus.INCOMPLETE;
    }
  };

  const changeRuleStatus = React.useCallback(
    (rule: Rule, status: RuleStatus) => {
      props.changeRuleStatus({ ...rule, status: status });
    },
    [props],
  );

  const columns = React.useMemo<GridColDef[]>(
    () => [
      {
        field: 'name',
        type: 'string',
        headerName: 'Trust Rule Name',
        flex: 1,
        renderCell: (params) => (
          <>
            <IconButton
              onClick={() => handleFavoriteClick(params.row)}
              sx={{ paddingLeft: 0 }}>
              {params.row.isFavorite ? (
                <StarIcon color="secondary" />
              ) : (
                <StarOutlineIcon />
              )}
            </IconButton>
            {params.row.name}
          </>
        ),
      },
      {
        field: 'userGroupName',
        type: 'string',
        headerName: 'Users',
        flex: 1,
        // renderCell: (params) => {
        //   const groups = (params.row.userGroups || []) as UserGroup[];
        //   return groups.map((obj) => obj.name).join(', ');
        // },
      },
      // {
      //   field: 'users',
      //   type: 'string',
      //   headerName: 'User(s)',
      //   flex: 1,
      //   renderCell: (params) => {
      //     const users = (params.row.users || []) as User[];
      //     return users.map((obj) => `${obj.firstName} ${obj.lastName}`).join(', ');
      //   },
      // },
      {
        field: 'deviceGroupName',
        type: 'string',
        headerName: 'Devices',
        flex: 1,
        // renderCell: (params) => {
        //   const groups = (params.row.deviceGroups || []) as DeviceGroup[];
        //   return groups.map((obj) => obj.name).join(', ');
        // },
      },
      // {
      //   field: 'devices',
      //   type: 'string',
      //   headerName: 'Device(s)',
      //   flex: 1,
      //   renderCell: (params) => {
      //     const users = (params.row.devices || []) as Device[];
      //     return users.map((obj) => obj.name).join(', ');
      //   },
      // },
      {
        field: 'accessProfile',
        type: 'string',
        headerName: 'Local Access Profile',
        flex: 1,
      },
      {
        field: 'accessWindow',
        type: 'string',
        headerName: 'Access Window',
        width: 400,
        // flex: 1,
        renderCell: (params) => {
          return (
            <div
              dangerouslySetInnerHTML={{ __html: params.row.accessWindow }}
            />
          );
        },
      },
      // {
      //   field: 'zoneName',
      //   type: 'string',
      //   headerName: 'Trust Zone',
      //   flex: 1,

      // },
      {
        field: 'status',
        type: 'string',
        headerName: 'Status',
        // flex: 1,
        width: 100,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        type: 'actions',
        width: 100,
        // flex: 1,
        getActions: (params) => {
          const actions = [
            <GridActionsCellItem
              icon={<EditIcon color="info" />}
              label="Edit"
              disabled={!hasPermission('trust.zones', 'write')}
              onClick={editRule(params)}
              showInMenu
              key="editRule"
            />,
            <GridActionsCellItem
              icon={<DeleteIcon color="info" />}
              label="Delete"
              disabled={!hasPermission('trust.zones', 'delete')}
              onClick={deleteRule(params)}
              showInMenu
              key="deleteRule"
            />,
          ];
          if (params.row.status && params.row.status === RuleStatus.ACTIVE) {
            actions.push(
              <GridActionsCellItem
                icon={<SaveAsIcon color="info" />}
                label="Disable"
                disabled={!hasPermission('trust.zones', 'write')}
                onClick={() =>
                  changeRuleStatus(params.row, RuleStatus.DISABLED)
                }
                showInMenu
                key="disableRule"
              />,
            );
          }
          if (params.row.status && params.row.status === RuleStatus.DISABLED) {
            actions.push(
              <GridActionsCellItem
                icon={<SaveAsIcon color="info" />}
                label={getRuleApplicableStatus(params.row)}
                disabled={!hasPermission('trust.zones', 'write')}
                onClick={() =>
                  changeRuleStatus(
                    params.row,
                    getRuleApplicableStatus(params.row),
                  )
                }
                showInMenu
                key="changeStatus"
              />,
            );
          }
          return actions;
        },
      },
    ],
    [changeRuleStatus, deleteRule, editRule, handleFavoriteClick],
  );
  const handleFilterClick = () => {
    setOpenFilter(true);
  };
  const handleApplyFilter = (filterValues: RuleFilter) => {
    setFilters(filterValues);
    const filteredRules = getFilteredRulesRows(props.data, filterValues);
    setFilteredRows(filteredRules);
    getSortedRows(filteredRules, selectedSortOption);
  };

  const handleClearFilter = () => {
    setFilteredRows(props.data);
    getSortedRows(props.data, selectedSortOption);
  };

  const handleCancelFilter = () => {
    setFilteredRows(props.data);
    getSortedRows(props.data, selectedSortOption);
    setOpenFilter(false);
    setFilters(defaultFilterValues);
  };

  const handleSortOptionChange = (selectedValue: FilterDisplayOption) => {
    setSelectedSortOption(selectedValue);
    getSortedRows([...filteredRows], selectedValue);
  };

  return (
    <React.Fragment>
      {openFilter && (
        <Card elevation={0} className={classes.contentSection}>
          <RulesFilter
            onApplyFilter={handleApplyFilter}
            handleCancelFilter={handleCancelFilter}
            handleClearFilter={handleClearFilter}
            filters={filters}
            formMasterData={props.formMasterData}
          />
        </Card>
      )}
      <DataGrid
        columns={columns}
        rows={rows}
        getRowId={(row) => row.ruleId}
        pageSizeOptions={constants.PAGE_SIZE_OPTIONS}
        initialState={{
          pagination: { paginationModel: constants.PAGINATION_MODEL },
        }}
        autoHeight
        slots={{
          toolbar: () => (
            <CustomToolbar
              handleFilterClick={handleFilterClick}
              isDisplaySortOptions
              handleSortOptionChange={(selectedOption) =>
                handleSortOptionChange(selectedOption)
              }
              sortOptionValue={selectedSortOption}
            />
          ),
          noRowsOverlay: () => (
            <NoRowsOverlay
              hasAccess={hasPermission('trust.list', 'read')}
              name="Trust Rules"
            />
          ),
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        sx={{
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: '600',
          },
        }}
        disableRowSelectionOnClick
      />
    </React.Fragment>
  );
};

export default RulesTable;
