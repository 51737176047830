import { DayType } from 'pages/trust/types';
import { IdentityAccessProvider, RuleTemplate } from './enums';

export const constants = {
  TIMEOUT: 1000,
  PASSWORD_a_z_REGEX: /[a-z]+/,
  PASSWORD_A_Z_REGEX: /[A-Z]+/,
  PASSWORD_0_9_REGEX: /\d.*\d/,
  PASSWORD_SPECIAL_CHAR_REGEX: /[!"#$%&'()*+,./:;<=>?@[\\\]^_`{|}~-]+/,
  DIRECTORY_USERS_PATH: '/directory/users',
  DIRECTORY_GROUPS_PATH: '/directory/groups',
  SNACKBAR_AUTO_HIDE_TIME: 3000,
  PASSWORD_REGEX:
    /^(?=[\w!#$%&*+/<=>?@\\^~]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!#$%&()*+/<=>?@\\^_~])(?=.{8,}).*$/,
  EMAIL_REGEX:
    /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\dA-Za-z-]+\.)+[A-Za-z]{2,}))$/,
  GROUP_NAME_REGEX: /^[\w '#$&+./@-]{1,100}$/, //device and user group names
  NAME_REGEX: /^[\w '#$&+./@-\s]{1,100}$/, //device name, org name, username
  USER_NAME_REGEX: /^[A-Za-z-']{1,100}$/, //first name, last name
  ZIP_CODE_REGEX: /^[0-9]{5}(?:-[0-9]{4})?|[0-9]{6}$/,
  IP_REGEX: /^(\d{1,3}\.){3}\d{1,3}(\/(1[6-9]|2\d|3[0-2]))$/,
  NLB_IP_REGEX: /^(\d{1,3}\.){3}\d{1,3}$/,
  FQDN_REGEX:
    /^(([\dA-Za-z]|[\dA-Za-z][\dA-Za-z-]*[\dA-Za-z])\.)*([\dA-Za-z]|[\dA-Za-z][\dA-Za-z-]*[\dA-Za-z])$/,
  HOSTING_NAME_REGEX: /^(?!(aws|azure|gcp|salesforce|ibm)).*/i,

  HTTPS_URL_REGEX_: /^(https):\/\/[^ "]+$/,
  LATITUDE_REGEX: /^(-?([1-8]?[0-9](\.\d{1,9})?|90(\.0{1,9})?))$/,
  LONGITUDE_REGEX:
    /^(-?((180(\.0{1,9})?)|((1[0-7][0-9]|[0-9]{1,2})(\.\d{1,9})?)))$/,
  HEX_COLOR_CODE_REGEX: /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/,
  MAC_ADDRESS_REGEX: /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/,
  IP_ADDRESS_REGEX:
    /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
  MAIL_REGEX: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
  PHONE_REGEX: /^(?:\d{10}|\d{3}\s?\d{3}\s?\d{4})$/,
  NUMERIC_VALUE_REGEX: /^(?:\d*\.\d+|\d+\.\d*|\d+)$/,
  HOME_PAGE_PATH: '/portal/sites/summary',
  DEVICE_TYPE_GATEWAY: 'gateway',
  DAYS: [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ] as DayType[],
  WEEK_DAYS: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
  DAYS_TEMPLATE: [
    RuleTemplate.ALL_DAYS,
    RuleTemplate.WEEK_DAYS,
    RuleTemplate.CUSTOM,
  ],
  PAGINATION_MODEL: { pageSize: 15 },
  PAGE_SIZE_OPTIONS: [15, 25, 50, 100],
  ACCESS_PROFILES: [
    'Administrator',
    'Remote User',
    'User-SSH',
    'Domain Administrator',
    'Administrator-SSH',
  ],
  POWER_PRODUCTION_UNIT: 'kW',
  DRAWER_WIDTH: 250,
  LOGOUT_PATH: '/login',
  URL_REGEX: /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/,
  ACCESS_PROVIDERS: [IdentityAccessProvider.AZURE, IdentityAccessProvider.OKTA],
  LOGIN_PATH: '/login',
  AZURE_LOGIN_PATH: '/azure-login',
  DYNAMIC_GROUP_CONFIRM_MESSAGE:
    'You are creating a dynamic group – is this what you want?',
  STATIC_GROUP_CONFIRM_MESSAGE:
    'You are creating a static group – is this what you want?',
  DYNAMIC_GROUP_UPDATE_CONFIRM_MESSAGE:
    'You are updating a dynamic group – is this what you want?',
  STATIC_GROUP_UPDATE_CONFIRM_MESSAGE:
    'You are updating a static group – is this what you want?',
  ADMIN_ROLE_ID: '1',
  SUPER_ADMIN_ROLE_ID: '3',
  END_USER_ROLE_ID: '4',
  ADMIN_ROLE_NAME: 'admin',
  END_USER_ROLE_NAME: 'enduser',
  SETTINGS_ROUTE_STATE: 'settings',
  END_USER_ACCESS_DENIED_MENUS: [
    'users',
    'trust',
    'logging',
    'viz',
    'settings',
    'sites.groups',
    'devices.groups',
  ],
  ADMIN_END_USER_ROLE_PATTERN: /\b(?:admin|enduser|superadmin)\b/i,
  END_USER_ROLE_PATTERN: /\benduser\b/i,
  ADMIN_ROLE_PATTERN: /\b(?:admin|superadmin)\b/i,
  AZURE_USER_DEFAULT_PASSWORD: '@TEST1234',
  ALL_MENUS: [
    'users',
    'trust',
    'logging',
    'viz',
    'settings',
    'sites',
    'devices',
  ],
  END_USER_ACCESS_FEATURES: [
    'sites.summary',
    'sites.groups',
    'devices.summary',
    'devices.groups',
  ],
  ROLE_NAME_REGEX:
    /^(?:(?!(?:Admin|SuperAdmin|EndUser)\b)[\w '#$&+./@-\s]){1,100}$/i,
  GATEWAY_DEVICE_TYPE_PATTERN: /\bgateway\b/i,
  DEVICE_PORT_PATTERN: /^\d{1,5}$/,
  REPEAT_EVENT_PATTERN: /^\d{1,3}$/,
};
