import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Grid,
} from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

import TcSelectDropdown from 'common/components/TcSelectDropdown';
import { UserGroup } from './types';

type SelectGroupDialogProps = {
  open: boolean;
  groups: UserGroup[];
  onCancel: () => void;
  onSaveGroup: (groups?: string[]) => void;
  currentUserGroups?: string[];
};

const SelectGroupDialog: React.FC<SelectGroupDialogProps> = ({
  open,
  groups,
  onCancel,
  onSaveGroup,
  currentUserGroups,
}) => {
  const methods = useForm<{ groups?: string[] }>({
    defaultValues: { groups: currentUserGroups ?? [] },
    mode: 'onBlur',
  });
  const { handleSubmit } = methods;

  const handleSubmitGroup = handleSubmit(
    async (data: { groups?: string[] }) => {
      onSaveGroup(data.groups ?? []);
    },
  );
  return (
    <React.Fragment>
      <Dialog open={open}>
        <DialogContent>
          <DialogContentText>
            This user will automatically become part of the following list of
            groups. Please select the group.
          </DialogContentText>
          <FormProvider {...methods}>
            <form noValidate>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TcSelectDropdown
                    name="groups"
                    label="Groups"
                    multiple
                    options={groups}
                    optionKey="groupId"
                    optionLabel="name"
                  />
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} color="info" variant="outlined">
            {'Cancel'}
          </Button>
          <Button
            onClick={handleSubmitGroup}
            color="warning"
            autoFocus
            variant="contained">
            {'Save'}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
export default SelectGroupDialog;
