import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { CssBaseline, Breadcrumbs, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';

import { Site } from '../types';
import SiteSummaryTab from './Summary';
import apiClient from 'common/apiClientAxios';
import { setLoader, setSnackbarToast } from 'redux/UiStateSlice';
import { useContentStyles } from 'common/styles/useContentStyles';
import { siteDefaultValues } from '../sitesList/Sites';

// const demoConfigData = {
//   state: 'Connected',
//   rssi: '0.0dBm',
//   provider: 'VzW',
//   apn: 'we01.vzwstatic',
//   ip: '104.107.62.205',
//   number: '17077914215',
//   imei: 'xxxxxxxxxxxxx',
//   accessTechnology: '3G LTE',
//   model: 'MC7455',
//   manufacturer: 'Sierra Wireless, Inc.',
//   hardwareVersion: '1.0',
//   firmwareVersion:
//     'SWI9X30C_02.20.03.00 r6691 CARMD-EV-FRMWR2 2016/06/30 10:54:05',
// };
const SiteDetails: React.FC = () => {
  const classes = useContentStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  // const [value, setValue] = React.useState('1');
  const { site } = (location.state as { site: Site }) || {
    site: { ...siteDefaultValues },
  };
  const [siteData, setSiteData] = React.useState<Site>(site);
  //if location state has issues then redirect to list page
  useEffect(() => {
    if (!site || !site.siteId) {
      dispatch(
        setSnackbarToast({
          message: 'Please select site from list',
          open: true,
          severity: 'error',
        }),
      );
      navigate('/portal/sites/summary');
    }
  }, [dispatch, navigate, site]);

  // const handleChange = (event: React.SyntheticEvent, newValue: string) => {
  //   setValue(newValue);
  // };
  const onGetSiteDetails = React.useCallback(async () => {
    try {
      dispatch(setLoader({ loaderMessage: 'Please wait', openLoader: true }));
      const siteResponse = await apiClient.get(`/sites/${site?.siteId}`);
      setSiteData(siteResponse.data.data as Site);
      dispatch(setLoader({ loaderMessage: 'Please wait', openLoader: false }));
      //Get devices master data
    } catch (error: any) {
      dispatch(setLoader({ loaderMessage: 'Please wait', openLoader: false }));
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: 'error',
        }),
      );
    }
  }, [dispatch, site?.siteId]);

  const handleFavoriteSite = async (siteObj: Site) => {
    try {
      dispatch(setLoader({ loaderMessage: 'Please wait', openLoader: true }));
      if (siteObj.isFavorite) {
        await apiClient.delete(`users/favoriteSite/${siteObj.siteId}`);
      } else {
        await apiClient.put(`users/favoriteSite/${siteObj.siteId}`);
      }
      const siteResponse = await apiClient.get(`/sites/${siteObj?.siteId}`);
      setSiteData(siteResponse.data.data as Site);
      dispatch(setLoader({ loaderMessage: 'Please wait', openLoader: false }));
    } catch (error: any) {
      dispatch(setLoader({ loaderMessage: 'Please wait', openLoader: false }));
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: 'error',
        }),
      );
    }
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.contentPadding}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/portal/sites/summary" color="inherit">
            Sites
          </Link>
          <Link to="/portal/sites/summary" color="inherit">
            List
          </Link>
          <Typography variant="body1">{siteData?.name}</Typography>
        </Breadcrumbs>
      </div>
      {/* <Card elevation={0} className={classes.contentSection}> */}
      <SiteSummaryTab
        site={siteData}
        onGetUpdatedSite={onGetSiteDetails}
        handleFavoriteSite={handleFavoriteSite}></SiteSummaryTab>
      {/* <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                onChange={handleChange}
                textColor="primary"
                indicatorColor="secondary">
                <Tab label="Summary" value="1" />
                <Tab label="Security" value="2" />
                <Tab label="Site LAN" value="3" />
                <Tab label="Site WAN" value="4" />
                <Tab label="Cellular" value="5" />
                <Tab label="VPN" value="6" />
                <Tab label="Firewall" value="7" />
                <Tab label="OPN Config" value="8" />
                <Tab label="Cloud Config" value="9" />
                <Tab label="Site Image" value="10" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <SiteSummaryTab
                site={siteData}
                onGetUpdatedSite={onGetSiteDetails}></SiteSummaryTab>
            </TabPanel>
            <TabPanel value="2">
            <SecurityTab></SecurityTab>
            </TabPanel>
            <TabPanel value="3">
              <SiteLANTab site={siteData}></SiteLANTab>
            </TabPanel>
            <TabPanel value="4">
              <SiteWANTab site={siteData}></SiteWANTab>
            </TabPanel>
            <TabPanel value="5">
              <CellularTab information={demoConfigData}></CellularTab>
            </TabPanel>
            <TabPanel value="6">
              <VPNTab></VPNTab>
            </TabPanel>
            <TabPanel value="7">
              <FirewallTab></FirewallTab>
            </TabPanel>
            <TabPanel value="8">
              <OPNConfigTab></OPNConfigTab>
            </TabPanel>
            <TabPanel value="9">
              <CloudConfigTab information={demoConfigData}></CloudConfigTab>
            </TabPanel>
            <TabPanel value="10">
              <SiteImageTab
                site={siteData}
                ></SiteImageTab>
            </TabPanel>
          </TabContext>
        </Box> */}
      {/* </Card> */}
    </React.Fragment>
  );
};

export default SiteDetails;
