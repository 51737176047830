import React, { useState } from 'react';
import { CssBaseline, Card, Link, Typography, CardHeader } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';

import { useContentStyles } from 'common/styles/useContentStyles';
import logData from 'assets/staticdata/MPTLog.json';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { constants } from 'common/constants';
import { CustomToolbarWithOnlySearch } from 'common/components/CustomToolbarWithOnlySearch';

const Logging: React.FC = () => {
  const classes = useContentStyles();
  const [jsonData] = useState(
    logData.map((data, index) => {
      return { ...data, index };
    }),
  );

  const columns: GridColDef[] = [
    {
      field: 'Event Time',
      headerName: 'Event Time',
      flex: 1,
    },
    {
      field: 'Severity',
      headerName: 'Severity',
      width: 90,
    },
    {
      field: 'Message',
      headerName: 'Message',
      flex: 1,
    },
    {
      field: 'Username',
      headerName: 'Username',
      flex: 1,
    },
    {
      field: 'Site',
      headerName: 'Site',
      flex: 1,
    },
    {
      field: 'Device',
      headerName: 'Device',
      flex: 1,
    },
    {
      field: 'IP Address',
      headerName: 'IP Address',
      width: 150,
    },
    {
      field: 'Session ID',
      headerName: 'Session ID',
      width: 100,
    },
  ];

  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.contentPadding}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit">
            Logging
          </Link>
          <Typography variant="body1">{'Logging'}</Typography>
        </Breadcrumbs>
      </div>
      <Card elevation={0} className={classes.contentSection}>
        <CardHeader
          disableTypography
          title="Log Viewer"
          className={classes.cardHeader}></CardHeader>
        <DataGrid
          rows={jsonData}
          columns={columns}
          getRowId={(row) => row.index}
          pageSizeOptions={constants.PAGE_SIZE_OPTIONS}
          initialState={{
            pagination: { paginationModel: constants.PAGINATION_MODEL },
          }}
          slots={{
            toolbar: CustomToolbarWithOnlySearch,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          autoHeight
          showCellVerticalBorder
          showColumnVerticalBorder
          sx={{
            borderRight: '1px dotted',
            borderBottom: '1px dotted',
            borderColor: 'primary.light',
            borderStyle: 'dotted',
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: '600',
            },
            '& .MuiDataGrid-columnHeaders': {
              borderTop: '1px',
              borderBottom: '1px',
              borderColor: 'primary.light',
              borderStyle: 'dotted',
            },

            '& .MuiDataGrid-footerContainer': {
              borderColor: 'primary.light',
              borderStyle: 'dotted',
            },
            '& .MuiDataGrid-cell': {
              borderColor: 'primary.light',
              borderStyle: 'dotted',
            },
            '& .MuiDataGrid-columnHeader': {
              borderTop: '1px',
              borderBottom: '1px',
              borderColor: 'primary.light',
              borderStyle: 'dotted',
            },
            '& .MuiDataGrid-columnHeader:last-child': {
              borderRight: '0px',
            },
            '& .MuiDataGrid-cell:last-child': {
              borderRight: '0px',
            },
          }}
        />
      </Card>
    </React.Fragment>
  );
};

export default Logging;
