import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useErrorBoundary } from 'react-error-boundary';
import { useDispatch } from 'react-redux';
import { clearUserData } from 'redux/UserStateSlice';
import { constants } from './constants';

const instance = axios.create({
  baseURL:
    process.env.REACT_APP_API_BASE_URL ?? 'http://localhost:3001/api/v1/',
});
instance.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem('access_token');
  if (accessToken) {
    config.headers['Authorization'] = `Bearer ${accessToken}`;
  }
  return config;
});

const AxiosInterceptor = ({ children }: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showBoundary } = useErrorBoundary();
  const [isSet, setIsSet] = useState(false);

  useEffect(() => {
    setIsSet(true);
    const resInterceptor = (response: any) => {
      return response;
    };

    const errInterceptor = (error: any) => {
      if (error.response) {
        //Redirect user to login page for 401 or 403 errors
        if (error.response.status === 401) {
          localStorage.setItem('access_token', '');
          localStorage.setItem('user', '');
          localStorage.setItem('login_with', '');
          dispatch(clearUserData());
          if (error.response.config.url !== '/auth/login') {
            setTimeout(() => navigate(constants.LOGIN_PATH), 2000);
          }
        }
        return Promise.reject(error);
      } else if (error.request) {
        showBoundary(error);
      }
    };

    const interceptor = instance.interceptors.response.use(
      resInterceptor,
      errInterceptor,
    );
    return () => instance.interceptors.response.eject(interceptor);
  }, [dispatch, navigate, showBoundary]);
  return isSet && children;
};

export default instance;
export { AxiosInterceptor };
