import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

type NoRowsOverlayProps = {
  hasAccess: boolean;
  name: string;
};

function NoRowsOverlay({ hasAccess, name }: NoRowsOverlayProps) {
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      height="100%">
      {hasAccess ? (
        <Typography variant="body2">No {name}</Typography>
      ) : (
        <Grid item>
          <Alert severity="error">You do not have access to {name}</Alert>
        </Grid>
      )}
    </Grid>
  );
}

export default NoRowsOverlay;
