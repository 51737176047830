import React from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Checkbox,
  Typography,
  Grid,
  Button,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormProvider, useForm } from 'react-hook-form';
import TcTextField from 'common/components/TcTextField';
import TcSelectWithStringArray from 'common/components/TcSelectWithStringArray';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { constants } from 'common/constants';

const useStyles = makeStyles(() => ({
  customHeader: {
    fontWeight: 'bold',
  },
  cardHeader: {
    fontSize: '20px',
    fontWeight: 'normal',
  },
}));

type FirewallForm = {
  name: string;
  direction: string;
  ipAddress: string;
  port: string;
  protocol: string;
};

const formDefaultValues: FirewallForm = {
  name: '',
  direction: '',
  ipAddress: '',
  port: '',
  protocol: '',
};

const directions = ['Inbound', 'Outbound', 'Both'];
const protocols = ['TCP', 'UDP', 'SSH', 'RDP'];

const FirewallTab: React.FC = () => {
  const classes = useStyles();
  const columns: GridColDef[] = [
    {
      field: 'to',
      headerName: 'To',
      headerClassName: classes.customHeader,
    },
    {
      field: 'action',
      headerName: 'Action',
      headerClassName: classes.customHeader,
      flex: 1,
    },
    {
      field: 'from',
      headerName: 'From',
      headerClassName: classes.customHeader,
      flex: 1,
    },
    {
      field: 'direction',
      headerName: 'Direction',
      headerClassName: classes.customHeader,
      flex: 1,
    },
    {
      field: 'name',
      headerName: 'Name',
      headerClassName: classes.customHeader,
      flex: 1,
    },
  ];
  const rows = [
    {
      to: '22/tcp',
      action: 'Allow',
      from: '104.27.92.3',
      direction: 'Inbound',
      name: 'Allow SSH',
    },
    {
      to: '1194/udp',
      action: 'Allow',
      from: 'Anywhere',
      direction: 'Inbound',
      name: 'Allow OpenVPN',
    },
    {
      to: '6363/tcp',
      action: 'Allow',
      from: 'Anywhere',
      direction: 'Both',
      name: 'Allow NFD TCP',
    },
    {
      to: '6363/udp',
      action: 'Allow',
      from: 'Anywhere',
      direction: 'Both',
      name: 'Allow NFD UDP',
    },
    {
      to: '22/tcp (v6)',
      action: 'Allow',
      from: '104.27.92.3',
      direction: 'Inbound',
      name: 'Allow SSH',
    },
    {
      to: '1194/udp (v6)',
      action: 'Allow',
      from: 'Anywhere',
      direction: 'Inbound',
      name: 'Allow OpenVPN',
    },
    {
      to: '6363/tcp (v6)',
      action: 'Allow',
      from: 'Anywhere',
      direction: 'Both',
      name: 'Allow NFD TCP',
    },
    {
      to: '6363/udp (v6)',
      action: 'Allow',
      from: 'Anywhere',
      direction: 'Both',
      name: 'Allow NFD UDP',
    },
  ];
  const methods = useForm<FirewallForm>({
    defaultValues: formDefaultValues,
    mode: 'onBlur',
  });

  return (
    <Card variant="outlined">
      <CardHeader
        title="UFW Configuration"
        className={classes.cardHeader}
        disableTypography
        action={
          <div style={{ position: 'relative', top: 10, right: 10 }}>
            <Typography component="header" variant="body1" gutterBottom>
              <label htmlFor="enable-checkbox">{' Enable'}</label>
              <Checkbox
                id="enable-checkbox"
                color="primary"
                defaultChecked
                inputProps={{ 'aria-label': 'Enable' }}
              />
            </Typography>
          </div>
        }
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1" gutterBottom>
                  {'Add Rule'}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormProvider {...methods}>
                  <Grid container spacing={2}>
                    <Grid item xs={10}>
                      <TcTextField name="name" label="Name" />
                    </Grid>
                    <Grid item xs={10}>
                      <TcSelectWithStringArray
                        name="direction"
                        label="Direction"
                        options={directions}
                      />
                    </Grid>
                    <Grid item xs={10}>
                      <TcTextField name="ipAddress" label="IP Address" />
                    </Grid>
                    <Grid item xs={10}>
                      <TcTextField name="port" label="Port" />
                    </Grid>
                    <Grid item xs={10}>
                      <TcSelectWithStringArray
                        name="protocol"
                        label="Protocol(TCP,UDP,Both)"
                        options={protocols}
                      />
                    </Grid>
                    <Grid item container spacing={2} direction={'row'}>
                      <Grid item xs={2}>
                        <Button
                          size="small"
                          fullWidth
                          variant="contained"
                          color="info">
                          {'Add'}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </FormProvider>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={8}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1" gutterBottom>
                  {'Active Rules'}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  getRowId={(row) => row.to}
                  pageSizeOptions={constants.PAGE_SIZE_OPTIONS}
                  initialState={{
                    pagination: { paginationModel: constants.PAGINATION_MODEL },
                  }}
                  sx={{
                    '& .MuiDataGrid-row:hover': {
                      cursor: 'pointer',
                    },
                    '& .MuiDataGrid-columnHeaderTitle': {
                      fontWeight: '600',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2} direction={'row'}>
                  <Grid item xs={2}>
                    <Button
                      size="small"
                      fullWidth
                      variant="outlined"
                      color="info">
                      {'Delete'}
                    </Button>
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      size="small"
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="info">
                      {'Edit'}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default FirewallTab;
