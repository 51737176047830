import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
export const useDrawerFormStyles = makeStyles((theme: Theme) => ({
  formContainer: {
    width: '100%',
    padding: theme.spacing(2),
  },
  formTitleContainer: {
    width: '100%',
    padding: theme.spacing(2),
  },
  marginTitle: {
    marginTop: 4,
    marginBottom: 2,
    marginLeft: 4,
  },
  closeButton: {
    position: 'absolute',
    // color: theme.palette.action.selected,
  },
  bottomDivider: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  ruleFormTabContainer: {
    minHeight: '300px',
    maxHeight: '300px',
  },
}));
