import React from 'react';
import {
  Button,
  Grid,
  Typography,
  Paper,
  TextField,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { UsernameAndPasswordForm } from '../types';
import TcTextField from 'common/components/TcTextField';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

interface RDPLoginFormProps {
  handleSubmitForm: (data: UsernameAndPasswordForm) => void;
  onDisconnect: () => void;
}

const RDPLoginForm: React.FC<RDPLoginFormProps> = ({
  handleSubmitForm,
  onDisconnect,
}) => {
  const methods = useForm<UsernameAndPasswordForm>({
    defaultValues: { username: '', password: '' },
    mode: 'onBlur',
  });
  const {
    handleSubmit,
    formState: { isValid, errors },
  } = methods;

  const onSubmitCredentials = handleSubmit(
    async (data: UsernameAndPasswordForm) => {
      handleSubmitForm(data);
    },
  );

  const [showPassword, setShowPassword] = React.useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Grid item xs={6}>
      <FormProvider {...methods}>
        <form noValidate>
          <Paper elevation={3} style={{ padding: '20px' }}>
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center">
              <Grid item xs={12}>
                <Typography variant="body2">
                  Enter device connection credentials
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TcTextField
                  name="username"
                  label="Username *"
                  rules={{
                    required: {
                      value: true,
                      message: 'Username is required',
                    },
                  }}></TcTextField>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="password"
                  defaultValue=""
                  rules={{
                    required: {
                      value: true,
                      message: 'Password is required',
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={'Password *'}
                      size="small"
                      type={showPassword ? 'text' : 'password'}
                      variant="outlined"
                      fullWidth
                      error={!!errors['password']}
                      helperText={
                        errors['password']
                          ? (errors['password']?.message as unknown as string)
                          : ''
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={togglePasswordVisibility}>
                              {showPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                item
                xs={12}
                spacing={2}>
                <Grid item>
                  <Button
                    size="small"
                    variant="contained"
                    color="info"
                    type="submit"
                    disabled={!isValid}
                    onClick={onSubmitCredentials}>
                    {'Connect'}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    size="small"
                    variant="outlined"
                    color="info"
                    onClick={onDisconnect}
                    id="cancel">
                    {'Cancel'}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </form>
      </FormProvider>
    </Grid>
  );
};

export default RDPLoginForm;
