import * as React from 'react';
import { useEffect } from 'react';
import { Avatar } from '@mui/material';
import BlockIcon from '@mui/icons-material/Block';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import green from '@mui/material/colors/green';
import grey from '@mui/material/colors/grey';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { hasPermission, stringAvatar } from 'common/helpers/utils';
import { UserStatus } from 'common/enums';
import { User, UserGroup } from '../types';
import NoRowsOverlay from 'common/components/NoRowsOverlay';

type UsersTableProps = {
  data: User[];
};

const GroupUsersSubTable: React.FC<UsersTableProps> = (props) => {
  const [rows, setRows] = React.useState<User[]>(props.data);

  useEffect(() => {
    setRows(props.data);
  }, [props.data]);

  const columns = React.useMemo<GridColDef[]>(
    () => [
      {
        field: 'avatar',
        headerName: '',
        width: 100,
        renderCell: (params) => (
          <Avatar
            {...stringAvatar(
              `${params.row.firstName ?? ''} ${params.row.lastName ?? ''}`,
            )}
          />
        ),
      },
      { field: 'firstName', type: 'string', headerName: 'First Name', flex: 1 },
      { field: 'lastName', type: 'string', headerName: 'Last Name', flex: 1 },
      { field: 'email', type: 'string', headerName: 'Email', flex: 1 },
      { field: 'roleName', type: 'string', headerName: 'Role', flex: 1 },
      {
        field: 'groups',
        type: 'string',
        headerName: 'Group(s)',
        flex: 1,
        valueGetter: (params) => {
          const groups = (params.row.groups || []) as UserGroup[];
          return groups.map((obj) => obj.name).join(', ');
        },
      },
      { field: 'country', type: 'string', headerName: 'Country', flex: 1 },
      { field: 'state', type: 'string', headerName: 'State', flex: 1 },
      { field: 'city', type: 'string', headerName: 'City', flex: 1 },
      {
        field: 'organization',
        type: 'string',
        headerName: 'Organization',
        flex: 1,
        valueGetter: (params) => {
          return params.row.organization?.name ?? '';
        },
      },
      {
        field: 'status',
        type: 'string',
        headerName: 'Status',
        width: 60,
        renderCell: (params) => {
          const status = params.row.status || '';
          if (status === UserStatus.ACTIVE) {
            return <CheckCircleOutlineIcon sx={{ color: green[500] }} />;
          } else if (status === UserStatus.INACTIVE) {
            return <BlockIcon sx={{ color: grey[500] }} />;
          } else {
            return status;
          }
        },
      },
    ],
    [],
  );

  return (
    <>
      <DataGrid
        disableVirtualization
        columns={columns}
        rows={rows}
        getRowId={(row) => row.userId}
        hideFooter={true}
        hideFooterPagination={true}
        autoHeight
        slots={{
          noRowsOverlay: () => (
            <NoRowsOverlay
              hasAccess={hasPermission('users.summary', 'read')}
              name="Users"
            />
          ),
        }}
        sx={{
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: '600',
          },
        }}
      />
    </>
  );
};
export default GroupUsersSubTable;
