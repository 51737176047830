import React, { useState } from 'react';

import {
  CssBaseline,
  Button,
  Breadcrumbs,
  Card,
  CardContent,
  Typography,
  CardActionArea,
  IconButton,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import dayjs from 'dayjs';

import apiClient from 'common/apiClientAxios';
import { setLoader, setSnackbarToast } from 'redux/UiStateSlice';
import { FormMasterData, Rule, RuleForm } from '../types';
import RuleScheduleForm from './RuleScheduleForm';
import { constants } from 'common/constants';
import TcSelectWithStringArray from 'common/components/TcSelectWithStringArray';
import { DeviceGroup } from 'pages/devices/types';
import { UserGroup } from 'pages/users/types';
import TcAutocomplete from 'common/components/TcAutocomplete';
import {
  getDateRangeAccessWindow,
  getDayInfo,
  getRepeatingAccessWindow,
} from 'common/helpers/utils';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useContentStyles } from 'common/styles/useContentStyles';
import TcTextField from 'common/components/TcTextField';
import { AccessWindowType, RecurrenceUnit, RuleTemplate } from 'common/enums';

const EditRule: React.FC = () => {
  const classes = useContentStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { formMasterData, selectedRule } = location.state as {
    formMasterData: FormMasterData;
    selectedRule: Rule;
  };
  const onClose = () => {
    navigate(`/portal/trust/list/`);
  };
  const { userGroups, deviceGroups } = formMasterData;
  const [selectedUserGroup, setSelectedUserGroup] = useState<UserGroup>({
    groupId: selectedRule.userGroupId,
    name: selectedRule.userGroupName,
  });
  const [selectedDeviceGroup, setSelectedDeviceGroup] = useState<DeviceGroup>({
    groupId: selectedRule.deviceGroupId,
    name: selectedRule.deviceGroupName,
  });
  const [openCalendarModal, setOpenCalendarModal] = React.useState(false);
  const [accessWindow, setAccessWindow] = React.useState(
    selectedRule.accessWindow ?? '',
  );
  // const [calendar] = React.useState({
  //   startDate: selectedRule.startDate,
  //   endDate: selectedRule.endDate,
  //   recurrenceEndDate: selectedRule.recurrenceEndDate,
  //   recurrenceStartDate: selectedRule.recurrenceStartDate,
  //   startTime: selectedRule.startTime,
  //   endTime: selectedRule.endTime,
  //   daysTemplate: selectedRule.daysTemplate,
  //   recurrenceStartTime: selectedRule.recurrenceStartTime,
  //   recurrenceEndTime: selectedRule.recurrenceEndTime,
  // });

  const methods = useForm<RuleForm>({
    defaultValues: {
      ...selectedRule,
      accessWindowType:
        selectedRule.accessWindowType ?? AccessWindowType.DATE_RANGE,
      startDate: selectedRule.startDate ? dayjs(selectedRule.startDate) : null,
      endDate: selectedRule.endDate ? dayjs(selectedRule.endDate) : null,
      startTime: selectedRule.startTime ? dayjs(selectedRule.startTime) : null,
      endTime: selectedRule.endTime ? dayjs(selectedRule.endTime) : null,
      daysTemplate: selectedRule.daysTemplate ?? RuleTemplate.ALL_DAYS,
      // recurrence: selectedRule.recurrence ?? 1,
      // recurrenceUnit: selectedRule.recurrenceUnit ?? '',
      recurrenceStartDate: selectedRule.recurrenceStartDate
        ? dayjs(selectedRule.recurrenceStartDate)
        : null,
      recurrenceEndDate: selectedRule.recurrenceEndDate
        ? dayjs(selectedRule.recurrenceEndDate)
        : null,
      recurrenceStartTime: selectedRule.recurrenceStartTime
        ? dayjs(selectedRule.recurrenceStartTime)
        : null,
      recurrenceEndTime: selectedRule.recurrenceEndTime
        ? dayjs(selectedRule.recurrenceEndTime)
        : null,
      schedule: {
        Monday: {
          startTime: selectedRule.schedule?.Monday.startTime
            ? dayjs(selectedRule.schedule?.Monday.startTime)
            : null,
          endTime: selectedRule.schedule?.Monday.endTime
            ? dayjs(selectedRule.schedule?.Monday.endTime)
            : null,
          isActive: selectedRule.schedule?.Monday.isActive ?? false,
        },
        Tuesday: {
          startTime: selectedRule.schedule?.Tuesday.startTime
            ? dayjs(selectedRule.schedule?.Tuesday.startTime)
            : null,
          endTime: selectedRule.schedule?.Tuesday.endTime
            ? dayjs(selectedRule.schedule?.Tuesday.endTime)
            : null,
          isActive: selectedRule.schedule?.Tuesday.isActive ?? false,
        },
        Wednesday: {
          startTime: selectedRule.schedule?.Wednesday.startTime
            ? dayjs(selectedRule.schedule?.Wednesday.startTime)
            : null,
          endTime: selectedRule.schedule?.Wednesday.endTime
            ? dayjs(selectedRule.schedule?.Wednesday.endTime)
            : null,
          isActive: selectedRule.schedule?.Wednesday.isActive ?? false,
        },
        Thursday: {
          startTime: selectedRule.schedule?.Thursday.startTime
            ? dayjs(selectedRule.schedule?.Thursday.startTime)
            : null,
          endTime: selectedRule.schedule?.Thursday.endTime
            ? dayjs(selectedRule.schedule?.Thursday.endTime)
            : null,
          isActive: selectedRule.schedule?.Thursday.isActive ?? false,
        },
        Friday: {
          startTime: selectedRule.schedule?.Friday.startTime
            ? dayjs(selectedRule.schedule?.Friday.startTime)
            : null,
          endTime: selectedRule.schedule?.Friday.endTime
            ? dayjs(selectedRule.schedule?.Friday.endTime)
            : null,
          isActive: selectedRule.schedule?.Friday.isActive ?? false,
        },
        Saturday: {
          startTime: selectedRule.schedule?.Saturday.startTime
            ? dayjs(selectedRule.schedule?.Saturday.startTime)
            : null,
          endTime: selectedRule.schedule?.Saturday.endTime
            ? dayjs(selectedRule.schedule?.Saturday.endTime)
            : null,
          isActive: selectedRule.schedule?.Saturday.isActive ?? false,
        },
        Sunday: {
          startTime: selectedRule.schedule?.Sunday.startTime
            ? dayjs(selectedRule.schedule?.Sunday.startTime)
            : null,
          endTime: selectedRule.schedule?.Sunday.endTime
            ? dayjs(selectedRule.schedule?.Sunday.endTime)
            : null,
          isActive: selectedRule.schedule?.Sunday.isActive ?? false,
        },
      },
      recurrenceWeekSchedule: {
        Monday: {
          isActive:
            selectedRule.recurrenceWeekSchedule?.Monday.isActive ?? false,
        },
        Tuesday: {
          isActive:
            selectedRule.recurrenceWeekSchedule?.Tuesday.isActive ?? false,
        },
        Wednesday: {
          isActive:
            selectedRule.recurrenceWeekSchedule?.Wednesday.isActive ?? false,
        },
        Thursday: {
          isActive:
            selectedRule.recurrenceWeekSchedule?.Thursday.isActive ?? false,
        },
        Friday: {
          isActive:
            selectedRule.recurrenceWeekSchedule?.Friday.isActive ?? false,
        },
        Saturday: {
          isActive:
            selectedRule.recurrenceWeekSchedule?.Saturday.isActive ?? false,
        },
        Sunday: {
          isActive:
            selectedRule.recurrenceWeekSchedule?.Sunday.isActive ?? false,
        },
      },
    },
    mode: 'onBlur',
  });

  const {
    handleSubmit,
    getValues,
    setValue,
    formState: { isValid, errors, isDirty },
  } = methods;

  const onCancelCalendarModal = () => {
    if (getValues('accessWindowType') === AccessWindowType.DATE_RANGE) {
      setValue(
        'startDate',
        selectedRule.startDate ? dayjs(selectedRule.startDate) : null,
      );
      setValue(
        'endDate',
        selectedRule.endDate ? dayjs(selectedRule.endDate) : null,
      );
      setValue(
        'startTime',
        selectedRule.startTime ? dayjs(selectedRule.startTime) : null,
      );
      setValue(
        'endTime',
        selectedRule.endTime ? dayjs(selectedRule.endTime) : null,
      );
      setValue('daysTemplate', selectedRule.daysTemplate);
    } else {
      setValue(
        'recurrenceStartDate',
        selectedRule.recurrenceStartDate
          ? dayjs(selectedRule.recurrenceStartDate)
          : null,
      );
      setValue(
        'recurrenceEndDate',
        selectedRule.recurrenceEndDate
          ? dayjs(selectedRule.recurrenceEndDate)
          : null,
      );
      setValue(
        'recurrenceStartTime',
        selectedRule.recurrenceStartTime
          ? dayjs(selectedRule.recurrenceStartTime)
          : null,
      );
      setValue(
        'recurrenceEndTime',
        selectedRule.recurrenceEndTime
          ? dayjs(selectedRule.recurrenceEndTime)
          : null,
      );
      setValue('recurrenceUnit', selectedRule.recurrenceUnit);
      setValue('recurrence', selectedRule.recurrence);
      setValue('recurrenceMonth', selectedRule.recurrenceMonth);
      setValue('recurrenceWeekSchedule', selectedRule.recurrenceWeekSchedule);
    }
    setOpenCalendarModal(false);
  };

  const onAcceptCalendar = () => {
    // setCalendar({
    //   startDate: getValues('startDate'),
    //   endDate: getValues('endDate'),
    //   recurrenceStartDate: getValues('recurrenceStartDate'),
    //   recurrenceEndDate: getValues('recurrenceEndDate'),
    //   // startTime: getValues('startTime'),
    //   // endTime: getValues('endTime'),
    // });
    if (getValues('accessWindowType') === AccessWindowType.DATE_RANGE) {
      setAccessWindow(
        getDateRangeAccessWindow({
          startDate: getValues('startDate'),
          endDate: getValues('endDate'),
          fromTime: getValues('startTime'),
          toTime: getValues('endTime'),
          daysTemplate: getValues('daysTemplate') as RuleTemplate,
          schedule: getValues('schedule'),
        }),
      );
    } else {
      setAccessWindow(
        getRepeatingAccessWindow({
          startDate: getValues('recurrenceStartDate'),
          endDate: getValues('recurrenceEndDate'),
          fromTime: getValues('recurrenceStartTime'),
          toTime: getValues('recurrenceEndTime'),
          schedule: getValues('recurrenceWeekSchedule'),
          recurrence: getValues('recurrence'),
          recurrenceUnit: getValues('recurrenceUnit'),
          recurrenceMonth: getValues('recurrenceMonth'),
        }),
      );
    }

    setOpenCalendarModal(false);
  };

  const onSelectUserGroup = (
    _event: React.SyntheticEvent<Element, Event>,
    value: UserGroup | null,
  ) => {
    if (value) {
      setValue('userGroupId', value.groupId);
      setSelectedUserGroup(value);
    }
  };

  const onSelectDeviceGroup = (
    _event: React.SyntheticEvent<Element, Event>,
    value: DeviceGroup | null,
  ) => {
    if (value) {
      setValue('deviceGroupId', value.groupId);
      setSelectedDeviceGroup(value);
    }
  };

  const onUpdate = handleSubmit(async (data: RuleForm) => {
    try {
      const ruleId = selectedRule.ruleId;
      dispatch(setLoader({ loaderMessage: 'Please wait', openLoader: true }));
      let recurrenceMonth = {};
      if (
        data.recurrenceMonth &&
        data.recurrenceMonth.option &&
        data.recurrenceUnit === RecurrenceUnit.MONTHS
      ) {
        const dateInfo = getDayInfo(data.recurrenceStartDate);
        recurrenceMonth = {
          dayOfMonth: dateInfo.dayOfMonth,
          dayName: dateInfo.dayName,
          positionInMonth: dateInfo.positionInMonth,
          option: data.recurrenceMonth.option,
          weekNumber: dateInfo.weekNumber,
        };
      }
      const trustRuleResponse = await apiClient.put(`/trust-rules/${ruleId}`, {
        ...data,
        ruleId,
        userGroupName: selectedUserGroup?.name,
        deviceGroupName: selectedDeviceGroup?.name,
        zoneId: data.zone?.zoneId,
        zoneName: data.zone?.name,
        accessWindow,
        recurrenceMonth: { ...recurrenceMonth },
      });
      dispatch(setLoader({ loaderMessage: 'Please wait', openLoader: false }));
      dispatch(
        setSnackbarToast({
          message: trustRuleResponse.data.meta.message,
          open: true,
          severity: 'success',
        }),
      );
    } catch (error: any) {
      dispatch(setLoader({ loaderMessage: 'Please wait', openLoader: false }));
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: 'error',
        }),
      );
    }
    navigate(`/portal/trust/list/`);
  });

  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.contentPadding}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/portal/trust/list" color="inherit">
            Trust
          </Link>
          <Link to="/portal/trust/list" color="inherit">
            List
          </Link>
          <Typography variant="body1">{selectedRule.name}</Typography>
        </Breadcrumbs>
      </div>
      <Card
        elevation={0}
        sx={{
          padding: 2,
        }}>
        <FormProvider {...methods}>
          <form noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography component="h6" variant="h6" gutterBottom>
                  {`Edit Rule`}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={10}>
                    <Grid container spacing={2}>
                      <Grid item alignSelf={'center'}>
                        {/* <label htmlFor="userName">Rule Name *</label> */}
                        <Typography variant="body1">Rule Name *</Typography>
                      </Grid>
                      <Grid item>
                        <TcTextField
                          name="name"
                          rules={{
                            required: 'Rule Name is required',
                            pattern: {
                              value: constants.NAME_REGEX,
                              message: 'Invalid name',
                            },
                          }}></TcTextField>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={1}>
                    <Button
                      size="small"
                      type="submit"
                      disabled={
                        !isValid || !isDirty || Object.keys(errors).length > 0
                        // ||
                        // selectedRule.accessWindow === accessWindow
                      }
                      onClick={onUpdate}
                      fullWidth
                      variant="contained"
                      color="info">
                      {'Save'}
                    </Button>
                  </Grid>
                  <Grid item xs={1}>
                    <Button
                      size="small"
                      type="button"
                      fullWidth
                      variant="outlined"
                      color="info"
                      onClick={onClose}>
                      {'Cancel'}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {/* <Card sx={{
                                    backgroundColor: 'secondary.main',
                                }} elevation={0} square>
                                    <CardContent> */}
                <Grid container spacing={2} alignItems="stretch">
                  <Grid item xs={3}>
                    <Card
                      sx={{
                        backgroundColor: 'secondary.main',
                        width: '100%',
                        height: '100%',
                      }}
                      elevation={0}>
                      <CardContent>
                        <Grid container spacing={2} flexDirection={'column'}>
                          <Grid item alignSelf={'center'}>
                            <strong>
                              <label htmlFor="userGroup">User Groups</label>
                            </strong>
                          </Grid>
                          <Grid
                            item
                            sx={{
                              '& .MuiFormControl-root': {
                                background: 'white',
                              },
                            }}>
                            <TcAutocomplete
                              name="userGroupId"
                              label=""
                              optionKey="groupId"
                              options={userGroups}
                              value={selectedUserGroup}
                              getOptionLabel={(grp) => grp.name}
                              onValueSelect={onSelectUserGroup}
                              rules={{
                                required: 'User Group is required',
                              }}></TcAutocomplete>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={3}>
                    <Card
                      sx={{
                        backgroundColor: 'secondary.main',
                        width: '100%',
                        height: '100%',
                      }}
                      elevation={0}>
                      <CardContent>
                        <Grid container spacing={2} flexDirection={'column'}>
                          <Grid item alignSelf={'center'}>
                            <strong>
                              <label htmlFor="deviceGroup">Device Groups</label>
                            </strong>
                          </Grid>
                          <Grid
                            item
                            sx={{
                              '& .MuiFormControl-root': {
                                background: 'white',
                              },
                            }}>
                            <TcAutocomplete
                              name="deviceGroupId"
                              label=""
                              optionKey="groupId"
                              options={deviceGroups}
                              value={selectedDeviceGroup}
                              getOptionLabel={(grp) => grp.name}
                              onValueSelect={onSelectDeviceGroup}
                              rules={{
                                required: 'Device Group is required',
                              }}></TcAutocomplete>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={3}>
                    <Card
                      sx={{
                        backgroundColor: 'secondary.main',
                        width: '100%',
                        height: '100%',
                      }}
                      elevation={0}>
                      <CardContent>
                        <Grid container spacing={2} flexDirection={'column'}>
                          <Grid item alignSelf={'center'}>
                            <strong>
                              <label htmlFor="localAccess">
                                Local Access Profile
                              </label>
                            </strong>
                          </Grid>
                          <Grid
                            item
                            sx={{
                              '& .MuiFormControl-root': {
                                background: (theme) =>
                                  selectedDeviceGroup.groupId !== '' &&
                                  selectedUserGroup.groupId !== ''
                                    ? 'white'
                                    : theme.palette.grey[300],
                              },
                            }}>
                            <TcSelectWithStringArray
                              label=""
                              disabled={
                                !(
                                  selectedDeviceGroup.groupId !== '' &&
                                  selectedUserGroup.groupId !== ''
                                )
                              }
                              name="accessProfile"
                              options={constants.ACCESS_PROFILES}
                              rules={{
                                required: 'Local Access Profile is required',
                              }}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={3}>
                    <Card
                      sx={{
                        backgroundColor: 'secondary.main',
                        width: '100%',
                        height: '100%',
                      }}
                      elevation={0}>
                      <CardContent>
                        <Grid container spacing={2} flexDirection={'column'}>
                          <Grid item alignSelf={'center'}>
                            <strong>
                              <label htmlFor="accessWindow">
                                Access Window
                              </label>
                            </strong>
                          </Grid>
                          <Grid
                            item
                            alignSelf={'center'}
                            xs={12}
                            sx={{ width: '100%' }}>
                            <Grid
                              container
                              spacing={2}
                              flexDirection={'column'}>
                              {accessWindow ? (
                                <Grid
                                  item
                                  alignSelf={'center'}
                                  sx={{ width: '100%' }}>
                                  <Card>
                                    <CardActionArea
                                      onClick={() =>
                                        setOpenCalendarModal(true)
                                      }>
                                      <CardContent style={{ padding: 8 }}>
                                        {' '}
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: accessWindow,
                                          }}
                                        />
                                      </CardContent>
                                    </CardActionArea>
                                  </Card>
                                </Grid>
                              ) : (
                                <Grid item sx={{ width: '100%' }}>
                                  <Card>
                                    <CardContent style={{ padding: 0 }}>
                                      <IconButton
                                        sx={{ paddingLeft: '50%' }}
                                        onClick={() =>
                                          setOpenCalendarModal(true)
                                        }>
                                        <EditCalendarIcon />
                                      </IconButton>
                                    </CardContent>
                                  </Card>
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {openCalendarModal && (
              <RuleScheduleForm
                open={openCalendarModal}
                onCancelCalendarModal={onCancelCalendarModal}
                onAcceptCalendar={onAcceptCalendar}></RuleScheduleForm>
            )}
          </form>
        </FormProvider>
      </Card>
    </React.Fragment>
  );
};

export default EditRule;
