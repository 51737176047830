import { createTheme, alpha } from '@mui/material/styles';
import { PaletteOptions } from '@mui/material/styles/createPalette';
import { TypographyOptions } from '@mui/material/styles/createTypography';

export interface ICustomThemeOptions {
  primaryColor: string;
  secondaryColor: string;
  font: string;
  successColor: string;
  infoColor: string;
  warningColor: string;
  errorColor: string;
}

export const paletteColors = {
  contrastText: '#ffffff',
  backgroundDefault: '#f4f4f4', //'#000000',
  textDisabled: '#00001161',
  textPrimary: '#000011de',
  textSecondary: '#000011ab',
  textHint: '#00000061',
  paper: '#ffffff',
};

const customTheme = (themeOptions: ICustomThemeOptions) => {
  const palette: PaletteOptions = {
    primary: {
      main: themeOptions.primaryColor,
      light: alpha(themeOptions.primaryColor, 0.2),
    },
    secondary: {
      main: themeOptions.secondaryColor,
      contrastText: paletteColors.paper,
      light: alpha(themeOptions.secondaryColor, 0.5),
    },
    success: {
      main: themeOptions.successColor,
    },
    info: {
      main: themeOptions.infoColor,
    },
    warning: {
      main: themeOptions.warningColor,
    },
    error: {
      main: themeOptions.errorColor,
    },
    action: {
      hover: alpha(themeOptions.primaryColor, 0.05),
      selected: alpha(themeOptions.primaryColor, 0.1),
      active: '#a9a9a9', //'#EBEDED',
      disabledBackground: '#EBEDED',
      disabledOpacity: 0.12,
    },
    background: {
      paper: paletteColors.paper,
      default: paletteColors.backgroundDefault, //alpha(themeOptions.primaryColor, 0.01),
    },
    text: {
      primary: paletteColors.textPrimary,
      secondary: paletteColors.textSecondary,
      disabled: paletteColors.textDisabled,
    },
  };

  const typography: TypographyOptions = {
    fontFamily: themeOptions.font,
    body1: {
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: '0.1px',
      textAlign: 'left',
    },
    body2: {
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '20px',
      letterSpacing: '0.15000000596046448px',
      textAlign: 'left',
    },
    subtitle1: {
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '28px',
      letterSpacing: '0.15000000596046448px',
      textAlign: 'left',
    },
    subtitle2: {
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '22px',
      letterSpacing: '0.10000000149011612px',
      textAlign: 'left',
    },
    caption: {
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '19.92px',
      letterSpacing: '0.4px',
      textAlign: 'left',
      color: 'rgba(0, 0, 17, 0.87)',
    },
    overline: {
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '32px',
      letterSpacing: '1px',
      textAlign: 'left',
      textTransform: 'uppercase',
    },
    button: {
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: '0.46000000834465027px',
      textAlign: 'left',
      textTransform: 'none',
    },
    h1: {
      fontSize: '96px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '112px',
      letterSpacing: '-1.5px',
      textAlign: 'left',
    },
    h2: {
      fontSize: '60px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '72px',
      letterSpacing: '-0.5000000596046448px',
      textAlign: 'left',
    },
    h3: {
      fontSize: '48px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '56px',
      letterSpacing: '0',
      textAlign: 'left',
    },
    h4: {
      fontSize: '34px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '42px',
      letterSpacing: '0.25px',
      textAlign: 'left',
    },
    h5: {
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '32px',
      letterSpacing: '0px',
      textAlign: 'left',
    },
    h6: {
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '32px',
      letterSpacing: '0.15000000596046448px',
      textAlign: 'left',
    },
  };

  return createTheme({
    palette,
    typography,
  });
};

export default customTheme;
