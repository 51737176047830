import React from 'react';
import { Dialog, DialogContent } from '@mui/material';
import AddOwner from './AddOwner';

type AddOwnerDialogProps = {
  open: boolean;
  onCancel: () => void;
  onSave: () => void;
};

const AddOwnerDialog: React.FC<AddOwnerDialogProps> = ({
  open,
  onCancel,
  onSave,
}) => {
  return (
    <React.Fragment>
      <Dialog open={open}>
        <DialogContent>
          <AddOwner onClose={onCancel} onSaveUpdateTable={onSave}></AddOwner>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};
export default AddOwnerDialog;
