import React, { useEffect, useState } from 'react';
import L from 'leaflet';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet.offline';
import '../../assets/leaflet/leaflet.css';
import logo from '../../assets/images/marker-icon.png';

interface MapProps {
  latitude: number;
  longitude: number;
}

const SiteMap: React.FC<MapProps> = ({ latitude, longitude }) => {
  const [map, setMap] = useState();
  useEffect(() => {
    if (map) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      const tileLayerOffline = L.tileLayer.offline(
        'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        {
          attribution:
            '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
          minZoom: 13,
          maxZoom: 16,
        },
      );
      tileLayerOffline.addTo(map);
    }
  });

  return (
    <MapContainer
      center={[latitude, longitude]}
      zoom={13}
      style={{ height: '400px', width: '100%' }}
      whenReady={() => setMap(map)}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution=""
      />
      <Marker
        position={[latitude, longitude]}
        icon={
          new L.Icon({
            iconUrl: logo,
            iconSize: [25, 41],
            iconAnchor: [12, 41],
          })
        }>
        <Popup>
          Location: {latitude}, {longitude}
        </Popup>
      </Marker>
    </MapContainer>
  );
};

export default SiteMap;
