import React, { useCallback, useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CssBaseline,
  Grid,
  Typography,
} from '@mui/material';

import { UserFormMasterData, UsersFilterData } from './types';
import { useFilterFormStyles } from 'common/styles/useFilterFormStyles';
import TcCheckboxInput from 'common/components/TcCheckboxInput';
import { userStatuses } from './Users';
import TcAutocompleteMultiSelect from 'common/components/TcAutocompleteMultiSelect';
import TcTextField from 'common/components/TcTextField';
import { Country, State } from 'pages/sites/types';

type FilterProps = {
  onApplyFilter: (formData: UsersFilterData) => void;
  filters: UsersFilterData;
  handleClearFilter: () => void;
  handleCancelFilter: () => void;
  userFormMasterData: UserFormMasterData;
  isShowCancel?: boolean;
  isResetForm?: boolean;
};

const UsersFilter: React.FC<FilterProps> = (filterProps) => {
  const classes = useFilterFormStyles();
  const [selectedCountries, setSelectedCountries] = useState<string[]>(
    filterProps.filters.selectedCountries,
  );
  const [selectedStates, setSelectedStates] = useState<string[]>(
    filterProps.filters.selectedStates,
  );
  const { countries, roles, states, groups, organizations } =
    filterProps.userFormMasterData;
  const methods = useForm({
    defaultValues: filterProps.filters,
  });
  const { handleSubmit, setValue, reset } = methods;

  const onSubmit = handleSubmit(async (data: UsersFilterData) => {
    filterProps.onApplyFilter(data);
  });

  const onCountrySelect = (
    _event: React.SyntheticEvent<Element, Event>,
    value: Country[],
  ) => {
    setValue(
      'selectedCountries',
      value.map((c) => c.countryId),
    );
    setSelectedCountries(value.map((c) => c.countryId));
  };

  const onStateSelect = (
    _event: React.SyntheticEvent<Element, Event>,
    value: State[],
  ) => {
    setValue(
      'selectedStates',
      value.map((c) => c.stateId),
    );
    setSelectedStates(value.map((c) => c.stateId));
  };

  const resetForm = useCallback(() => {
    reset({
      selectedCountries: [],
      roles: [],
      statuses: [],
      selectedStates: [],
      organizations: [],
      city: '',
      zipcode: '',
      groups: [],
    });
    setSelectedCountries([]);
    setSelectedStates([]);
  }, [reset]);

  const onClear = () => {
    resetForm();
    filterProps.handleClearFilter();
  };

  const onCancel = () => {
    resetForm();
    filterProps.handleCancelFilter();
  };

  useEffect(() => {
    if (filterProps.isResetForm) {
      resetForm();
    }
  }, [filterProps.isResetForm, resetForm]);

  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.root}>
        <FormProvider {...methods}>
          {filterProps.isShowCancel && (
            <Card key={1} className={classes.longCard} variant="outlined">
              <CardHeader
                subheader={
                  <Typography variant="subtitle1">
                    <strong>Groups</strong>
                  </Typography>
                }
                className={classes.cardHeader}></CardHeader>
              <CardContent className={classes.cardContent}>
                <TcCheckboxInput
                  name="groups"
                  optionKey="groupId"
                  optionLabel="name"
                  options={groups}
                  className="custom-checkbox-font-size"
                />
              </CardContent>
            </Card>
          )}
          <Card key={2} className={classes.card} variant="outlined">
            <CardHeader
              subheader={
                <Typography variant="subtitle1">
                  <strong>Roles</strong>
                </Typography>
              }
              className={classes.cardHeader}></CardHeader>
            <CardContent>
              <TcCheckboxInput
                name="roles"
                optionKey="roleId"
                optionLabel="name"
                options={roles}
                className="custom-checkbox-font-size"
              />
            </CardContent>
          </Card>
          <Card key={3} className={classes.addressCard} variant="outlined">
            <CardHeader
              subheader={
                <Typography variant="subtitle1">
                  <strong>Address</strong>
                </Typography>
              }
              className={classes.cardHeader}></CardHeader>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TcAutocompleteMultiSelect
                    name="selectedCountries"
                    value={
                      selectedCountries.map((country) =>
                        countries.find((c) => c.countryId === country),
                      ) as Country[]
                    }
                    label="Country"
                    onValueSelect={onCountrySelect}
                    optionKey="countryId"
                    // optionLabel='name'
                    getOptionLabel={(country) => country?.name}
                    options={countries}></TcAutocompleteMultiSelect>
                </Grid>
                <Grid item xs={12}>
                  <TcAutocompleteMultiSelect
                    name="selectedStates"
                    value={
                      selectedStates.map((state) =>
                        states.find((c) => c.stateId === state),
                      ) as State[]
                    }
                    label="State"
                    onValueSelect={onStateSelect}
                    optionKey="stateId"
                    getOptionLabel={(state) => state?.name}
                    options={states}></TcAutocompleteMultiSelect>
                </Grid>
                <Grid item xs={12}>
                  <TcTextField
                    name="city"
                    placeholder="Enter City"></TcTextField>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          {/* <Card key={4} className={classes.shortCard} variant="outlined">
          <CardHeader subheader={
              <Typography variant="subtitle1">
              <strong>Recurrences</strong>
            </Typography>
            } className={classes.cardHeader}></CardHeader>
            <CardContent>
              <TcCheckboxInput
                name="recurrences"
                optionKey="name"
                optionLabel="name"
                options={recurrences.map(val=> {return{ name: val }})}
                className='custom-checkbox-font-size'
              />
            </CardContent>
          </Card> */}
          <Card key={5} className={classes.longCard} variant="outlined">
            <CardHeader
              subheader={
                <Typography variant="subtitle1">
                  <strong>Organizations</strong>
                </Typography>
              }
              className={classes.cardHeader}></CardHeader>
            <CardContent>
              <TcCheckboxInput
                name="organizations"
                optionKey="organizationId"
                optionLabel="name"
                options={organizations}
                className="custom-checkbox-font-size"
              />
            </CardContent>
          </Card>
          <Card key={6} className={classes.shortCard} variant="outlined">
            <CardHeader
              subheader={
                <Typography variant="subtitle1">
                  <strong>Status</strong>
                </Typography>
              }
              className={classes.cardHeader}></CardHeader>
            <CardContent>
              <TcCheckboxInput
                name="statuses"
                optionKey="name"
                optionLabel="name"
                options={userStatuses.map((val) => {
                  return { name: val };
                })}
                className="custom-checkbox-font-size"
              />
            </CardContent>
          </Card>
          <Grid container spacing={2} className={classes.bottomDivider}>
            <Grid item xs={2}>
              <Button
                size="small"
                type="submit"
                onClick={onSubmit}
                fullWidth
                variant="contained"
                color="info">
                {'Apply All'}
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                size="small"
                fullWidth
                variant="outlined"
                color="info"
                onClick={onClear}>
                {'Clear All'}
              </Button>
            </Grid>
            {filterProps.isShowCancel && (
              <Grid item xs={2}>
                <Button
                  size="small"
                  fullWidth
                  variant="outlined"
                  color="info"
                  onClick={onCancel}>
                  {'Cancel'}
                </Button>
              </Grid>
            )}
          </Grid>
        </FormProvider>
      </div>
    </React.Fragment>
  );
};
export default UsersFilter;
