import React, { useState } from 'react';
import { Stack, Switch, Typography } from '@mui/material';

import { Device } from '../types';
import { DeviceGroupType } from 'common/enums';
import StaticToDynamicDialog from './StaticToDynamicDialog';
import DynamicToStaticDialog from './DynamicToStaticDialog';

type GroupTypeProps = {
  onCancel: (type: DeviceGroupType) => void;
  onConfirmSave: (type: DeviceGroupType) => void;
  rows: Device[];
  rowSelectionModel: string[];
  defaultGroupType: DeviceGroupType;
};
const GroupType: React.FC<GroupTypeProps> = ({
  onCancel,
  onConfirmSave,
  rows,
  rowSelectionModel,
  defaultGroupType,
}) => {
  const [showStaticToDynamicDialog, setShowStaticToDynamicDialog] =
    useState(false);
  const [showDynamicToStaticDialog, setShowDynamicToStaticDialog] =
    useState(false);
  const [groupTypeSwitchValue, setGroupTypeSwitchValue] =
    useState(defaultGroupType);
  const [newAddedDevicesOnTypeChange, setNewAddedDevicesOnTypeChange] =
    useState<Device[]>([]);
  const handleGroupTypeSwitchChange = () => {
    if (groupTypeSwitchValue === DeviceGroupType.STATIC) {
      //static to dynamic
      //find new added rows
      const newAddedDevices = rows.filter(
        (device) =>
          !rowSelectionModel.some(
            (originalObj) => originalObj === device.deviceId,
          ),
      );
      setNewAddedDevicesOnTypeChange(newAddedDevices);
      setShowStaticToDynamicDialog(true);
    } else {
      setShowDynamicToStaticDialog(true);
    }
  };

  const onConfirmStaticToDynamic = () => {
    setGroupTypeSwitchValue(DeviceGroupType.DYNAMIC);
    setShowStaticToDynamicDialog(false);
    onConfirmSave(DeviceGroupType.DYNAMIC);
  };

  const onCancelStaticToDynamic = () => {
    setShowStaticToDynamicDialog(false);
    onCancel(DeviceGroupType.STATIC);
  };

  const onConfirmDynamicToStatic = () => {
    setGroupTypeSwitchValue(DeviceGroupType.STATIC);
    setShowDynamicToStaticDialog(false);
    onConfirmSave(DeviceGroupType.STATIC);
  };

  const onCancelDynamicToStatic = () => {
    setShowDynamicToStaticDialog(false);
    onCancel(DeviceGroupType.DYNAMIC);
  };

  return (
    <React.Fragment>
      <Stack direction="row" alignItems="center">
        <Typography>
          <strong>Group Type: </strong>&nbsp;&nbsp;
        </Typography>
        <Typography variant="subtitle1">Static</Typography>
        <Switch
          checked={groupTypeSwitchValue === DeviceGroupType.DYNAMIC}
          onChange={handleGroupTypeSwitchChange}
        />
        <Typography variant="subtitle1">Dynamic</Typography>
      </Stack>

      {showStaticToDynamicDialog && (
        <StaticToDynamicDialog
          newDevices={newAddedDevicesOnTypeChange}
          onConfirm={onConfirmStaticToDynamic}
          open={showStaticToDynamicDialog}
          onCancel={onCancelStaticToDynamic}></StaticToDynamicDialog>
      )}

      {showDynamicToStaticDialog && (
        <DynamicToStaticDialog
          onConfirm={onConfirmDynamicToStatic}
          open={showDynamicToStaticDialog}
          onCancel={onCancelDynamicToStatic}></DynamicToStaticDialog>
      )}
    </React.Fragment>
  );
};
export default GroupType;
