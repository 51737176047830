import { Controller, useFormContext, Control } from 'react-hook-form';
import {
  FormControlLabel,
  Checkbox,
  Grid,
  FormHelperText,
} from '@mui/material';

interface CheckboxInputProps<T> {
  name: string;
  options: T[];
  optionKey: keyof T;
  optionLabel: keyof T;
  rules?: Record<string, any>;
  className?: string;
}

const TcCheckboxInput = <T extends Record<string, any>>({
  name,
  options,
  optionKey,
  optionLabel,
  rules,
  className,
}: CheckboxInputProps<T>) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const isError = Boolean(errors[name]);

  const handleCheckboxChange =
    (inputValue: string, checked: boolean) =>
    (prevValues: string[]): string[] => {
      if (checked) {
        return [...prevValues, inputValue];
      } else {
        return prevValues.filter((value) => value !== inputValue);
      }
    };

  return (
    <>
      {options.map((option) => (
        <Grid item xs={12} key={option[optionKey]}>
          <Controller
            key={option[optionKey]}
            name={name}
            control={control as Control}
            rules={rules}
            render={({ field }) => (
              <FormControlLabel
                className={className}
                control={
                  <Checkbox
                    size="small"
                    {...field}
                    value={option[optionKey]}
                    checked={field.value?.includes(option[optionKey])}
                    onChange={(e) =>
                      field.onChange(
                        handleCheckboxChange(
                          option[optionKey],
                          e.target.checked,
                        )(field.value),
                      )
                    }
                  />
                }
                label={option[optionLabel]}
              />
            )}
          />
        </Grid>
      ))}
      {isError && (
        <FormHelperText>
          {errors[name] ? (errors[name]?.message as unknown as string) : ''}
        </FormHelperText>
      )}
    </>
  );
};

export default TcCheckboxInput;
