import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from '../../redux/Store';
import { RouteType } from '../../routes/types';

type Props = {
  item: RouteType;
};

const SidebarItem = ({ item }: Props) => {
  const { appState } = useSelector((state: RootState) => state.appState);

  return item.sidebarProps && item.path ? (
    <>
      <ListItemButton
        component={Link}
        to={item.path}
        sx={{
          '&: hover': {
            backgroundColor: 'secondary.light',
          },
          borderLeft: appState === item.state ? '4px solid' : '0px',
          borderLeftColor: appState === item.state ? 'secondary.main' : 'unset',
          paddingY: 1, //'8px',
          paddingX: 3, //'24px',
        }}>
        <ListItemIcon
          sx={{
            paddingX: item.isSubMenu ? 3 : 0, // `${item.isSubMenu ? '24px' : '0px'}`,
            color: appState === item.state ? 'secondary.main' : 'unset',
          }}>
          {item.sidebarProps.icon ?? null}
        </ListItemIcon>
        <ListItemText
          primary={item.sidebarProps.displayText}
          sx={{
            color: appState === item.state ? 'secondary.main' : 'unset',
          }}
        />
      </ListItemButton>
    </>
  ) : null;
};

export default SidebarItem;
