import React from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Typography,
  Checkbox,
} from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import TcTextField from 'common/components/TcTextField';
import { Site } from 'pages/sites/types';

interface SiteLANTabProps {
  site?: Site;
}

type SiteLANForm = {
  interfaceFriendlyName: string;
  ethernetName: string;
  IPAdress: string;
  subnetMask: string;
  gateway: string;
  DNS: string;
  DHCPConfigEnable: string;
  interfaceName: string;
  subnet: string;
  netMask: string;
  defaultLeaseTime: string;
  maxLeaseTime: string;
  rangeFrom: string;
  rangeTo: string;
  router: string;
  DHCPSubnetMask: string;
  broadcastAddress: string;
  DHCPdNS: string;
};

const formDefaultValues: SiteLANForm = {
  interfaceFriendlyName: 'Site Network',
  ethernetName: 'Eno1',
  IPAdress: '192.168.77.200',
  subnetMask: '255.255.255.0',
  gateway: '192.168.77.1',
  DNS: '8.8.8.8',
  DHCPConfigEnable: 'enable',
  interfaceName: 'Site Network',
  subnet: '192.168.77.0',
  netMask: '255.255.255.0',
  defaultLeaseTime: '600',
  maxLeaseTime: '7200',
  rangeFrom: '192.168.77.10',
  rangeTo: '192.168.77.24',
  router: '192.168.77.1',
  DHCPSubnetMask: '255.255.255.0',
  broadcastAddress: '192.168.40.31',
  DHCPdNS: '8.8.8.8',
};

const SiteLANTab: React.FC<SiteLANTabProps> = () => {
  const methods = useForm<SiteLANForm>({
    defaultValues: formDefaultValues,
    mode: 'onBlur',
  });

  return (
    <Card variant="outlined">
      <CardHeader
        title="LAN Configuration"
        style={{ fontSize: '20px' }}
        disableTypography
      />
      <CardContent>
        <FormProvider {...methods}>
          <Grid container spacing={2}>
            <Grid item xs={2}></Grid>
            <Grid item xs={4}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="body1" gutterBottom>
                    {'Interface Name'}
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <TcTextField
                    name="interfaceFriendlyName"
                    label="Friendly Name"
                  />
                </Grid>
                <Grid item xs={10}>
                  <TcTextField name="ethernetName" label="Ethernet Name" />
                </Grid>
                <Grid item xs={10}>
                  <TcTextField name="IPAdress" label="IP Address" />
                </Grid>
                <Grid item xs={10}>
                  <TcTextField name="subnetMask" label="Subnet Mask" />
                </Grid>
                <Grid item xs={10}>
                  <TcTextField name="gateway" label="Gateway" />
                </Grid>
                <Grid item xs={10}>
                  <TcTextField name="DNS" label="DNS" />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="body1" gutterBottom>
                    {' DHCP Server Configuration'}
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography component="header" variant="body1" gutterBottom>
                    <label htmlFor="enable-checkbox">{' Enable'}</label>
                    <Checkbox
                      id="enable-checkbox"
                      color="primary"
                      defaultChecked
                      inputProps={{ 'aria-label': 'Enable' }}
                    />
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <TcTextField name="interfaceName" label="Interface Name" />
                </Grid>
                <Grid item xs={10}>
                  <TcTextField name="subnet" label="Subnet" />
                </Grid>
                <Grid item xs={10}>
                  <TcTextField name="netMask" label="Net Mask" />
                </Grid>
                <Grid item xs={10}>
                  <TcTextField
                    name="defaultLeaseTime"
                    label="Default Lease Time"
                  />
                </Grid>
                <Grid item xs={10}>
                  <TcTextField name="maxLeaseTime" label="Max Lease Time" />
                </Grid>
                <Grid container item xs={10} spacing={1}>
                  <Grid item xs={6}>
                    <TcTextField name="rangeFrom" label="Range From" />
                  </Grid>
                  <Grid item xs={6}>
                    <TcTextField name="rangeTo" label="Range To" />
                  </Grid>
                </Grid>
                <Grid item xs={10}>
                  <TcTextField name="router" label="Routers" />
                </Grid>
                <Grid item xs={10}>
                  <TcTextField name="DHCPSubnetMask" label="Subnet Mask" />
                </Grid>
                <Grid item xs={10}>
                  <TcTextField
                    name="broadcastAddress"
                    label="Broadcast Address"
                  />
                </Grid>
                <Grid item xs={10}>
                  <TcTextField name="DHCPdNS" label="Domain Name Servers" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FormProvider>
      </CardContent>
    </Card>
  );
};

export default SiteLANTab;
