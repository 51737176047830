import React from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Checkbox,
  Typography,
  Grid,
  Radio,
  FormControlLabel,
  Button,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  informationItem: {
    display: 'flex',
    marginBottom: '8px',
  },
  label: {
    width: '20%',
    fontWeight: 'bold',
  },
  value: {
    flex: 1,
  },
  cardHeader: {
    fontSize: '20px',
    fontWeight: 'normal',
  },
}));

const VPNTab: React.FC = () => {
  const classes = useStyles();
  return (
    <Card variant="outlined">
      <CardHeader
        title="VPN"
        className={classes.cardHeader}
        disableTypography
        action={
          <div style={{ position: 'relative', top: 10, right: 400 }}>
            <Typography component="header" variant="body1" gutterBottom>
              <label htmlFor="enable-checkbox">{' Enable'}</label>
              <Checkbox
                id="enable-checkbox"
                color="primary"
                defaultChecked
                inputProps={{ 'aria-label': 'Enable' }}
              />
            </Typography>
          </div>
        }
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <FormControlLabel
              value="client"
              control={<Radio defaultChecked />}
              label="Client"
            />
          </Grid>
          <Grid item xs={9}>
            <div className={classes.informationItem}>
              <Typography className={classes.label}>
                Current Configuration
              </Typography>
              <Typography className={classes.value}>
                Operant_20230601.ovpn
              </Typography>
            </div>
            <div className={classes.informationItem}>
              <Typography className={classes.label}>
                New Configuration
              </Typography>
              <Grid item xs={1}>
                <Button variant="contained" color="info" component="label">
                  Browse
                  <input type="file" style={{ display: 'none' }} />
                </Button>
              </Grid>
              <Grid item xs={1}>
                <Button variant="contained" color="info" component="label">
                  Apply
                </Button>
              </Grid>
            </div>
            <div className={classes.informationItem}>
              <Typography className={classes.label}>Client Name</Typography>
              <Typography className={classes.value}>
                Operant-GW-05042
              </Typography>
            </div>
            <div className={classes.informationItem}>
              <Typography className={classes.label}>
                Remote Server IP
              </Typography>
              <Typography className={classes.value}>20.83.240.31</Typography>
            </div>
            <div className={classes.informationItem}>
              <Typography className={classes.label}>
                Remote Port Number
              </Typography>
              <Typography className={classes.value}>1194</Typography>
            </div>
            <div className={classes.informationItem}>
              <Typography className={classes.label}>Authentication</Typography>
              <Typography className={classes.value}>SHA-512</Typography>
            </div>
            <div className={classes.informationItem}>
              <Typography className={classes.label}>Cipher</Typography>
              <Typography className={classes.value}>AES-256-CBC</Typography>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <FormControlLabel
              value="server"
              control={<Radio />}
              label="Server"
            />
          </Grid>
          <Grid item xs={9}>
            <Button variant="contained" color="info" disabled>
              Settings
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default VPNTab;
