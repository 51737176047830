import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';

import { Organization } from 'pages/settings/types';

type DeleteOrganizationProps = {
  selectedOrganization: Organization;
  open: boolean;
  onCancelRemove: () => void;
  onDeleteOrganization: () => void;
};

const DeleteOrganization: React.FC<DeleteOrganizationProps> = (props) => {
  const handleRemove = () => {
    props.onDeleteOrganization();
  };

  return (
    <React.Fragment>
      <Dialog open={props.open}>
        <DialogTitle>
          {`Delete Organization '${props.selectedOrganization.name}' ?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {'This will remove the Organization.'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={props.onCancelRemove}
            color="info"
            variant="outlined">
            {'Cancel'}
          </Button>
          <Button
            onClick={handleRemove}
            color="warning"
            autoFocus
            variant="contained">
            {'Remove'}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
export default DeleteOrganization;
