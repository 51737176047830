import { FC, useState } from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { RoleFeature } from 'pages/settings/types';

type AccessSettingsProps = {
  permissions?: RoleFeature[];
};

const AccessSettings: FC<AccessSettingsProps> = ({ permissions }) => {
  const { control, getValues, setValue } = useFormContext();

  const [selectAllRead, setSelectAllRead] = useState(false);
  const [selectAllWrite, setSelectAllWrite] = useState(false);
  const [selectAllDelete, setSelectAllDelete] = useState(false);

  const handleSelectAll = (
    accessType: 'read' | 'write' | 'delete',
    checked: boolean,
  ) => {
    switch (accessType) {
      case 'read':
        {
          setSelectAllRead(checked);
          const allRead = getValues('permissions')?.map(
            (item: RoleFeature) => ({
              ...item,
              read: checked,
            }),
          );
          setValue('permissions', allRead, { shouldDirty: true });
        }
        break;
      case 'write':
        {
          setSelectAllWrite(checked);
          const allWrite = getValues('permissions')?.map(
            (item: RoleFeature) => ({
              ...item,
              write: checked,
            }),
          );
          setValue('permissions', allWrite, { shouldDirty: true });
        }
        break;
      case 'delete':
        {
          setSelectAllDelete(checked);
          const allDelete = getValues('permissions')?.map(
            (item: RoleFeature) => ({
              ...item,
              delete: checked,
            }),
          );
          setValue('permissions', allDelete, { shouldDirty: true });
        }
        break;
      default:
        break;
    }
  };

  return (
    <TableContainer sx={{ maxHeight: 440 }}>
      <Table size="small" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>Feature Name</strong>
            </TableCell>
            <TableCell padding="none">
              <strong>Read</strong>
              <br />
              <Checkbox
                checked={selectAllRead}
                onChange={(_event, checked) => handleSelectAll('read', checked)}
              />
            </TableCell>
            <TableCell padding="none">
              <strong>Write</strong>
              <br />
              <Checkbox
                checked={selectAllWrite}
                onChange={(_event, checked) =>
                  handleSelectAll('write', checked)
                }
              />
            </TableCell>
            <TableCell padding="none">
              <strong>Delete</strong>
              <br />
              <Checkbox
                checked={selectAllDelete}
                onChange={(_event, checked) =>
                  handleSelectAll('delete', checked)
                }
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {permissions?.map((feature, index) => (
            <TableRow key={feature.featureId}>
              <TableCell>{feature.featureName}</TableCell>
              <TableCell padding="none">
                <Controller
                  name={`permissions.${index}.read` as any}
                  control={control}
                  render={({ field }) => (
                    <Checkbox {...field} checked={field.value} />
                  )}
                />
              </TableCell>
              <TableCell padding="none">
                <Controller
                  name={`permissions.${index}.write` as any}
                  control={control}
                  render={({ field }) => (
                    <Checkbox {...field} checked={field.value} />
                  )}
                />
              </TableCell>
              <TableCell padding="none">
                <Controller
                  name={`permissions.${index}.delete` as any}
                  control={control}
                  render={({ field }) => (
                    <Checkbox {...field} checked={field.value} />
                  )}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AccessSettings;
