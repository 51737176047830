import { useCallback, useState, useEffect, useMemo, Fragment } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Link } from '@mui/material';

import { Device, DeviceGroup } from '../types';
import { constants } from 'common/constants';
import { useContentStyles } from 'common/styles/useContentStyles';
import { DeviceAccessMethod, FeatureStatus } from 'common/enums';
import apiClient from 'common/apiClientAxios';
import { hasPermission, isEndUser } from 'common/helpers/utils';
import { FeatureSetting } from 'pages/settings/types';
import assets from '../../../assets';
import VideoModal from 'common/components/VideoModal';
import RemoteAccessSSH from '../remoteAccess/RemoteAccessSSH';
import RemoteAccessRDP from '../remoteAccess/RemoteAccessRDP';
import NoRowsOverlay from 'common/components/NoRowsOverlay';
import RemoteAccessVNC from '../remoteAccess/RemoteAccessVNC';

type DevicesTableProps = {
  data: Device[];
};

const deviceDefaultValues = {
  siteId: '',
  name: '',
  alias: '',
  latitude: '',
  longitude: '',
  deviceId: '',
  manufacturerId: '',
  interfaces: [],
  connections: [],
  type: '',
  typeId: '',
  groups: [],
  siteName: '',
  ipAddress: '',
  accessMethods: [],
  manufacturerName: '',
  status: '',
  macAddress: '',
};

const GroupDevicesSubTable: React.FC<DevicesTableProps> = (props) => {
  const classes = useContentStyles();
  const [rows, setRows] = useState<Device[]>(props.data);
  const [selectedDevice, setSelectedDevice] =
    useState<Device>(deviceDefaultValues);
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const [openSSHAccessModal, setOpenSSHAccessModal] = useState(false);
  const [openRDPAccessModal, setOpenRDPAccessModal] = useState(false);
  const [openVNCAccessModal, setOpenVNCAccessModal] = useState(false);

  const handleCloseVideoModal = () => {
    setOpenVideoModal(false);
  };
  const handleCloseSSHAccessModal = () => {
    setOpenSSHAccessModal(false);
  };
  const handleCloseRDPAccessModal = () => {
    setOpenRDPAccessModal(false);
  };
  const handleCloseVNCAccessModal = () => {
    setOpenVNCAccessModal(false);
  };

  const handleAccessMethodClick = useCallback(
    async (accessMethod: string, device: Device) => {
      //If feature is code_devices.remote_access, then show access modal else show video modal
      const featuresResponse = await apiClient.get('/settings/features');
      const featureList = featuresResponse.data.data as FeatureSetting[];
      const remoteAccessSettings = featureList.find(
        (feature) => feature.featureId === 'code_devices.remote_access',
      );
      if (remoteAccessSettings?.status === FeatureStatus.ENABLED) {
        setSelectedDevice(device);
        if (accessMethod.toLocaleLowerCase() === DeviceAccessMethod.SSH) {
          setOpenSSHAccessModal(true);
        } else if (
          accessMethod.toLocaleLowerCase() === DeviceAccessMethod.RDP
        ) {
          setOpenRDPAccessModal(true);
        } else if (
          accessMethod.toLocaleLowerCase() === DeviceAccessMethod.HTTP ||
          accessMethod.toLocaleLowerCase() === DeviceAccessMethod.HTTPS
        ) {
          setOpenVNCAccessModal(true);
        }
      } else {
        setVideoUrl(
          accessMethod === DeviceAccessMethod.SSH
            ? assets.videos.SSHVideo
            : assets.videos.RDPSCADA,
        );
        setOpenVideoModal(true);
      }
    },
    [],
  );

  useEffect(() => {
    setRows(props.data);
  }, [props.data]);

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'name',
        type: 'string',
        headerName: 'Device Name',
        flex: 1,
      },
      {
        field: 'alias',
        type: 'string',
        headerName: 'Alias(es)',
        flex: 1,
      },
      {
        field: 'groups',
        type: 'string',
        headerName: 'Group(s)',
        flex: 1,
        valueGetter: (params) => {
          const groups = (params.row.groups || []) as DeviceGroup[];
          return groups.map((obj) => obj.name).join(', ');
        },
      },
      {
        field: 'siteName',
        type: 'string',
        headerName: 'Site',
        flex: 1,
      },
      { field: 'type', type: 'string', headerName: 'Type', flex: 1 },
      {
        field: 'accessMethods',
        type: 'string',
        headerName: 'Access Methods',
        flex: 1,
        valueGetter: (params) => {
          const accessMethods = (params.row.accessMethods || []) as string[];
          return accessMethods.join(', ');
        },
        renderCell: (params) =>
          (params.row.accessMethods || []).map((method: string) => (
            <Fragment key={`${method}${params.row.deviceId}`}>
              <Link
                component="button"
                sx={{
                  color: (theme) => theme.palette.info.main,
                  textDecorationColor: (theme) => theme.palette.info.main,
                }}
                onClick={() => handleAccessMethodClick(method, params.row)}>
                {method}
              </Link>
              &nbsp;
            </Fragment>
          )),
      },
      {
        field: 'manufacturerName',
        type: 'string',
        headerName: 'Manufacturer',
        flex: 1,
      },
      {
        field: 'interfaces',
        type: 'string',
        headerName: 'Interface',
        flex: 1,
        valueGetter: (params) => {
          const interfaces = (params.row.interfaces || []) as string[];
          return interfaces.join(', ');
        },
      },
      {
        field: 'status',
        type: 'string',
        headerName: 'Status',
        flex: 1,
      },
    ],
    [handleAccessMethodClick],
  );
  // Filter columns based on user role
  const filteredColumns = isEndUser()
    ? columns.filter(
        (column) => column.field !== 'groups' && column.field !== 'interfaces',
      )
    : columns;

  return (
    <>
      <DataGrid
        disableVirtualization
        columns={filteredColumns}
        rows={rows}
        getRowId={(row) => row.deviceId}
        hideFooter={true}
        hideFooterPagination={true}
        autoHeight
        slots={{
          noRowsOverlay: () => (
            <NoRowsOverlay
              hasAccess={hasPermission('devices.summary', 'read')}
              name="Devices"
            />
          ),
        }}
        sx={{
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: '600',
          },
        }}
        getRowClassName={(params) =>
          params.row.type.toLowerCase() === constants.DEVICE_TYPE_GATEWAY
            ? classes.rowColor
            : ''
        }
      />
      {openSSHAccessModal && (
        <RemoteAccessSSH
          open={openSSHAccessModal}
          onClose={handleCloseSSHAccessModal}
          device={selectedDevice}
        />
      )}
      {openRDPAccessModal && (
        <RemoteAccessRDP
          open={openRDPAccessModal}
          onClose={handleCloseRDPAccessModal}
          device={selectedDevice}
        />
      )}
      {openVNCAccessModal && (
        <RemoteAccessVNC
          open={openVNCAccessModal}
          onClose={handleCloseVNCAccessModal}
          device={selectedDevice}
        />
      )}
      <VideoModal
        open={openVideoModal}
        onClose={handleCloseVideoModal}
        videoUrl={videoUrl}
      />
    </>
  );
};
export default GroupDevicesSubTable;
