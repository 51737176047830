import React, { useEffect, useState } from 'react';
import { CssBaseline, Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import GroupAdd from '@mui/icons-material/GroupAdd';

import apiClient from 'common/apiClientAxios';
import { setLoader, setSnackbarToast } from 'redux/UiStateSlice';
import { Site, SiteFormMasterData, State } from '../types';
import { constants } from '../../../common/constants';
import { useDrawerFormStyles } from 'common/styles/useDrawerFormStyles';
import TcAutocomplete from '../../../common/components/TcAutocomplete';
import TcSelectDropdown from 'common/components/TcSelectDropdown';
import TcTextField from 'common/components/TcTextField';
import TcSelectWithStringArray from 'common/components/TcSelectWithStringArray';
import FormHeader from 'common/components/FormHeader';
import TcSelectWithButtonLastOption from 'common/components/TcSelectWithButtonLastOption';
import { hasPermission } from 'common/helpers/utils';

type AddSiteProps = {
  onClose: () => void;
  onSaveUpdateTable: () => void;
  siteFormMasterData: SiteFormMasterData;
  openOperatorForm: () => void;
  openOwnerForm: () => void;
};

const AddSite: React.FC<AddSiteProps> = (props) => {
  const classes = useDrawerFormStyles();
  const dispatch = useDispatch();
  const {
    groups,
    siteTypes,
    siteOwners,
    siteOperators,
    countries,
    complianceLevels,
  } = props.siteFormMasterData;

  const [states, setStates] = useState<State[]>([]);
  const [selectedState, setSelectedState] = useState<State>({
    stateId: '',
    code: '',
    countryId: '',
    countryName: '',
    name: '',
  });
  // const [selectedType, setSelectedType] = useState<SiteType | undefined>({
  //   complianceLevels: [],
  //   name: '',
  //   typeId: '',
  // });

  const onClose = () => {
    props.onClose();
  };

  const methods = useForm<Site>({
    mode: 'onBlur',
  });
  const {
    handleSubmit,
    setValue,
    watch,
    formState: { isValid },
  } = methods;

  const onSaveSite = handleSubmit(async (data: Site) => {
    const country = countries.find(
      (thisCountry) => thisCountry.countryId === data.countryId,
    )?.name;
    const groupName = groups.find(
      (thisGroup) => thisGroup.groupId === data.groupId,
    )?.name;
    const operatorName = siteOperators.find(
      (thisOperator) => thisOperator.operatorId === data.operatorId,
    )?.name;
    const ownerName = siteOwners.find(
      (thisOwner) => thisOwner.ownerId === data.ownerId,
    )?.name;
    const type = siteTypes.find(
      (thisType) => thisType.typeId === data.typeId,
    )?.name;
    try {
      dispatch(setLoader({ loaderMessage: 'Please wait', openLoader: true }));
      const siteResponse = await apiClient.post(`/sites`, {
        ...data,
        country,
        state: selectedState.name,
        groupName,
        powerProductionUnit: constants.POWER_PRODUCTION_UNIT,
        operatorName,
        ownerName,
        type,
      });
      dispatch(setLoader({ loaderMessage: 'Please wait', openLoader: false }));
      dispatch(
        setSnackbarToast({
          message: siteResponse.data.meta.message,
          open: true,
          severity: 'success',
        }),
      );
    } catch (error: any) {
      dispatch(setLoader({ loaderMessage: 'Please wait', openLoader: false }));
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: 'error',
        }),
      );
    }
    props.onSaveUpdateTable();
  });

  const watchedCountryValue = watch('countryId');
  // const watchedTypeValue = watch('typeId');

  // useEffect(() => {
  //   if (watchedTypeValue) {
  //     setSelectedType(
  //       siteTypes.find((site) => site.typeId === watchedTypeValue),
  //     );
  //   }
  // }, [siteTypes, watchedTypeValue]);

  const handleSiteImageFileChange = async (event: any) => {
    const filePath = await uploadImage(event.target.files[0]);
    setValue('image', filePath);
  };

  const handleSiteLayoutFileChange = async (event: any) => {
    const filePath = await uploadImage(event.target.files[0]);
    setValue('layout', filePath);
  };

  const uploadImage = async (selectedFile: any) => {
    const formData = new FormData();
    formData.append('file', selectedFile);
    try {
      dispatch(setLoader({ loaderMessage: 'Please wait', openLoader: true }));
      const filesResponse = await apiClient.post(
        `uploads/site-image`,
        formData,
      );
      const responseData = filesResponse.data.data;
      dispatch(setLoader({ loaderMessage: 'Please wait', openLoader: false }));
      return responseData.filePath;
    } catch (error: any) {
      dispatch(setLoader({ loaderMessage: 'Please wait', openLoader: false }));
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: 'error',
        }),
      );
    }
  };
  const onStateSelect = (_event: any, value: State | null) => {
    if (value) {
      setSelectedState(value);
      setValue('stateId', value.stateId, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  };

  useEffect(() => {
    const fetchStates = async () => {
      if (watchedCountryValue) {
        dispatch(setLoader({ loaderMessage: 'Please wait', openLoader: true }));
        try {
          const response = await apiClient.get(
            `/countries/${watchedCountryValue}/states`,
          );
          setStates(response.data.data);
          dispatch(
            setLoader({ loaderMessage: 'Please wait', openLoader: false }),
          );
        } catch (error: any) {
          dispatch(
            setLoader({ loaderMessage: 'Please wait', openLoader: false }),
          );
          const errorData =
            error.response?.data?.meta?.message || String(error.message);
          dispatch(
            setSnackbarToast({
              message: errorData,
              open: true,
              severity: 'error',
            }),
          );
        }
      }
    };
    fetchStates();
  }, [dispatch, watchedCountryValue]);

  const handleCreateOperator = () => {
    props.openOperatorForm();
  };

  const handleCreateOwner = () => {
    props.openOwnerForm();
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <FormHeader title={`Add Site`} onClose={onClose}></FormHeader>
      <FormProvider {...methods}>
        <form noValidate>
          <Grid container spacing={2} className={classes.formContainer}>
            <Grid item xs={6}>
              <TcTextField
                name="name"
                label="Name *"
                rules={{
                  required: 'Name is required',
                  pattern: {
                    value: constants.NAME_REGEX,
                    message: 'Invalid name',
                  },
                }}></TcTextField>
            </Grid>
            <Grid item xs={6}>
              <TcTextField
                name="alias"
                label="Alias (Nickname)"
                rules={{
                  pattern: {
                    value: constants.NAME_REGEX,
                    message: 'Invalid Alias',
                  },
                }}></TcTextField>
            </Grid>
            <Grid item xs={6}>
              {/* <TcSelectDropdown
                name="ownerId"
                label="Owner *"
                options={siteOwners}
                optionKey="ownerId"
                optionLabel="name"
                rules={{
                  required: 'Owner is required',
                }}
              /> */}
              <TcSelectWithButtonLastOption
                name="ownerId"
                label="Owner *"
                optionKey="ownerId"
                options={siteOwners}
                optionLabel="name"
                buttonIcon={<GroupAdd />}
                buttonLabel="Add owner"
                optionButtonClick={handleCreateOwner}
                optionButtonDisabled={!hasPermission('owners', 'write')}
                rules={{
                  required: 'Owner is required',
                }}></TcSelectWithButtonLastOption>
            </Grid>
            <Grid item xs={6}>
              {/* <TcSelectDropdown
                name="operatorId"
                label="Operator"
                options={siteOperators}
                optionKey="operatorId"
                optionLabel="name"
              /> */}
              <TcSelectWithButtonLastOption
                name="operatorId"
                label="Operator"
                optionKey="operatorId"
                options={siteOperators}
                optionLabel="name"
                buttonIcon={<GroupAdd />}
                buttonLabel="Add operator"
                optionButtonClick={handleCreateOperator}
                optionButtonDisabled={
                  !hasPermission('operators', 'write')
                }></TcSelectWithButtonLastOption>
            </Grid>
            <Grid item xs={6}>
              <TcTextField
                name="addressLine"
                label="Address *"
                rules={{
                  required: 'Address is required',
                }}></TcTextField>
            </Grid>
            <Grid item xs={6}>
              <TcSelectDropdown
                name="countryId"
                label="Country *"
                options={countries}
                optionKey="countryId"
                optionLabel="name"
                rules={{ required: 'Country is required' }}
              />
            </Grid>
            <Grid item xs={6}>
              <TcAutocomplete
                name="stateId"
                label="State *"
                optionKey="stateId"
                options={states}
                value={selectedState}
                onValueSelect={onStateSelect}
                getOptionLabel={(state) => state.name}
                rules={{ required: 'State is required' }}></TcAutocomplete>
            </Grid>
            <Grid item xs={6}>
              <TcTextField
                name="city"
                label="City *"
                rules={{
                  required: 'City is required',
                }}></TcTextField>
            </Grid>
            <Grid item xs={6}>
              <TcTextField
                name="latitude"
                label="Latitude"
                rules={{
                  pattern: {
                    value: constants.LATITUDE_REGEX,
                    message: 'Invalid latitude format',
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TcTextField
                name="longitude"
                label="Longitude"
                rules={{
                  pattern: {
                    value: constants.LONGITUDE_REGEX,
                    message: 'Invalid longitude format',
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TcTextField name="zipcode" label="Zipcode" />
            </Grid>
            <Grid item xs={6}>
              <TcSelectDropdown
                name="typeId"
                label="Type *"
                options={siteTypes}
                optionKey="typeId"
                optionLabel="name"
                rules={{
                  required: 'Type is required',
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TcSelectWithStringArray
                name="complianceLevel"
                label="Compliance Level *"
                options={
                  complianceLevels
                    ? complianceLevels.map(
                        (complianceLevel) => complianceLevel.name,
                      )
                    : []
                }
                rules={{ required: 'Compliance Level is required' }}
              />
            </Grid>
            <Grid item xs={6}>
              <TcTextField
                name="powerProduction"
                label="Production(kW) *"
                rules={{
                  required: 'Production value is required',
                  pattern: {
                    value: constants.NUMERIC_VALUE_REGEX,
                    message: 'Enter numeric value',
                  },
                }}
              />
            </Grid>
            {/* <Grid item xs={6}>
              <TcSelectDropdown
                name="groupId"
                label="Group"
                options={groups}
                optionKey="groupId"
                optionLabel="name"
              />
            </Grid> */}
            <Grid item xs={6}>
              <TcTextField name="contactName" label="Contact Name" />
            </Grid>
            <Grid item xs={6}>
              <TcTextField
                name="contactPhone"
                label="Contact Phone"
                rules={{
                  pattern: {
                    value: constants.PHONE_REGEX,
                    message: 'Invalid contact number',
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TcTextField
                name="contactEmail"
                label="Contact Email"
                rules={{
                  pattern: {
                    value: constants.MAIL_REGEX,
                    message: 'Invalid email',
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TcTextField name="contactCompany" label="Contact Company" />
            </Grid>
            <Grid item xs={6}>
              <TcTextField name="tags" label="Tags" />
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="info"
                component="label"
                disabled>
                Choose Site Image
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: 'none' }}
                  disabled
                  onChange={handleSiteImageFileChange}
                />
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="info"
                component="label"
                disabled>
                Choose Layout Image
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: 'none' }}
                  disabled
                  onChange={handleSiteLayoutFileChange}
                />
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.bottomDivider}>
            <Grid item xs={2}>
              <Button
                size="small"
                type="submit"
                disabled={!isValid}
                onClick={onSaveSite}
                fullWidth
                variant="contained"
                color="info">
                {'Save'}
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                size="small"
                fullWidth
                variant="outlined"
                color="info"
                onClick={onClose}>
                {'Cancel'}
              </Button>
            </Grid>
            <Grid item xs={3}></Grid>
          </Grid>
        </form>
      </FormProvider>
    </React.Fragment>
  );
};

export default AddSite;
