import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
export const useFilterFormStyles = makeStyles((theme: Theme) => ({
  formContainer: {
    width: '100%',
    margin: '1%',
  },
  closeButton: {
    marginTop: 1,
    marginLeft: 2,
    cursor: 'pointer',
  },
  form: {
    width: '100%',
    padding: '1%',
  },
  bottomDivider: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    fontSize: '14px',
  },
  card: {
    width: 'calc(14% - 5px)',
    maxHeight: '360px',
    overflow: 'hidden',
  },
  shortCard: {
    width: 'calc(12% - 5px)',
    maxHeight: '360px',
    overflow: 'hidden',
  },
  shorterCard: {
    width: 'calc(10% - 5px)',
    maxHeight: '360px',
    overflow: 'hidden',
  },
  shortestCard: {
    width: 'calc(9% - 5px)',
    maxHeight: '360px',
    overflow: 'hidden',
  },
  longCard: {
    width: 'calc(18% - 5px)',
    maxHeight: '360px',
    overflow: 'hidden',
  },
  addressCard: {
    width: 'calc(22% - 5px)',
    maxHeight: '360px',
    overflow: 'hidden',
  },
  cardContent: {
    width: '100%',
    maxHeight: '86%',
    overflowY: 'scroll',
    paddingTop: '0px',
  },
  cardHeader: {
    paddingBottom: '0px !important',
  },
  centerAlignedCard: {
    width: 'calc(14% - 5px)',
    maxHeight: '350px',
    overflow: 'scroll',
    display: 'flex',
    alignItems: 'center',
  },
}));
