import React, { useCallback, useRef, useState } from 'react';
import Tab from '@mui/material/Tab';
import ProfileTab from './ProfileTab';
import ResetPasswordTab from './ResetPasswordTab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import apiClient from 'common/apiClientAxios';
import { setLoader, setSnackbarToast } from 'redux/UiStateSlice';
import { User } from 'pages/users/types';
import { useDispatch } from 'react-redux';
import { userDefaultValues } from 'pages/users/Users';

const AccountSettingsPage: React.FC = () => {
  const [tabValue, setTabValue] = useState('1');
  const [userData, setUserData] = useState<User>(userDefaultValues);
  const dispatch = useDispatch();
  const isRunEffect = useRef(true);
  //Fetch user details from backend
  const getUserDetails = useCallback(async () => {
    try {
      dispatch(setLoader({ loaderMessage: 'Please wait', openLoader: true }));
      const userId = localStorage.getItem('user')
        ? JSON.parse(localStorage.getItem('user') as string).userId
        : '';
      const api = `users/${userId}`;
      const userDetailsResponse = await apiClient.get(api);
      const userDetails = userDetailsResponse.data.data as User;
      setUserData(userDetails);
      dispatch(setLoader({ loaderMessage: 'Please wait', openLoader: false }));
    } catch (error: any) {
      dispatch(setLoader({ loaderMessage: 'Please wait', openLoader: false }));
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: 'error',
        }),
      );
    }
  }, [dispatch]);

  React.useEffect(() => {
    if (isRunEffect.current) {
      getUserDetails();
      isRunEffect.current = false;
    }
  }, [getUserDetails, isRunEffect]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const onCancelResetPassword = () => {
    setTabValue('1');
  };

  const onUpdatePassword = () => {
    setTabValue('1');
  };

  return (
    <TabContext value={tabValue}>
      <TabList
        onChange={handleTabChange}
        textColor="primary"
        indicatorColor="secondary">
        <Tab label="Profile" value={'1'} />
        <Tab label="Security" value={'2'} />
      </TabList>
      <TabPanel value="1">
        <ProfileTab user={userData} getUpdatedUser={getUserDetails} />
      </TabPanel>
      <TabPanel value="2">
        <ResetPasswordTab
          user={userData}
          onCancel={onCancelResetPassword}
          onUpdatePassword={onUpdatePassword}
        />
      </TabPanel>
    </TabContext>
  );
};

export default AccountSettingsPage;
