import React, { ReactElement } from 'react';
import { useDrawerFormStyles } from 'common/styles/useDrawerFormStyles';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Grid, Typography, IconButton } from '@mui/material';

type FormHeaderProps = {
  title: string;
  onClose: () => void;
};

const FormHeader = (props: FormHeaderProps): ReactElement => {
  const classes = useDrawerFormStyles();
  return (
    <Grid container className={classes.formTitleContainer}>
      <Grid item xs={11}>
        <Typography component="h6" variant="h6" gutterBottom>
          {props.title}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <IconButton
          onClick={props.onClose}
          sx={{
            position: 'absolute',
            right: 8,
            color: (theme) => theme.palette.grey[700],
          }}>
          <CloseOutlinedIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};
export default FormHeader;
