import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';

import { SiteOperator } from 'pages/settings/types';

type DeleteOperatorProps = {
  selectedOperator: SiteOperator;
  open: boolean;
  onCancelRemove: () => void;
  onDeleteOperator: () => void;
};

const DeleteOperator: React.FC<DeleteOperatorProps> = (props) => {
  const handleRemove = () => {
    props.onDeleteOperator();
  };

  return (
    <React.Fragment>
      <Dialog open={props.open}>
        <DialogTitle>
          {`Delete Site Operator '${props.selectedOperator.name}' ?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {'This will remove the Site Operator.'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={props.onCancelRemove}
            color="info"
            variant="outlined">
            {'Cancel'}
          </Button>
          <Button
            onClick={handleRemove}
            color="warning"
            autoFocus
            variant="contained">
            {'Remove'}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
export default DeleteOperator;
