import React, { useEffect, useRef, useState } from 'react';

import { CssBaseline, FormControl } from '@mui/material';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import GroupAdd from '@mui/icons-material/GroupAdd';
import { RootState } from 'redux/Store';

import { constants } from '../../common/constants';
import { User, UserForm, UserFormMasterData, UserGroup } from './types';
import apiClient from 'common/apiClientAxios';
import TcSelectDropdown from 'common/components/TcSelectDropdown';
import TcTextField from 'common/components/TcTextField';
import { useDrawerFormStyles } from 'common/styles/useDrawerFormStyles';
import { setLoader, setSnackbarToast } from 'redux/UiStateSlice';
import { LoginFeature, UserGroupType, UserStatus } from 'common/enums';
import { State } from 'pages/sites/types';
import TcAutocomplete from 'common/components/TcAutocomplete';
import FormHeader from 'common/components/FormHeader';
// import NoGroupDialog from 'pages/devices/devicesList/NoGroupDialog';
import SelectGroupDialog from './SelectGroupDialog';
import {
  filterUserGroups,
  hasAllElements,
  hasPermission,
} from 'common/helpers/utils';
import TcSelectWithButtonLastOption from 'common/components/TcSelectWithButtonLastOption';

type EditUserProps = {
  onClose: () => void;
  onSaveUpdateTable: () => void;
  selectedUser: User;
  userFormMasterData: UserFormMasterData;
  openAddOrgForm: () => void;
};

const EditUser: React.FC<EditUserProps> = (props: EditUserProps) => {
  const classes = useDrawerFormStyles();
  const dispatch = useDispatch();
  const isSetState = useRef(true);
  const { groups, organizations, countries, roles } = props.userFormMasterData;
  const onClose = () => {
    props.onClose();
  };

  const methods = useForm<UserForm>({
    defaultValues: {
      ...props.selectedUser,
      groups: props.selectedUser.groups ?? [],
      accessMethods: props.selectedUser.accessMethods ?? [],
      organizationId: props.selectedUser.organization?.organizationId,
    },
    mode: 'onBlur',
  });
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { isValid, isDirty },
  } = methods;
  const [states, setStates] = useState<State[]>([]);
  const [selectedState, setSelectedState] = useState<State>({
    stateId: props.selectedUser.stateId ?? '',
    code: '',
    countryId: props.selectedUser.countryId ?? '',
    countryName: props.selectedUser.country ?? '',
    name: props.selectedUser.state ?? '',
  });

  const watchedCountryValue = watch('countryId');
  const onStateSelect = (_event: any, value: State | null) => {
    if (value) {
      setSelectedState(value);
      setValue('stateId', value.stateId, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  };

  useEffect(() => {
    const fetchStates = async () => {
      if (watchedCountryValue) {
        setSelectedState({
          stateId: '',
          code: '',
          countryId: '',
          countryName: '',
          name: '',
        });
        setValue('stateId', '', {
          shouldValidate: true,
          shouldDirty: true,
        });
        dispatch(setLoader({ loaderMessage: 'Please wait', openLoader: true }));
        try {
          const response = await apiClient.get(
            `/countries/${watchedCountryValue}/states`,
          );
          const statesResponse = response.data.data as State[];
          setStates(statesResponse);
          if (isSetState.current) {
            const state = statesResponse.find(
              (thisState) => thisState.stateId === props.selectedUser.stateId,
            );
            if (state) {
              setSelectedState(state);
              setValue('stateId', state.stateId, {
                shouldValidate: true,
                shouldDirty: true,
              });
            }
            isSetState.current = false;
          }
          dispatch(
            setLoader({ loaderMessage: 'Please wait', openLoader: false }),
          );
        } catch (error: any) {
          dispatch(
            setLoader({ loaderMessage: 'Please wait', openLoader: false }),
          );
          const errorData =
            error.response?.data?.meta?.message || String(error.message);
          dispatch(
            setSnackbarToast({
              message: errorData,
              open: true,
              severity: 'error',
            }),
          );
        }
      }
    };
    fetchStates();
  }, [dispatch, props.selectedUser.stateId, setValue, watchedCountryValue]);

  const [affectedDynamicGroups, setAffectedDynamicGroups] = useState<
    UserGroup[]
  >([]);
  const [submittedUserFormData, setSubmittedUserFormData] = useState<UserForm>({
    ...props.selectedUser,
    groups: props.selectedUser.groups ?? [],
    accessMethods: props.selectedUser.accessMethods ?? [],
  });
  // const [openNoGroupDialog, setOpenNoGroupDialog] = useState(false);
  const [openSelectGroupDialog, setOpenSelectGroupDialog] = useState(false);

  const onUpdateUser = handleSubmit(async (data: UserForm) => {
    setSubmittedUserFormData(data);
    const dynamicGroups = groups.filter(
      (group) => group.type === UserGroupType.DYNAMIC,
    );
    //if affected group is same as existing device group then submit form
    const dynamicGroupIds = dynamicGroups.map((grp) => grp.groupId);
    const defaultGroupIds = props.selectedUser.groups?.map(
      (grp) => grp.groupId,
    );
    if (
      dynamicGroupIds.length === defaultGroupIds?.length &&
      hasAllElements(dynamicGroupIds, defaultGroupIds)
    ) {
      submitUser(defaultGroupIds, data);
    } else {
      const filterGroups = filterUserGroups(dynamicGroups, data);
      setAffectedDynamicGroups(filterGroups);
      if (filterGroups.length === 0) {
        // setOpenNoGroupDialog(true);
        submitUser([], data);
      } else {
        setOpenSelectGroupDialog(true);
      }
    }
  });

  const submitUser = async (selectedGroups?: string[], data?: UserForm) => {
    try {
      const formData = data ? data : submittedUserFormData;
      const userGroups = groups
        .filter((group) => selectedGroups?.includes(group.groupId))
        .map((obj) => {
          return { groupId: obj.groupId, name: obj.name };
        });
      const roleName = roles.find(
        (thisRole) => thisRole.roleId === formData.roleId,
      )?.name;
      const country = countries.find(
        (thisCountry) => thisCountry.countryId === formData.countryId,
      )?.name;
      const organization = organizations.find(
        (thisOrg) => thisOrg.organizationId === formData.organizationId,
      );
      dispatch(setLoader({ loaderMessage: 'Please wait', openLoader: true }));
      const userResponse = await apiClient.put(
        `/users/${props.selectedUser.userId}`,
        {
          ...formData,
          country,
          groups: userGroups,
          state: selectedState.name,
          roleName,
          fromTime: formData.fromTime
            ? new Date(formData.fromTime).toISOString()
            : undefined,
          toTime: formData.toTime
            ? new Date(formData.toTime).toISOString()
            : undefined,
          organization: organization && {
            organizationId: organization?.organizationId,
            name: organization?.name,
          },
        },
      );
      dispatch(setLoader({ loaderMessage: 'Please wait', openLoader: false }));
      dispatch(
        setSnackbarToast({
          message: userResponse.data.meta.message,
          open: true,
          severity: 'success',
        }),
      );
    } catch (error: any) {
      dispatch(setLoader({ loaderMessage: 'Please wait', openLoader: false }));
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: 'error',
        }),
      );
    }
    props.onSaveUpdateTable();
  };

  const handleCreateOrganization = () => {
    props.openAddOrgForm();
  };

  const { availableLogins } = useSelector(
    (state: RootState) => state.loginSettingsState,
  );

  return (
    <React.Fragment>
      <CssBaseline />
      <FormHeader
        title={`Edit User : ${props.selectedUser.firstName} ${props.selectedUser.lastName}`}
        onClose={onClose}></FormHeader>
      <FormProvider {...methods}>
        <form noValidate>
          <Grid container spacing={2} className={classes.formContainer}>
            <Grid item xs={6}>
              <TcTextField
                name="email"
                label="Email*"
                rules={{
                  required: {
                    value: true,
                    message: 'Email Address required',
                  },
                  pattern: {
                    value: constants.EMAIL_REGEX,
                    message: 'Invalid Email Address',
                  },
                }}></TcTextField>
            </Grid>
            <Grid item xs={6}>
              <TcTextField
                name="firstName"
                label="First Name*"
                rules={{
                  required: {
                    value: true,
                    message: 'First name is required',
                  },
                  pattern: {
                    value: constants.USER_NAME_REGEX,
                    message: 'Invalid First name',
                  },
                }}></TcTextField>
            </Grid>
            <Grid item xs={6}>
              <TcTextField
                name="lastName"
                label="Last Name*"
                rules={{
                  required: {
                    value: true,
                    message: 'Last name is required',
                  },
                  pattern: {
                    value: constants.USER_NAME_REGEX,
                    message: 'Invalid Last name',
                  },
                }}></TcTextField>
            </Grid>
            <Grid item xs={6}>
              <TcTextField
                name="middleName"
                label="Middle Name"
                rules={{
                  pattern: {
                    value: constants.USER_NAME_REGEX,
                    message: 'Invalid Last name',
                  },
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <TcTextField
                name="phone"
                label="Contact Phone"
                rules={{
                  pattern: {
                    value: constants.PHONE_REGEX,
                    message: 'Invalid contact number',
                  },
                }}
              />
            </Grid>
            {availableLogins.includes(LoginFeature.CUSTOM) && (
              <Grid item xs={6}>
                <TcTextField
                  name="username"
                  label="Username*"
                  rules={{
                    required: {
                      value: true,
                      message: 'Username is required',
                    },
                    pattern: {
                      value: constants.NAME_REGEX,
                      message: 'Invalid username',
                    },
                  }}></TcTextField>
              </Grid>
            )}
            <Grid item xs={6}>
              <TcTextField
                name="ndnIdentity"
                label="NDN Identity"></TcTextField>
            </Grid>
            <Grid item xs={6}>
              <TcSelectDropdown
                name="roleId"
                label="Role*"
                options={roles}
                optionKey="roleId"
                optionLabel="name"
                rules={{
                  required: {
                    value: true,
                    message: 'Role is required',
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TcSelectWithButtonLastOption
                name="organizationId"
                label="Organization"
                optionKey="organizationId"
                options={organizations}
                optionLabel="name"
                buttonIcon={<GroupAdd />}
                buttonLabel="Add Organization"
                optionButtonClick={handleCreateOrganization}
                optionButtonDisabled={
                  !hasPermission('organizations', 'write')
                }></TcSelectWithButtonLastOption>
            </Grid>
            <Grid item xs={6}>
              <TcTextField name="employeeId" label="Employee ID" />
            </Grid>
            <Grid item xs={6}>
              <TcTextField name="manager" label="Manager Name" />
            </Grid>
            <Grid item xs={6}>
              <TcTextField name="managerId" label="Manager ID" />
            </Grid>
            <Grid item xs={6}>
              <TcTextField name="addressLine" label="Address" />
            </Grid>
            <Grid item xs={6}>
              <TcSelectDropdown
                name="countryId"
                label="Country"
                options={countries}
                optionKey="countryId"
                optionLabel="name"
              />
            </Grid>
            <Grid item xs={6}>
              <TcAutocomplete
                name="stateId"
                label="State"
                optionKey="stateId"
                options={states}
                value={selectedState}
                onValueSelect={onStateSelect}
                getOptionLabel={(state) => state.name}></TcAutocomplete>
            </Grid>
            <Grid item xs={6}>
              <TcTextField name="city" label="City"></TcTextField>
            </Grid>
            <Grid item xs={6}>
              <TcTextField
                name="zipcode"
                label="Zipcode"
                rules={{
                  pattern: {
                    value: constants.ZIP_CODE_REGEX,
                    message: 'Invalid zipcode',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">User status</FormLabel>
                <Controller
                  name="status"
                  control={control}
                  render={({ field }) => (
                    <RadioGroup {...field}>
                      <Grid container direction="row">
                        <Grid item>
                          <FormControlLabel
                            value={UserStatus.ACTIVE}
                            control={
                              <Radio
                                color={
                                  field.value === 'Active'
                                    ? 'success'
                                    : 'default'
                                }
                              />
                            }
                            label="Active"
                          />
                        </Grid>
                        <Grid item>
                          <FormControlLabel
                            value={UserStatus.INACTIVE}
                            control={
                              <Radio
                                color={
                                  field.value === 'Inactive'
                                    ? 'error'
                                    : 'default'
                                }
                              />
                            }
                            label="Inactive"
                          />
                        </Grid>
                      </Grid>
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={2}>
              <Button
                size="small"
                type="submit"
                onClick={onUpdateUser}
                fullWidth
                variant="contained"
                color="info"
                id="save"
                disabled={!isValid || !isDirty}>
                {'Save'}
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                size="small"
                fullWidth
                variant="outlined"
                color="info"
                onClick={onClose}
                id="cancel">
                {'Cancel'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
      {/* {openNoGroupDialog && (
        <NoGroupDialog
          onCancel={() => setOpenNoGroupDialog(false)}
          onConfirmSave={() => submitUser()}
          open={openNoGroupDialog}
          dialogTitle="No group applied to this user"
          dialogContent="This user will not be accessible until it is added to a group and trust rules apply to it"></NoGroupDialog>
      )} */}
      {openSelectGroupDialog && (
        <SelectGroupDialog
          groups={affectedDynamicGroups}
          onCancel={() => setOpenSelectGroupDialog(false)}
          onSaveGroup={(selectedGroups) => submitUser(selectedGroups)}
          open={openSelectGroupDialog}
          //currentUserGroups={props.selectedUser.groups?.map(grp=> grp.groupId)}
        ></SelectGroupDialog>
      )}
    </React.Fragment>
  );
};

export default EditUser;
