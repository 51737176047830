import React, { useCallback, useState } from 'react';
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Guacamole from 'guacamole-common-js';
import $ from 'jquery';
import { DeviceDetails, UsernameAndPasswordForm } from 'pages/devices/types';
import apiClient from 'common/apiClientAxios';
import { RDPConnectionSettings } from 'common/types';
import { useDispatch } from 'react-redux';
import { setSnackbarToast } from 'redux/UiStateSlice';
import { deviceAccessErrors } from 'pages/devices/device-access-errors';
import RDPLoginForm from './RDPLoginForm';

interface RemoteAccessRDPProps {
  open: boolean;
  onClose: () => void;
  device: DeviceDetails;
}
const RemoteAccessRDP: React.FC<RemoteAccessRDPProps> = ({
  open,
  onClose,
  device,
}) => {
  const [guacamoleClient, setGuacamoleClient] = useState<Guacamole.Client>();
  const [guacamoleClientState, setGuacamoleClientState] = useState(-1);
  const [guacamoleTunnelState, setGuacamoleTunnelState] = useState(-1);
  const [guacamoleKeyboard] = useState<Guacamole.Keyboard>(
    new Guacamole.Keyboard(document),
  );
  const [showUsernamePasswordForm, setShowUsernamePasswordForm] =
    useState(true);
  const [errorMessage, setErrorMessage] = useState('Something went wrong...');
  const dispatch = useDispatch();

  const onSubmitCredentials = async (data: UsernameAndPasswordForm) => {
    setErrorMessage('');
    setShowUsernamePasswordForm(false);
    await getToken({
      hostname: device.ipAddress,
      username: data.username,
      password: data.password,
    });
  };

  const connectToDevice = useCallback(
    (token: string) => {
      window.setTimeout(() => {
        const display = document.getElementById('RDPdisplay');
        if (display && token) {
          const webSocketBaseURL =
            process.env.REACT_APP_WEB_SOCKET_URL ?? 'ws://localhost:8082/';
          const guacTunnel = new Guacamole.WebSocketTunnel(
            `${webSocketBaseURL}/?token=${token}`,
          );
          const guac = new Guacamole.Client(guacTunnel);
          setGuacamoleClient(guac);
          const displayElement = guac.getDisplay().getElement();

          // Add client to display div
          const canvas = $(displayElement).find(`canvas`);
          canvas.css('z-index', '2');
          display?.appendChild(displayElement);

          // Mouse
          const mouse = new Guacamole.Mouse(guac.getDisplay().getElement());
          mouse.onmousedown = (state: Guacamole.Mouse.State) => {
            guac.sendMouseState(state);
          };
          mouse.onmousemove = (state: Guacamole.Mouse.State) => {
            guac.sendMouseState(state);
          };
          mouse.onmouseup = (state: Guacamole.Mouse.State) => {
            guac.sendMouseState(state);
          };
          // Keyboard
          guacamoleKeyboard.onkeydown = function (keysym) {
            guac.sendKeyEvent(1, keysym);
          };
          guacamoleKeyboard.onkeyup = function (keysym) {
            guac.sendKeyEvent(0, keysym);
          };

          guac.onstatechange = (state: Guacamole.Client.State) => {
            setGuacamoleClientState(state);
            if (state === 5) {
              setShowUsernamePasswordForm(true);
            }
          };
          guacTunnel.onstatechange = (state: Guacamole.Tunnel.State) => {
            setGuacamoleTunnelState(state);
            if (state === 2) {
              setShowUsernamePasswordForm(true);
            }
          };
          const handleDisconnect = () => {
            if (display.hasChildNodes()) display?.removeChild(displayElement);
            guacamoleKeyboard.onkeyup = null;
            guacamoleKeyboard.onkeydown = null;
            guac.disconnect();
            guacTunnel.disconnect();
          };
          const handleError = (error: Guacamole.Status) => {
            setErrorMessage(
              deviceAccessErrors[error.code] ?? 'Something went wrong...',
            );
            handleDisconnect();
          };
          // Error handler
          guac.onerror = function (error) {
            handleError(error);
          };
          guacTunnel.onerror = function (error) {
            handleError(error);
          };
          // Connect
          guac.connect('test');
          // Disconnect on close
          window.onunload = function () {
            handleDisconnect();
          };
        }
      }, 1000);
    },
    [guacamoleKeyboard],
  );

  const getToken = useCallback(
    async (connectionSettings: RDPConnectionSettings) => {
      try {
        const tokenResponse = await apiClient.post(
          `devices/${device.deviceId}/getRDPToken`,
          { ...connectionSettings },
        );
        const token = tokenResponse.data.data;
        connectToDevice(token);
        return token;
      } catch (error: any) {
        const errorData =
          error.response?.data?.meta?.message || String(error.message);
        dispatch(
          setSnackbarToast({
            message: errorData,
            open: true,
            severity: 'error',
          }),
        );
      }
    },
    [connectToDevice, device.deviceId, dispatch],
  );

  const onDisconnect = () => {
    guacamoleKeyboard.onkeyup = null;
    guacamoleKeyboard.onkeydown = null;
    guacamoleClient?.disconnect();
    onClose();
  };
  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="lg"
      PaperProps={{
        style: {
          height: '856px',
          width: '1100px',
        },
      }}>
      <DialogTitle sx={{ m: 0, p: 2 }} />
      <IconButton
        aria-label="close"
        onClick={onDisconnect}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}>
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          spacing={2}
          style={{ minHeight: '100%' }}>
          {/* Loading State */}
          {(guacamoleTunnelState === 0 ||
            guacamoleClientState === 1 ||
            guacamoleClientState === 2) &&
            guacamoleTunnelState !== 2 && (
              <>
                <Grid item>
                  <CircularProgress />
                </Grid>
                <Grid item>
                  <div>Connecting...</div>
                </Grid>
              </>
            )}
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center">
            {/* Error Message */}
            {(guacamoleClientState === 5 || guacamoleTunnelState === 2) && (
              <Grid item xs={12}>
                <Typography color="error" align="center">
                  {errorMessage}
                </Typography>
              </Grid>
            )}
            {/* Login Form */}
            {showUsernamePasswordForm && (
              <RDPLoginForm
                handleSubmitForm={onSubmitCredentials}
                onDisconnect={onDisconnect}></RDPLoginForm>
            )}
          </Grid>
          <Grid item xs={12}>
            <div id="RDPdisplay"></div>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
export default RemoteAccessRDP;
