import { FC, useCallback, useEffect } from 'react';
import { Backdrop, CircularProgress, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useMsal } from '@azure/msal-react';
import apiClient from 'common/apiClientAxios';
import { setLoader, setSnackbarToast } from 'redux/UiStateSlice';
import { constants } from 'common/constants';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { setUserData } from 'redux/UserStateSlice';

const useStyles = makeStyles((theme: Theme) => ({
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
    color: theme.palette.background.default,
  },
}));

const AzureADCallback: FC = () => {
  const { accounts, inProgress } = useMsal();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  const handleCallback = useCallback(async () => {
    try {
      dispatch(setLoader({ loaderMessage: 'Please wait', openLoader: true }));
      let firstName = '';
      let lastName = '';
      if (accounts[0].name) {
        firstName = accounts[0].name?.split(' ')[0];
        lastName = accounts[0].name?.split(' ')[1];
      }
      const loginUser = {
        email: accounts[0].username,
        username: accounts[0].username,
        firstName,
        lastName,
      };
      const loginResponse = await apiClient.post(`/auth/azure-login`, {
        ...loginUser,
      });
      const userLoginData = loginResponse.data.data;
      localStorage.setItem('access_token', userLoginData.accessToken);
      localStorage.setItem('user', JSON.stringify(userLoginData));
      dispatch(setUserData(userLoginData));
      dispatch(setLoader({ loaderMessage: 'Please wait', openLoader: false }));
      navigate(constants.HOME_PAGE_PATH);
    } catch (apiError: any) {
      dispatch(setLoader({ loaderMessage: 'Please wait', openLoader: false }));
      const errorData =
        apiError.response?.data?.meta?.message || String(apiError.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: 'error',
        }),
      );
    }
  }, [accounts, dispatch, navigate]);

  useEffect(() => {
    if (accounts[0]) {
      handleCallback();
    }
  }, [accounts, handleCallback, inProgress]);

  useEffect(() => {
    const interval = setInterval(() => {
      const keys = Object.keys(localStorage);
      keys.forEach((key) => {
        const value = localStorage.getItem(key);
        if (
          !accounts[0] &&
          value &&
          value.includes('failedRequests') &&
          value.includes('errors')
        ) {
          dispatch(
            setSnackbarToast({
              message: 'Error while login with Azure, please try again',
              open: true,
              severity: 'error',
            }),
          );
          localStorage.clear();
          navigate(constants.LOGIN_PATH);
        }
      });
    }, 2000);
    return () => {
      clearInterval(interval);
    };
  }, [accounts, dispatch, navigate]);

  return (
    <Backdrop open={true} className={classes.backdrop}>
      <CircularProgress color="inherit" />
      <Typography component="h5" variant="h5">
        Processing Azure Login...
      </Typography>
    </Backdrop>
  );
};

export default AzureADCallback;
