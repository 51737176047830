'use client';
import { useState } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { ErrorBoundary } from 'react-error-boundary';

import { Outlet } from 'react-router-dom';
import Sidebar from '../Sidebar';
import TopBar from '../Headerbar';
import { constants } from 'common/constants';
import ErrorFallback from '../ErrorFallback';

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const MainLayout = () => {
  const [open, setOpen] = useState(true);

  const [sidebarWidth, setSidebarWidth] = useState(constants.DRAWER_WIDTH);
  const handleDrawerOpen = () => {
    setOpen(true);
    setSidebarWidth(constants.DRAWER_WIDTH);
  };
  const handleDrawerClose = () => {
    setOpen(false);
    setSidebarWidth(65);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <TopBar
        handleDrawerOpen={handleDrawerOpen}
        open={open}
        handleDrawerClose={handleDrawerClose}></TopBar>
      <Sidebar handleDrawerClose={handleDrawerClose} open={open} />
      <Box
        component="main"
        sx={{
          p: 2,
          paddingTop: 5,
          marginTop: 5,
          width: `calc(100% - ${sidebarWidth}px)`,
          minHeight: '90vh',
          backgroundColor: 'background.default',
        }}>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Outlet />
        </ErrorBoundary>
      </Box>
    </Box>
  );
};

export default MainLayout;
