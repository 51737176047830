import React from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Theme,
  Checkbox,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { GridColDef, DataGrid } from '@mui/x-data-grid';

const useStyles = makeStyles((theme: Theme) => ({
  customHeader: {
    backgroundColor: theme.palette.secondary.main,
    fontWeight: 'bold',
    color: 'white',
  },
  customCellTextWrap: {
    whiteSpace: 'normal',
  },
  cardHeader: {
    fontSize: '20px',
    fontWeight: 'normal',
  },
}));

const SecurityTab: React.FC = () => {
  const classes = useStyles();
  const columns: GridColDef[] = [
    {
      field: 'publication',
      headerName: 'Publication/ Packet',
      width: 200,
      headerClassName: classes.customHeader,
    },
    {
      field: 'publishSigning',
      headerName: 'Publish Signing',
      width: 200,
      headerClassName: classes.customHeader,
      renderCell: (params) => {
        return <div className={classes.customCellTextWrap}>{params.value}</div>;
      },
    },
    {
      field: 'publishEncryption',
      headerName: 'Publish Encryption',
      width: 200,
      headerClassName: classes.customHeader,
      renderCell: (params) => {
        return <div className={classes.customCellTextWrap}>{params.value}</div>;
      },
    },
    {
      field: 'responseSigning',
      headerName: 'Response Signing',
      width: 200,
      headerClassName: classes.customHeader,
      renderCell: (params) => {
        return <div className={classes.customCellTextWrap}>{params.value}</div>;
      },
    },
    {
      field: 'responseEncryption',
      headerName: 'Response Encryption',
      width: 200,
      headerClassName: classes.customHeader,
      renderCell: (params) => {
        return <div className={classes.customCellTextWrap}>{params.value}</div>;
      },
    },
    {
      field: 'comments',
      headerName: 'Comments',
      flex: 1,
      headerClassName: classes.customHeader,
      renderCell: (params) => {
        return <div className={classes.customCellTextWrap}>{params.value}</div>;
      },
    },
  ];
  const rows = [
    {
      publication: 'Command',
      publishSigning: 'EdDSA  Client software-based private key',
      publishEncryption: 'AEAD-256',
      responseSigning: 'EdDSA  Gateway software-based private signing key',
      responseEncryption: 'AEAD-256',
      comments:
        'Full end-end security for command and return Data. Publishing signing entity determines access control privileges. Signing key and certificate updated every day and verified with TPM private key.',
    },
    {
      publication: 'Transport',
      publishSigning: 'AEAD-256 authentication ',
      publishEncryption: 'AEAD-256',
      responseSigning: 'EdDSA  Gateway software-based private signing key',
      responseEncryption: 'AEAD-256 authentication',
      comments:
        'Each transport link is AEAD-256 encrypted with separate key giving authentication and to hide the pub headers.',
    },
    {
      publication: 'AEAD key (updated hourly)',
      publishSigning: 'EdDSA Server software instance private key',
      publishEncryption: 'EdDSA public key from certificate collection',
      responseSigning: '',
      responseEncryption: '',
      comments:
        'AEAD-256 content key encrypted in turn with each public key in certificate collection.  Separate AEAD keys for end-end and transport link encryption and authentication.',
    },
    {
      publication: 'X.509 Certificate',
      publishSigning: '',
      publishEncryption: '',
      responseSigning: '',
      responseEncryption: '',
      comments: 'Certificate is signed by root CA and is public',
    },
    {
      publication: 'Signing Certificate',
      publishSigning: 'EdDSA TPM-based private signing key',
      publishEncryption: '',
      responseSigning: '',
      responseEncryption: '',
      comments:
        'Software signing key used for speed with public certificate signed by TPM private key having X.509 certificate.',
    },
  ];

  return (
    <Card variant="outlined">
      <CardHeader
        title="Security and Authentication"
        className={classes.cardHeader}
        disableTypography
        action={
          <div style={{ position: 'relative', top: 10, right: 10 }}>
            <Typography component="header" variant="body1" gutterBottom>
              <label htmlFor="enable-checkbox">{' Enable'}</label>
              <Checkbox
                id="enable-checkbox"
                color="primary"
                defaultChecked
                inputProps={{ 'aria-label': 'Enable' }}
              />
            </Typography>
          </div>
        }
      />
      <CardContent>
        <DataGrid
          disableVirtualization
          rows={rows}
          columns={columns}
          getRowId={(row) => row.publication}
          getRowHeight={() => 75}
          sx={{
            '& .MuiDataGrid-row:hover': {
              cursor: 'pointer',
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: '600',
            },
          }}
        />
      </CardContent>
    </Card>
  );
};

export default SecurityTab;
