import React, { useCallback, useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CssBaseline,
  Grid,
  Typography,
} from '@mui/material';

import { DeviceFormMasterData, DevicesGroupFilterData } from '../types';
import { useFilterFormStyles } from 'common/styles/useFilterFormStyles';
import TcCheckboxInput from 'common/components/TcCheckboxInput';
import { DeviceStatus } from 'common/enums';

const deviceStatuses = [
  { id: DeviceStatus.ONLINE, label: DeviceStatus.ONLINE },
  { id: DeviceStatus.OFFLINE, label: DeviceStatus.OFFLINE },
];

type FilterProps = {
  onApplyFilter: (formData: DevicesGroupFilterData) => void;
  filters: DevicesGroupFilterData;
  handleClearFilter: () => void;
  handleCancelFilter: () => void;
  deviceFormMasterData: DeviceFormMasterData;
  isShowCancel?: boolean;
  isResetForm?: boolean;
};

const DevicesFilter: React.FC<FilterProps> = (filterProps) => {
  const classes = useFilterFormStyles();
  const {
    deviceTypes,
    manufacturers,
    sites,
    interfaces,
    accessMethods,
    groups,
  } = filterProps.deviceFormMasterData;
  const { isShowCancel = true } = filterProps;
  const [statuses] = useState<
    {
      id: string;
      label: string;
    }[]
  >(deviceStatuses);
  const methods = useForm({
    defaultValues: filterProps.filters,
  });
  const { handleSubmit, reset } = methods;

  const onSubmit = handleSubmit(async (data: DevicesGroupFilterData) => {
    filterProps.onApplyFilter(data);
  });

  const resetForm = useCallback(() => {
    reset({
      accessMethods: [],
      manufacturers: [],
      types: [],
      statuses: [],
      interfaces: [],
      sites: [],
      groups: [],
    });
  }, [reset]);

  const onClear = () => {
    resetForm();
    filterProps.handleClearFilter();
  };

  const onCancel = () => {
    resetForm();
    filterProps.handleCancelFilter();
  };

  useEffect(() => {
    if (filterProps.isResetForm) {
      resetForm();
    }
  }, [filterProps.isResetForm, resetForm]);

  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.root}>
        <FormProvider {...methods}>
          {isShowCancel && (
            <Card key={1} className={classes.longCard} variant="outlined">
              <CardHeader
                subheader={
                  <Typography variant="subtitle1">
                    <strong>Groups</strong>
                  </Typography>
                }
                className={classes.cardHeader}></CardHeader>
              <CardContent className={classes.cardContent}>
                <TcCheckboxInput
                  name="groups"
                  optionKey="groupId"
                  optionLabel="name"
                  options={groups}
                  className="custom-checkbox-font-size"
                />
              </CardContent>
            </Card>
          )}

          <Card key={2} className={classes.shortCard} variant="outlined">
            <CardHeader
              subheader={
                <Typography variant="subtitle1">
                  <strong>Access Methods</strong>
                </Typography>
              }
              className={classes.cardHeader}></CardHeader>
            <CardContent>
              <TcCheckboxInput
                name="accessMethods"
                optionKey="name"
                optionLabel="name"
                options={accessMethods}
                className="custom-checkbox-font-size"
              />
            </CardContent>
          </Card>
          <Card key={3} className={classes.shortCard} variant="outlined">
            <CardHeader
              subheader={
                <Typography variant="subtitle1">
                  <strong>Interfaces</strong>
                </Typography>
              }
              className={classes.cardHeader}></CardHeader>
            <CardContent>
              <TcCheckboxInput
                name="interfaces"
                optionKey="name"
                optionLabel="name"
                options={interfaces}
                className="custom-checkbox-font-size"
              />
            </CardContent>
          </Card>
          <Card key={4} className={classes.shortCard} variant="outlined">
            <CardHeader
              subheader={
                <Typography variant="subtitle1">
                  <strong>Type</strong>
                </Typography>
              }
              className={classes.cardHeader}></CardHeader>
            <CardContent>
              <TcCheckboxInput
                name="types"
                optionKey="deviceTypeId"
                optionLabel="name"
                options={deviceTypes}
                className="custom-checkbox-font-size"
              />
            </CardContent>
          </Card>
          <Card key={5} className={classes.card} variant="outlined">
            <CardHeader
              subheader={
                <Typography variant="subtitle1">
                  <strong>Manufacturer</strong>
                </Typography>
              }
              className={classes.cardHeader}></CardHeader>

            <CardContent className={classes.cardContent}>
              <TcCheckboxInput
                name="manufacturers"
                optionKey="manufacturerId"
                optionLabel="name"
                options={manufacturers}
                className="custom-checkbox-font-size"
              />
            </CardContent>
          </Card>
          <Card key={6} className={classes.longCard} variant="outlined">
            <CardHeader
              subheader={
                <Typography variant="subtitle1">
                  <strong>Site</strong>
                </Typography>
              }
              className={classes.cardHeader}></CardHeader>
            <CardContent className={classes.cardContent}>
              <TcCheckboxInput
                name="sites"
                optionKey="siteId"
                optionLabel="name"
                options={sites}
                className="custom-checkbox-font-size"
              />
            </CardContent>
          </Card>
          <Card key={7} className={classes.shortestCard} variant="outlined">
            <CardHeader
              subheader={
                <Typography variant="subtitle1">
                  <strong>Status</strong>
                </Typography>
              }
              className={classes.cardHeader}></CardHeader>
            <CardContent>
              <TcCheckboxInput
                name="statuses"
                optionKey="id"
                optionLabel="label"
                options={statuses}
                className="custom-checkbox-font-size"
              />
            </CardContent>
          </Card>
          <Grid container spacing={2} className={classes.bottomDivider}>
            <Grid item xs={2}>
              <Button
                size="small"
                type="submit"
                onClick={onSubmit}
                fullWidth
                variant="contained"
                color="info">
                {'Apply All'}
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                size="small"
                fullWidth
                variant="outlined"
                color="info"
                onClick={onClear}>
                {'Clear All'}
              </Button>
            </Grid>
            {isShowCancel && (
              <Grid item xs={2}>
                <Button
                  size="small"
                  fullWidth
                  variant="outlined"
                  color="info"
                  onClick={onCancel}>
                  {'Cancel'}
                </Button>
              </Grid>
            )}
          </Grid>
        </FormProvider>
      </div>
    </React.Fragment>
  );
};
export default DevicesFilter;
