import React, { useCallback, useRef, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CssBaseline,
  Grid,
  Typography,
} from '@mui/material';
import { useDispatch } from 'react-redux';

import apiClient from 'common/apiClientAxios';
import { setLoader, setSnackbarToast } from 'redux/UiStateSlice';
import { Country, State, SiteFormMasterData, SiteFilterData } from '../types';
import { useFilterFormStyles } from 'common/styles/useFilterFormStyles';
import TcCheckboxInput from 'common/components/TcCheckboxInput';
import TcTextField from 'common/components/TcTextField';
import TcSlider from 'common/components/TcSlider';
import { powerProductionDefaultValues } from './SitesTable';
import '../../../common/styles/filter.css';
import TcAutocompleteMultiSelect from 'common/components/TcAutocompleteMultiSelect';

type FilterProps = {
  onApplyFilter: (formData: any) => void;
  handleClearFilter: () => void;
  handleCancelFilter: () => void;
  filters: SiteFilterData;
  siteFormMasterData: SiteFormMasterData;
};

const SitesFilter: React.FC<FilterProps> = (filterProps) => {
  const classes = useFilterFormStyles();
  const dispatch = useDispatch();
  const { siteTypes, siteOwners, siteOperators, countries, complianceLevels } =
    filterProps.siteFormMasterData;

  const [states, setStates] = useState<State[]>([]);

  const [selectedCountries, setSelectedCountries] = useState<Country[]>(
    filterProps.filters.selectedCountries,
  );
  const [selectedStates, setSelectedStates] = useState<State[]>(
    filterProps.filters.selectedStates,
  );
  const [powerDefaultValue, setPowerDefaultValue] = useState<number[]>(
    filterProps.filters.powerProduction,
  );

  const methods = useForm({
    defaultValues: filterProps.filters,
  });

  const { handleSubmit, setValue, reset } = methods;

  const onSubmit = handleSubmit(async (data: any) => {
    filterProps.onApplyFilter(data);
  });

  const onCountrySelect = (
    _event: React.SyntheticEvent<Element, Event>,
    value: Country[],
  ) => {
    setValue('selectedCountries', value);
    setSelectedCountries(value);
  };

  const onStateSelect = (
    _event: React.SyntheticEvent<Element, Event>,
    value: State[],
  ) => {
    setValue('selectedStates', value);
    setSelectedStates(value);
  };

  const resetForm = () => {
    reset({
      selectedCountries: [],
      selectedStates: [],
      city: '',
      powerProduction: powerProductionDefaultValues,
      selectedGroups: [],
      selectedOwners: [],
      selectedOperators: [],
      selectedCompliances: [],
      selectedSiteTypes: [],
    });
    setSelectedCountries([]);
    setSelectedStates([]);
    setPowerDefaultValue(powerProductionDefaultValues);
  };

  const onClear = () => {
    resetForm();
    filterProps.handleClearFilter();
  };

  const onCancel = () => {
    resetForm();
    filterProps.handleCancelFilter();
  };

  const isRunEffect = useRef(true);
  const getStates = useCallback(async () => {
    try {
      dispatch(setLoader({ loaderMessage: 'Please wait', openLoader: true }));
      const statesResponse = await apiClient.get(`/states`);
      setStates(statesResponse.data.data);
      dispatch(setLoader({ loaderMessage: 'Please wait', openLoader: false }));
    } catch (error: any) {
      dispatch(setLoader({ loaderMessage: 'Please wait', openLoader: false }));
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: 'error',
        }),
      );
    }
  }, [dispatch]);

  React.useEffect(() => {
    if (isRunEffect.current) {
      getStates();
      isRunEffect.current = false;
    }
  }, [getStates]);

  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.root}>
        <FormProvider {...methods}>
          {/* <Card
            key={1}
            className={classes.card} variant="outlined">
              <CardHeader subheader={
              <Typography variant="subtitle1">
              <strong>Groups</strong>
            </Typography>
            } className={classes.cardHeader}></CardHeader>
            <CardContent className={classes.cardContent}>
              <TcCheckboxInput
                name="selectedGroups"
                optionKey="groupId"
                optionLabel="name"
                options={groups}
                className='custom-checkbox-font-size'
              />
            </CardContent>
          </Card> */}
          <Card key={2} className={classes.card} variant="outlined">
            <CardHeader
              subheader={
                <Typography variant="subtitle1">
                  <strong>Owner</strong>
                </Typography>
              }
              className={classes.cardHeader}></CardHeader>
            <CardContent className={classes.cardContent}>
              <TcCheckboxInput
                name="selectedOwners"
                optionKey="ownerId"
                optionLabel="name"
                options={siteOwners}
                className="custom-checkbox-font-size"
              />
            </CardContent>
          </Card>
          <Card key={3} className={classes.card} variant="outlined">
            <CardHeader
              subheader={
                <Typography variant="subtitle1">
                  <strong>Operator</strong>
                </Typography>
              }
              className={classes.cardHeader}></CardHeader>
            <CardContent>
              <TcCheckboxInput
                name="selectedOperators"
                optionKey="operatorId"
                optionLabel="name"
                options={siteOperators}
                className="custom-checkbox-font-size"
              />
            </CardContent>
          </Card>
          <Card key={4} className={classes.shortCard} variant="outlined">
            <CardHeader
              subheader={
                <Typography variant="subtitle1">
                  <strong>Compliance Level</strong>
                </Typography>
              }
              className={classes.cardHeader}></CardHeader>
            <CardContent>
              <TcCheckboxInput
                name="selectedCompliances"
                optionKey="name"
                optionLabel="name"
                options={complianceLevels}
                className="custom-checkbox-font-size"
              />
            </CardContent>
          </Card>
          <Card key={5} className={classes.shortestCard} variant="outlined">
            <CardHeader
              subheader={
                <Typography variant="subtitle1">
                  <strong>Type</strong>
                </Typography>
              }
              className={classes.cardHeader}></CardHeader>
            <CardContent>
              <TcCheckboxInput
                name="selectedSiteTypes"
                optionKey="typeId"
                optionLabel="name"
                options={siteTypes}
                className="custom-checkbox-font-size"
              />
            </CardContent>
          </Card>
          <Card key={6} className={classes.longCard} variant="outlined">
            <CardHeader
              subheader={
                <Typography variant="subtitle1">
                  <strong>Address</strong>
                </Typography>
              }
              className={classes.cardHeader}></CardHeader>
            <CardContent>
              <TcAutocompleteMultiSelect
                name="selectedCountries"
                value={
                  selectedCountries.map((country) =>
                    countries.find((c) => c.countryId === country.countryId),
                  ) as Country[]
                }
                label="Country"
                onValueSelect={onCountrySelect}
                optionKey="countryId"
                // optionLabel='name'
                getOptionLabel={(country) => country.name}
                options={countries}></TcAutocompleteMultiSelect>
            </CardContent>
            <CardContent>
              <TcAutocompleteMultiSelect
                name="selectedStates"
                value={
                  selectedStates.map((state) =>
                    states.find((c) => c.stateId === state.stateId),
                  ) as State[]
                }
                label="State"
                onValueSelect={onStateSelect}
                optionKey="stateId"
                getOptionLabel={(state) => state.name}
                // optionLabel='name'
                options={states}></TcAutocompleteMultiSelect>
            </CardContent>
            <CardContent>
              <TcTextField name="city"></TcTextField>
            </CardContent>
          </Card>
          <Card key={7} className={classes.longCard} variant="outlined">
            <CardHeader
              subheader={
                <Typography variant="subtitle1">
                  <strong>Power Production(kW)</strong>
                </Typography>
              }
              className={classes.cardHeader}></CardHeader>
            <CardContent>
              <TcSlider
                name="powerProduction"
                step={1000}
                defaultValues={powerDefaultValue}
                min={0}
                max={1000000}
              />
            </CardContent>
          </Card>
        </FormProvider>
        <Grid container spacing={2} className={classes.bottomDivider}>
          <Grid item xs={2}>
            <Button
              size="small"
              type="submit"
              onClick={onSubmit}
              fullWidth
              variant="contained"
              color="info">
              {'Apply All'}
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              size="small"
              fullWidth
              variant="outlined"
              color="info"
              onClick={onClear}>
              {'Clear All'}
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              size="small"
              fullWidth
              variant="outlined"
              color="info"
              onClick={onCancel}>
              {'Cancel'}
            </Button>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default SitesFilter;
