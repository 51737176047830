import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, Autocomplete, TextField, Checkbox } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface TcAutocompleteMultiSelectProps<T> {
  name: string;
  label: string;
  defaultValue?: T[];
  value: T[];
  options: T[];
  optionKey: keyof T;
  rules?: Record<string, any>;
  onValueSelect: (
    _event: React.SyntheticEvent<Element, Event>,
    value: T[],
  ) => void;
  getOptionLabel: (option: T) => string;
}

const TcAutocompleteMultiSelect = <T extends Record<string, any>>({
  name,
  defaultValue,
  label,
  options,
  optionKey,
  rules,
  value,
  onValueSelect,
  getOptionLabel,
}: TcAutocompleteMultiSelectProps<T>) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <FormControl fullWidth variant="outlined" error={Boolean(errors[name])}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <Autocomplete
            {...field}
            size="small"
            options={options}
            multiple
            getOptionLabel={(option) => getOptionLabel(option)}
            value={
              value.map((option) =>
                options.find((c) => c[optionKey] === option[optionKey]),
              ) as T[]
            }
            filterSelectedOptions
            onChange={onValueSelect}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {getOptionLabel(option)}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                label={label}
                variant="outlined"
                error={Boolean(errors[name])}
                helperText={
                  errors[name]
                    ? (errors[name]?.message as unknown as string)
                    : ''
                }
                fullWidth
              />
            )}
          />
        )}
        rules={rules}
      />
    </FormControl>
  );
};

export default TcAutocompleteMultiSelect;
