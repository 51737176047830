import React from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Button,
  Grid,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import ReactSpeedometer from 'react-d3-speedometer';
import { SiteCloudConfig } from 'pages/sites/types';

interface CellularTabProps {
  information: SiteCloudConfig;
}

const useStyles = makeStyles({
  informationItem: {
    display: 'flex',
    marginBottom: '8px',
  },
  label: {
    width: '30%',
    fontWeight: 'bold',
  },
  value: {
    flex: 1,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '8px',
    padding: '16px',
  },
  cardHeader: {
    fontSize: '20px',
    fontWeight: 'normal',
  },
});

const CellularTab: React.FC<CellularTabProps> = ({ information }) => {
  const classes = useStyles();

  return (
    <Card variant="outlined">
      <CardHeader
        title="Cellular Connection Status"
        className={classes.cardHeader}
        disableTypography
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={7}>
            <div className={classes.informationItem}>
              <Typography className={classes.label}>State</Typography>
              <Typography className={classes.value}>
                {information.state}
              </Typography>
            </div>
            <div className={classes.informationItem}>
              <Typography className={classes.label}>RSSI</Typography>
              <Typography className={classes.value}>
                {information.rssi}
              </Typography>
            </div>
            <div className={classes.informationItem}>
              <Typography className={classes.label}>Provider</Typography>
              <Typography className={classes.value}>
                {information.provider}
              </Typography>
            </div>
            <div className={classes.informationItem}>
              <Typography className={classes.label}>APN</Typography>
              <Typography className={classes.value}>
                {information.apn}
              </Typography>
            </div>
            <div className={classes.informationItem}>
              <Typography className={classes.label}>IP</Typography>
              <Typography className={classes.value}>
                {information.ip}
              </Typography>
            </div>
            <div className={classes.informationItem}>
              <Typography className={classes.label}>Number</Typography>
              <Typography className={classes.value}>
                {information.number}
              </Typography>
            </div>
            <div className={classes.informationItem}>
              <Typography className={classes.label}>IMEI</Typography>
              <Typography className={classes.value}>
                {information.imei}
              </Typography>
            </div>
            <div className={classes.informationItem}>
              <Typography className={classes.label}>
                Access Technology
              </Typography>
              <Typography className={classes.value}>
                {information.accessTechnology}
              </Typography>
            </div>
            <div className={classes.informationItem}>
              <Typography className={classes.label}>Model</Typography>
              <Typography className={classes.value}>
                {information.model}
              </Typography>
            </div>
            <div className={classes.informationItem}>
              <Typography className={classes.label}>Manufacturer</Typography>
              <Typography className={classes.value}>
                {information.manufacturer}
              </Typography>
            </div>
            <div className={classes.informationItem}>
              <Typography className={classes.label}>
                Hardware Version
              </Typography>
              <Typography className={classes.value}>
                {information.hardwareVersion}
              </Typography>
            </div>
            <div className={classes.informationItem}>
              <Typography className={classes.label}>
                Firmware Version
              </Typography>
              <Typography className={classes.value}>
                {information.firmwareVersion}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={5}>
            <ReactSpeedometer
              maxValue={100}
              value={40}
              needleColor="red"
              ringWidth={45}
              needleHeightRatio={0.7}
              customSegmentStops={[0, 25, 50, 100]}
              segments={3}
              segmentColors={['#ed6c02', '#fab117', '#0092b7']}
              currentValueText="Signal Strength: ${value}"
              customSegmentLabels={[
                {
                  text: 'LOW',
                  position: 'INSIDE' as any,
                  color: '#FFF',
                },
                {
                  text: 'OK',
                  position: 'INSIDE' as any,
                  color: '#FFF',
                },
                {
                  text: 'GOOD',
                  position: 'INSIDE' as any,
                  color: '#FFF',
                },
              ]}
            />
          </Grid>
        </Grid>
      </CardContent>
      <div className={classes.buttonContainer}>
        <Button variant="contained" color="info" disabled>
          Logout
        </Button>
        <Button variant="contained" color="info" disabled>
          Restart
        </Button>
        <Button variant="contained" color="info" disabled>
          Power Off
        </Button>
      </div>
    </Card>
  );
};

export default CellularTab;
