import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';

import { Role } from 'pages/settings/types';

type DeleteRoleProps = {
  selectedRole: Role;
  open: boolean;
  onCancelRemove: () => void;
  onDeleteRole: () => void;
};

const DeleteRole: React.FC<DeleteRoleProps> = (props) => {
  const handleRemove = () => {
    props.onDeleteRole();
  };

  return (
    <React.Fragment>
      <Dialog open={props.open}>
        <DialogTitle>{`Delete Role '${props.selectedRole.name}' ?`}</DialogTitle>
        <DialogContent>
          <DialogContentText>{'This will remove the Role.'}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={props.onCancelRemove}
            color="info"
            variant="outlined">
            {'Cancel'}
          </Button>
          <Button
            onClick={handleRemove}
            color="warning"
            autoFocus
            variant="contained">
            {'Remove'}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
export default DeleteRole;
