interface DeviceAccessErrors {
  [code: number]: string;
}

export const deviceAccessErrors: DeviceAccessErrors = {
  768: 'The operation could not be performed because bad parameters were given',
  769: 'Permission was denied to perform the operation, as the user is not yet authorized',
  770: 'Permission was denied to perform the operation, and this permission will not be granted even if the user is authorized.',
  519: 'Connection failed (server unreachable)',
};
