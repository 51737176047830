import React from 'react';
import {
  Avatar,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Stack,
  Toolbar,
} from '@mui/material';
// import SearchIcon from '@mui/icons-material/Search';
// import NotificationsIcon from '@mui/icons-material/Notifications';
// import HelpIcon from '@mui/icons-material/HelpOutline';
import PersonIcon from '@mui/icons-material/Person';
import { makeStyles } from '@mui/styles';
import { styled, Theme } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import SettingsIcon from '@mui/icons-material/Settings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import assets from '../../assets';
import { useState } from 'react';
import AccountSettingsDialogue from 'pages/settings/AccountSettings/AccountSettingsDialogue';
import useAuth from 'pages/login/authService';

const drawerWidth = 240;
const useStyles = makeStyles((theme: Theme) => ({
  toolbarIcons: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  searchIcon: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  textField: {
    background: 'white',
    border: '1px solid white',
    borderRadius: theme.spacing(1),
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white',
      },
      '&:hover fieldset': {
        borderColor: 'white',
      },
    },
  },
}));

type TopbarProps = {
  open: boolean;
  handleDrawerOpen: () => void;
  handleDrawerClose: () => void;
};
interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const TopBar = ({ open, handleDrawerOpen, handleDrawerClose }: TopbarProps) => {
  const classes = useStyles();
  const { logout } = useAuth();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [openSettingsDialogue, setOpenSettingsDialogue] = React.useState(false);

  const onCloseSettingsDialogue = () => {
    setOpenSettingsDialogue(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAccountSettingsClick = () => {
    // navigate(`/portal/settings/account`);
    setOpenSettingsDialogue(true);
    setAnchorEl(null);
  };
  const handleLogoutClick = () => {
    logout();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <Stack
            sx={{ width: '100%' }}
            direction="row"
            justifyContent="left"
            spacing={2}>
            {!open ? (
              <>
                <Avatar
                  sx={{ width: 35, height: 35, cursor: 'pointer' }}
                  src={assets.images.circleLogo}
                  onClick={handleDrawerOpen}
                />
                <button onClick={handleDrawerOpen}>
                  <KeyboardDoubleArrowRightIcon />
                </button>
              </>
            ) : (
              <button onClick={handleDrawerClose}>
                <KeyboardDoubleArrowLeftIcon />
              </button>
            )}
          </Stack>
          {/* {open ?
            <button onClick={handleDrawerOpen}>
            <KeyboardDoubleArrowLeftIcon />
          </button>:
            <button onClick={handleDrawerOpen}>
            <KeyboardDoubleArrowRightIcon />
          </button>
        } */}
          <div className={classes.toolbarIcons}>
            {/* <TextField
              id="search-field"
              variant="outlined"
              size="small"
              className={classes.textField}
            />
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div> */}
            <IconButton color="inherit" onClick={handleClick}>
              <PersonIcon />
            </IconButton>
            {/* TODO: Hide help icon for time being 
            <IconButton color="inherit" href="/help-doc.html"
            target="_blank">
              <HelpIcon />
            </IconButton> */}
            {/* <IconButton color="inherit">
              <NotificationsIcon />
            </IconButton> */}
          </div>
        </Toolbar>
      </AppBar>

      <Popover
        id="user-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          padding: 2,
        }}>
        <List>
          <ListItemButton onClick={handleAccountSettingsClick}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Account Settings" />
          </ListItemButton>
          <ListItemButton onClick={handleLogoutClick}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItemButton>
        </List>
      </Popover>

      {openSettingsDialogue && (
        <AccountSettingsDialogue
          onCancel={onCloseSettingsDialogue}
          open={openSettingsDialogue}
        />
      )}
    </>
  );
};

export default TopBar;
