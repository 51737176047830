import { configureStore } from '@reduxjs/toolkit';

import appStateSlice from './AppStateSlice';
import themeSlice from './ThemeSlice';
import uiStateSlice from './UiStateSlice';
import userStateSlice from './UserStateSlice';
import loginSettingsSlice from './LoginSettingsSlice';

export const Store = configureStore({
  reducer: {
    appState: appStateSlice,
    themeState: themeSlice,
    uiState: uiStateSlice,
    userState: userStateSlice,
    loginSettingsState: loginSettingsSlice,
  },
});

export type RootState = ReturnType<typeof Store.getState>;
