import React, { useCallback, useRef, useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Guacamole from 'guacamole-common-js';
import { DeviceDetails } from 'pages/devices/types';
import apiClient from 'common/apiClientAxios';
import { SSHConnectionSettings } from 'common/types';
import { useDispatch } from 'react-redux';
import { setSnackbarToast } from 'redux/UiStateSlice';
import { deviceAccessErrors } from 'pages/devices/device-access-errors';

interface RemoteAccessSSHProps {
  open: boolean;
  onClose: () => void;
  device: DeviceDetails;
}

const RemoteAccessSSH: React.FC<RemoteAccessSSHProps> = ({
  open,
  onClose,
  device,
}) => {
  const isRunEffect = useRef(true);
  const [guacamoleClient, setGuacamoleClient] = useState<Guacamole.Client>();
  const [guacamoleKeyboard] = useState<Guacamole.Keyboard>(
    new Guacamole.Keyboard(document),
  );
  const [guacamoleClientState, setGuacamoleClientState] = useState(-1);
  const [guacamoleTunnelState, setGuacamoleTunnelState] = useState(-1);
  const [errorMessage, setErrorMessage] = useState('Something went wrong...');
  const dispatch = useDispatch();

  const onReconnectSSH = async () => {
    await getToken({ hostname: device.ipAddress });
  };

  const connectToDevice = useCallback(
    (token: string) => {
      window.setTimeout(() => {
        const display = document.getElementById('SSHdisplay');
        if (display && token) {
          const webSocketBaseURL =
            process.env.REACT_APP_WEB_SOCKET_URL ?? 'ws://localhost:8082/';
          const guacTunnel = new Guacamole.WebSocketTunnel(
            `${webSocketBaseURL}/?token=${token}`,
          );
          const guac = new Guacamole.Client(guacTunnel);
          setGuacamoleClient(guac);
          const displayElement = guac.getDisplay().getElement();
          display?.appendChild(displayElement);

          // Mouse
          const mouse = new Guacamole.Mouse(guac.getDisplay().getElement());
          mouse.onmousedown = (state: Guacamole.Mouse.State) => {
            guac.sendMouseState(state);
          };
          mouse.onmousemove = (state: Guacamole.Mouse.State) => {
            guac.sendMouseState(state);
          };
          mouse.onmouseup = (state: Guacamole.Mouse.State) => {
            guac.sendMouseState(state);
          };
          // Keyboard
          guacamoleKeyboard.onkeydown = function (keysym) {
            guac.sendKeyEvent(1, keysym);
          };
          guacamoleKeyboard.onkeyup = function (keysym) {
            guac.sendKeyEvent(0, keysym);
          };
          guac.onstatechange = (state: Guacamole.Client.State) => {
            //if client connection closed and canvas is displayed then remove canvas and close dialog
            if (state === 5 && display.hasChildNodes()) {
              handleDisconnect();
              onClose();
            } else {
              setGuacamoleClientState(state);
            }
          };
          guacTunnel.onstatechange = (state: Guacamole.Tunnel.State) => {
            setGuacamoleTunnelState(state);
          };
          const handleDisconnect = () => {
            if (display.hasChildNodes()) display?.removeChild(displayElement);
            guacamoleKeyboard.onkeyup = null;
            guacamoleKeyboard.onkeydown = null;
            guac.disconnect();
            guacTunnel.disconnect();
          };
          const handleError = (error: Guacamole.Status) => {
            setErrorMessage(
              deviceAccessErrors[error.code] ?? 'Something went wrong...',
            );
            handleDisconnect();
          };
          // Error handler
          guac.onerror = function (error) {
            handleError(error);
          };
          guacTunnel.onerror = function (error) {
            handleError(error);
          };
          // Connect
          guac.connect('test');
          // Disconnect on close
          window.onunload = function () {
            handleDisconnect();
          };
        }
      }, 1000);
    },
    [guacamoleKeyboard, onClose],
  );

  const getToken = useCallback(
    async (connectionSettings: SSHConnectionSettings) => {
      try {
        const apiUrl = `devices/${device.deviceId}/getSSHToken`;
        const tokenResponse = await apiClient.post(apiUrl, {
          ...connectionSettings,
        });
        const token = tokenResponse.data.data;
        connectToDevice(token);
        return token;
      } catch (error: any) {
        const errorData =
          error.response?.data?.meta?.message || String(error.message);
        dispatch(
          setSnackbarToast({
            message: errorData,
            open: true,
            severity: 'error',
          }),
        );
      }
    },
    [connectToDevice, device.deviceId, dispatch],
  );

  React.useEffect(() => {
    if (isRunEffect.current) {
      isRunEffect.current = false;
      getToken({ hostname: device.ipAddress });
    }
  }, [device.ipAddress, getToken]);

  const onDisconnect = () => {
    guacamoleClient?.disconnect();
    guacamoleKeyboard.onkeyup = null;
    guacamoleKeyboard.onkeydown = null;
    onClose();
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="lg"
      PaperProps={{
        style: {
          height: '856px',
          width: '1100px',
        },
      }}>
      <DialogTitle sx={{ m: 0, p: 2 }} />
      <IconButton
        aria-label="close"
        onClick={onDisconnect}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}>
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          spacing={2}
          style={{ minHeight: '100%' }}>
          {/* Loading State */}
          {(guacamoleTunnelState === 0 ||
            guacamoleClientState === 1 ||
            guacamoleClientState === 2) &&
            guacamoleTunnelState !== 2 && (
              <>
                <Grid item>
                  <CircularProgress />
                </Grid>
                <Grid item>
                  <div>Connecting...</div>
                </Grid>
              </>
            )}
          {/* Error State */}
          {(guacamoleClientState === 5 || guacamoleTunnelState === 2) && (
            <Grid item>
              <Typography color="error" align="center">
                {errorMessage}
              </Typography>
              <Paper elevation={3} style={{ padding: '20px' }}>
                <Grid
                  container
                  item
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}>
                  <Grid item>
                    <Button
                      size="small"
                      variant="outlined"
                      color="info"
                      onClick={onDisconnect}
                      id="cancel">
                      {'Cancel'}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      size="small"
                      variant="contained"
                      color="info"
                      onClick={onReconnectSSH}>
                      {'ReConnect'}
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          )}
          <Grid item xs={12}>
            <div id="SSHdisplay"></div>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
export default RemoteAccessSSH;
