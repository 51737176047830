import React, { useEffect, useState } from 'react';
import { CssBaseline, Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import GroupAdd from '@mui/icons-material/GroupAdd';

import apiClient from 'common/apiClientAxios';
import { setLoader, setSnackbarToast } from 'redux/UiStateSlice';
import { constants } from '../../../common/constants';
import { useDrawerFormStyles } from 'common/styles/useDrawerFormStyles';
import { DeviceForm, DeviceFormMasterData, DeviceGroup } from '../types';
import TcTextField from 'common/components/TcTextField';
import TcSelectDropdown from 'common/components/TcSelectDropdown';
import { deviceDefaultValues } from './Devices';
import {
  DeviceAccessMethod,
  DeviceGroupType,
  // DevicePort,
  DeviceStatus,
} from 'common/enums';
import { AccessMethod } from 'pages/users/types';
import FormHeader from 'common/components/FormHeader';
// import NoGroupDialog from './NoGroupDialog';
import SelectGroupDialog from './SelectGroupDialog';
import { filterDeviceGroup } from 'common/helpers/utils';
import TcSelectWithButtonLastOption from 'common/components/TcSelectWithButtonLastOption';

type AddDeviceProps = {
  onClose: () => void;
  onSaveUpdateTable: () => void;
  deviceFormMasterData: DeviceFormMasterData;
  openAddManufacturerForm: () => void;
};

const AddDevice: React.FC<AddDeviceProps> = (props) => {
  const classes = useDrawerFormStyles();
  const dispatch = useDispatch();
  const {
    groups,
    devices,
    deviceTypes,
    manufacturers,
    sites,
    interfaces,
    accessMethods,
  } = props.deviceFormMasterData;
  const onClose = () => {
    props.onClose();
  };

  const methods = useForm<DeviceForm>({
    defaultValues: deviceDefaultValues,
    mode: 'onBlur',
  });
  const {
    handleSubmit,
    watch,
    setValue,
    formState: { isValid },
  } = methods;

  const [affectedDynamicGroups, setAffectedDynamicGroups] = useState<
    DeviceGroup[]
  >([]);
  const [submittedDeviceFormData, setSubmittedDeviceFormData] =
    useState<DeviceForm>({ ...deviceDefaultValues });
  // const [openNoGroupDialog, setOpenNoGroupDialog] = useState(false);
  const [openSelectGroupDialog, setOpenSelectGroupDialog] = useState(false);

  const onSaveDevice = handleSubmit(async (data: DeviceForm) => {
    setSubmittedDeviceFormData(data);
    const dynamicGroups = groups.filter(
      (group) => group.type === DeviceGroupType.DYNAMIC,
    );
    const filterGroups = filterDeviceGroup(dynamicGroups, data);
    setAffectedDynamicGroups(filterGroups);
    if (filterGroups.length === 0) {
      // setOpenNoGroupDialog(true);
      submitDevice([], data);
    } else {
      setOpenSelectGroupDialog(true);
    }
  });

  const submitDevice = async (selectedGroups?: string[], data?: DeviceForm) => {
    try {
      const formData = data ? data : submittedDeviceFormData;
      const deviceGroups = groups
        .filter((group) => selectedGroups?.includes(group.groupId))
        .map((obj) => {
          return { groupId: obj.groupId, name: obj.name };
        });
      const selectedConnections = devices
        .filter((device) => formData.connections?.includes(device.deviceId))
        .map((obj) => {
          return { deviceId: obj.deviceId, name: obj.name };
        });
      const selectedType = deviceTypes.find(
        (deviceType) => deviceType.deviceTypeId === formData.typeId,
      );
      const selectedManufacturer = manufacturers.find(
        (manufacturer) =>
          manufacturer.manufacturerId === formData.manufacturerId,
      );
      const type = selectedType?.name;
      const manufacturerName = selectedManufacturer?.name;
      const siteName = sites.find(
        (site) => site.siteId === formData.siteId,
      )?.name;

      dispatch(setLoader({ loaderMessage: 'Please wait', openLoader: true }));
      const deviceResponse = await apiClient.post(`/devices`, {
        ...formData,
        groups: deviceGroups,
        connections: selectedConnections,
        type,
        manufacturerName,
        siteName,
        status: DeviceStatus.OFFLINE, // static status TODO: remove
        sshPort: formData.sshPort ?? undefined,
        rdpPort: formData.rdpPort ?? undefined,
      });
      dispatch(setLoader({ loaderMessage: 'Please wait', openLoader: false }));
      dispatch(
        setSnackbarToast({
          message: deviceResponse.data.meta.message,
          open: true,
          severity: 'success',
        }),
      );
    } catch (error: any) {
      dispatch(setLoader({ loaderMessage: 'Please wait', openLoader: false }));
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: 'error',
        }),
      );
    }
    props.onSaveUpdateTable();
  };

  const watchedTypeValue = watch('typeId');
  // const watchedAccessMethodsValues = watch('accessMethods');
  const [formAccessMethods, setFormAccessMethods] = useState<AccessMethod[]>(
    [],
  );

  useEffect(() => {
    if (watchedTypeValue) {
      setValue('accessMethods', []);
      const type = deviceTypes.find(
        (deviceType) => deviceType.deviceTypeId === watchedTypeValue,
      )?.name;
      if (type?.toLocaleLowerCase() !== constants.DEVICE_TYPE_GATEWAY) {
        setFormAccessMethods(
          accessMethods.filter(
            (method) =>
              method.name.toLocaleLowerCase() !== DeviceAccessMethod.CONFIG,
          ),
        ); //remove config
      } else {
        setFormAccessMethods(
          accessMethods.filter(
            (method) =>
              method.name.toLocaleLowerCase() === DeviceAccessMethod.CONFIG,
          ),
        );
      }
    }
  }, [accessMethods, deviceTypes, setValue, watchedTypeValue]);

  const handleCreateManufacturer = () => {
    props.openAddManufacturerForm();
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <FormHeader title="Add Device" onClose={onClose}></FormHeader>
      <FormProvider {...methods}>
        <form noValidate>
          <Grid container spacing={2} className={classes.formContainer}>
            <Grid item xs={6}>
              <TcTextField
                name="name"
                label="Name *"
                rules={{
                  required: 'Name is required',
                  pattern: {
                    value: constants.NAME_REGEX,
                    message: 'Invalid name',
                  },
                }}></TcTextField>
            </Grid>
            <Grid item xs={6}>
              <TcTextField
                name="alias"
                label="Alias"
                rules={{
                  pattern: {
                    value: constants.NAME_REGEX,
                    message: 'Invalid Alias',
                  },
                }}></TcTextField>
            </Grid>
            <Grid item xs={6}>
              <TcSelectWithButtonLastOption
                name="manufacturerId"
                label="Manufacturer *"
                optionKey="manufacturerId"
                options={manufacturers}
                optionLabel="name"
                buttonIcon={<GroupAdd />}
                buttonLabel="Add Manufacturer"
                optionButtonClick={handleCreateManufacturer}
                rules={{
                  required: 'Manufacturer is required',
                }}></TcSelectWithButtonLastOption>
            </Grid>
            <Grid item xs={6}>
              <TcSelectDropdown
                name="siteId"
                label="Site *"
                options={sites}
                optionKey="siteId"
                optionLabel="name"
                rules={{
                  required: 'Site is required',
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TcSelectDropdown
                name="interfaces"
                label="Interface(s)"
                multiple
                options={interfaces}
                optionKey="name"
                optionLabel="name"
              />
            </Grid>
            <Grid item xs={6}>
              <TcTextField
                name="ipAddress"
                label="Local IP Address *"
                rules={{
                  required: 'Local IP Address is required',
                  pattern: {
                    value: constants.IP_ADDRESS_REGEX,
                    message: 'Invalid IP',
                  },
                }}></TcTextField>
            </Grid>

            <Grid item xs={6}>
              <TcTextField
                name="latitude"
                label="Latitude"
                rules={{
                  pattern: {
                    value: constants.LATITUDE_REGEX,
                    message: 'Invalid Latitude',
                  },
                }}></TcTextField>
            </Grid>
            <Grid item xs={6}>
              <TcTextField
                name="longitude"
                label="Longitude"
                rules={{
                  pattern: {
                    value: constants.LONGITUDE_REGEX,
                    message: 'Invalid Longitude',
                  },
                }}></TcTextField>
            </Grid>
            <Grid item xs={6}>
              <TcSelectDropdown
                name="typeId"
                label="Type *"
                options={deviceTypes}
                optionKey="deviceTypeId"
                optionLabel="name"
                rules={{
                  required: 'Type is required',
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TcSelectDropdown
                name="accessMethods"
                label="Access Method(s) *"
                multiple
                options={formAccessMethods}
                optionKey="name"
                optionLabel="name"
                rules={{
                  required: 'Access Method is required',
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TcSelectDropdown
                name="connections"
                label="Device Logical Connections"
                multiple
                options={devices}
                optionKey="deviceId"
                optionLabel="name"
              />
            </Grid>
            <Grid item xs={6}>
              <TcTextField
                name="macAddress"
                label="MAC Address"
                rules={{
                  pattern: {
                    value: constants.MAC_ADDRESS_REGEX,
                    message: 'Invalid MAC Address',
                  },
                }}></TcTextField>
            </Grid>
            {/* { isStringInArray(watchedAccessMethodsValues, DeviceAccessMethod.SSH) && (<Grid item xs={6}>
            <TcTextField
                name="sshPort"
                label="SSH Port"
                defaultValue={DevicePort.SSH}
                rules={{
                  pattern: {
                    value: constants.DEVICE_PORT_PATTERN,
                    message: 'Invalid Port',
                  },
                }}></TcTextField>
            </Grid>)}
            { isStringInArray(watchedAccessMethodsValues, DeviceAccessMethod.RDP) && ( <Grid item xs={6}>
            <TcTextField
                name="rdpPort"
                label="RDP Port"
                defaultValue={DevicePort.RDP}
                rules={{
                  pattern: {
                    value: constants.DEVICE_PORT_PATTERN,
                    message: 'Invalid Port',
                  },
                }}></TcTextField>
            </Grid>)} */}
          </Grid>
          <Grid container spacing={2} className={classes.bottomDivider}>
            <Grid item xs={2}>
              <Button
                size="small"
                type="submit"
                disabled={!isValid}
                onClick={onSaveDevice}
                fullWidth
                variant="contained"
                color="info">
                {'Save'}
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                size="small"
                fullWidth
                variant="outlined"
                color="info"
                onClick={onClose}>
                {'Cancel'}
              </Button>
            </Grid>
            <Grid item xs={3}></Grid>
          </Grid>
        </form>
      </FormProvider>
      {/* {openNoGroupDialog && (
        <NoGroupDialog
          onCancel={() => setOpenNoGroupDialog(false)}
          onConfirmSave={() => submitDevice()}
          open={openNoGroupDialog}
          dialogTitle="No group applied to this device"
          dialogContent="This device will not be accessible until it is added to a group and trust rules apply to it"></NoGroupDialog>
      )} */}
      {openSelectGroupDialog && (
        <SelectGroupDialog
          groups={affectedDynamicGroups}
          onCancel={() => setOpenSelectGroupDialog(false)}
          onSaveGroup={(selectedGroups) => submitDevice(selectedGroups)}
          open={openSelectGroupDialog}></SelectGroupDialog>
      )}
    </React.Fragment>
  );
};

export default AddDevice;
