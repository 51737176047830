import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';

import { ICustomThemeOptions } from './CustomTheme';
import apiClient from 'common/apiClientAxios';
import axios from 'axios';

interface IThemeState {
  themeData: ICustomThemeOptions;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const customThemeOption: ICustomThemeOptions = {
  primaryColor: '#32353a',
  secondaryColor: '#fab117',
  font: 'Arial',
  successColor: '#2e7d32',
  infoColor: '#0092b7',
  warningColor: '#ed6c02',
  errorColor: '#d32f2f',
};

const initialState: IThemeState = {
  themeData: customThemeOption,
  status: 'idle',
  error: null,
};

export const saveThemeSettings = createAsyncThunk(
  'theme/saveSettings',
  async (theme: ICustomThemeOptions, { rejectWithValue }) => {
    try {
      const response = await apiClient.post('/settings/theme', theme);
      return response.data.data;
    } catch (error: any) {
      const errorData =
        axios.isAxiosError(error) && error.response?.data
          ? error.response.data.meta.message
          : String(error.message);
      return rejectWithValue(errorData);
    }
  },
);

export const fetchThemeSettings = createAsyncThunk(
  'theme/fetchSettings',
  async () => {
    try {
      const response = await apiClient.get('/settings/theme');
      return response.data.data;
    } catch (error: any) {
      const errorData =
        axios.isAxiosError(error) && error.response?.data
          ? error.response.data.meta.message
          : String(error.message);
      throw new Error(errorData ?? 'Failed to fetch theme settings');
    }
  },
);

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setThemeData(state, action: PayloadAction<ICustomThemeOptions>) {
      state.themeData.primaryColor = action.payload.primaryColor;
      state.themeData.secondaryColor = action.payload.secondaryColor;
      state.themeData.font = action.payload.font;
      state.themeData.infoColor = action.payload.infoColor;
      state.themeData.errorColor = action.payload.errorColor;
      state.themeData.successColor = action.payload.successColor;
      state.themeData.warningColor = action.payload.warningColor;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveThemeSettings.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(saveThemeSettings.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.themeData.primaryColor =
          action.payload.primaryColor ?? customThemeOption.primaryColor;
        state.themeData.secondaryColor =
          action.payload.secondaryColor ?? customThemeOption.secondaryColor;
        state.themeData.font = action.payload.font ?? customThemeOption.font;
        state.themeData.infoColor =
          action.payload.infoColor ?? customThemeOption.infoColor;
        state.themeData.errorColor =
          action.payload.errorColor ?? customThemeOption.errorColor;
        state.themeData.successColor =
          action.payload.successColor ?? customThemeOption.successColor;
        state.themeData.warningColor =
          action.payload.warningColor ?? customThemeOption.warningColor;
      })
      .addCase(saveThemeSettings.rejected, (state, action) => {
        state.status = 'failed';
        state.error =
          (action.payload as string) ?? 'Failed to save theme settings';
      })
      .addCase(fetchThemeSettings.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchThemeSettings.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.themeData.primaryColor =
          action.payload.primaryColor ?? customThemeOption.primaryColor;
        state.themeData.secondaryColor =
          action.payload.secondaryColor ?? customThemeOption.secondaryColor;
        state.themeData.font = action.payload.font ?? customThemeOption.font;
        state.themeData.infoColor =
          action.payload.infoColor ?? customThemeOption.infoColor;
        state.themeData.errorColor =
          action.payload.errorColor ?? customThemeOption.errorColor;
        state.themeData.successColor =
          action.payload.successColor ?? customThemeOption.successColor;
        state.themeData.warningColor =
          action.payload.warningColor ?? customThemeOption.warningColor;
      })
      .addCase(fetchThemeSettings.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message ?? 'Failed to fetch theme settings';
      });
  },
});

export const { setThemeData } = themeSlice.actions;

export default themeSlice.reducer;
