import * as React from 'react';
import { useEffect } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { DataGrid, GridActionsCellItem, GridColDef } from '@mui/x-data-grid';
import { constants } from 'common/constants';
import { Manufacturer } from 'pages/settings/types';
import { hasPermission } from 'common/helpers/utils';
import NoRowsOverlay from 'common/components/NoRowsOverlay';
import { CustomToolbarWithOnlySearch } from 'common/components/CustomToolbarWithOnlySearch';

type ManufacturersTableProps = {
  data: Manufacturer[];
  handleEditManufacturer: (manufacturer: Manufacturer) => void;
  handleDeleteManufacturer: (manufacturer: Manufacturer) => void;
};

const ManufacturersTable: React.FC<ManufacturersTableProps> = (props) => {
  const [rows, setRows] = React.useState<Manufacturer[]>(props.data);

  useEffect(() => {
    setRows(props.data);
  }, [props.data]);

  const editManufacturer = React.useCallback(
    (gridRow: any) => () => {
      props.handleEditManufacturer(gridRow.row);
    },
    [props],
  );

  const deleteManufacturer = React.useCallback(
    (gridRow: any) => () => {
      props.handleDeleteManufacturer(gridRow.row);
    },
    [props],
  );

  const columns = React.useMemo<GridColDef[]>(
    () => [
      {
        field: 'name',
        type: 'string',
        headerName: 'Manufacturer Name',
        flex: 1,
      },
      {
        field: 'description',
        type: 'string',
        headerName: 'Description',
        flex: 1,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        type: 'actions',
        flex: 1,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<EditIcon color="info" />}
            label="Edit"
            disabled={!hasPermission('manufacturers', 'write')}
            onClick={editManufacturer(params)}
            showInMenu
            key="editManufacturer"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon color="info" />}
            disabled={!hasPermission('manufacturers', 'delete')}
            label="Delete"
            onClick={deleteManufacturer(params)}
            showInMenu
            key="deleteManufacturer"
          />,
        ],
      },
    ],
    [deleteManufacturer, editManufacturer],
  );

  return (
    <DataGrid
      columns={columns}
      rows={rows}
      getRowId={(row) => row.manufacturerId}
      pageSizeOptions={constants.PAGE_SIZE_OPTIONS}
      initialState={{
        pagination: { paginationModel: constants.PAGINATION_MODEL },
      }}
      autoHeight
      slots={{
        toolbar: CustomToolbarWithOnlySearch,
        noRowsOverlay: () => (
          <NoRowsOverlay
            hasAccess={hasPermission('manufacturers', 'read')}
            name="Manufacturers"
          />
        ),
      }}
      slotProps={{
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 500 },
        },
      }}
      sx={{
        '& .MuiDataGrid-columnHeaderTitle': {
          fontWeight: '600',
        },
      }}
    />
  );
};

export default ManufacturersTable;
