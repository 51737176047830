import * as React from 'react';
import { useEffect } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { DataGrid, GridActionsCellItem, GridColDef } from '@mui/x-data-grid';
import { Card, IconButton, Link } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { useNavigate } from 'react-router-dom';

import SitesFilter from './SitesFilter';
import { Site, SiteFilterData, SiteFormMasterData } from '../types';
import { useContentStyles } from 'common/styles/useContentStyles';
import { constants } from 'common/constants';
import {
  hasPermission,
  isEndUser,
  isStringInArray,
  sortRows,
} from 'common/helpers/utils';
import CustomToolbar from 'common/components/CustomToolbar';
import NoRowsOverlay from 'common/components/NoRowsOverlay';
import { FilterDisplayOption } from 'common/enums';

export const powerProductionDefaultValues = [500, 300000];

type SitesTableProps = {
  data: Site[];
  handleEditSite: (site: Site) => void;
  handleDeleteSite: (site: Site) => void;
  handleFavoriteSite: (site: Site) => void;
  siteFormMasterData: SiteFormMasterData;
};

const SitesTable: React.FC<SitesTableProps> = (props) => {
  const classes = useContentStyles();
  const [rows, setRows] = React.useState<Site[]>(props.data);
  const [filteredRows, setFilteredRows] = React.useState<Site[]>(props.data);
  const navigate = useNavigate();
  const [selectedSortOption, setSelectedSortOption] = React.useState(
    FilterDisplayOption.ALL,
  );

  const getSortedRows = React.useCallback(
    (inputRows: Site[], selectedValue?: FilterDisplayOption) => {
      const records = sortRows([...inputRows], selectedValue);
      setRows(records as Site[]);
    },
    [],
  );

  useEffect(() => {
    setFilteredRows(props.data);
    getSortedRows(props.data);
  }, [getSortedRows, props.data]);

  const editSite = React.useCallback(
    (gridRow: any) => () => {
      props.handleEditSite(gridRow.row);
    },
    [props],
  );

  const deleteSite = React.useCallback(
    (gridRow: any) => () => {
      props.handleDeleteSite(gridRow.row);
    },
    [props],
  );

  const handleNameClick = React.useCallback(
    (site: Site) => {
      navigate(`/portal/sites/summary/${site.siteId}`, {
        state: { site: site },
      });
    },
    [navigate],
  );

  const handleFavoriteClick = React.useCallback(
    (site: Site) => {
      props.handleFavoriteSite(site);
    },
    [props],
  );

  const columns = React.useMemo<GridColDef[]>(
    () => [
      // {
      //   field: 'avatar',
      //   headerName: '',
      //   width: 10,
      //   renderCell: (params) => (
      //     params.row.isStarred ? (
      //       <StarIcon style={{ color: '#FFD700', marginRight: 4 }} />
      //     ) : (
      //       <StarOutlineIcon style={{ marginRight: 4 }} />
      //     )
      //   ),
      // },
      {
        field: 'name',
        type: 'string',
        headerName: 'Site Name',
        flex: 1,
        renderCell: (params) => (
          <div>
            <IconButton
              onClick={() => handleFavoriteClick(params.row)}
              sx={{ paddingLeft: 0 }}>
              {params.row.isFavorite ? (
                <StarIcon color="secondary" />
              ) : (
                <StarOutlineIcon />
              )}
            </IconButton>
            <Link
              component="button"
              sx={{
                color: (theme) => theme.palette.info.main,
                textDecorationColor: (theme) => theme.palette.info.main,
              }}
              onClick={() => handleNameClick(params.row)}>
              {params.row.name}
            </Link>
          </div>
        ),
      },
      {
        field: 'alias',
        type: 'string',
        headerName: 'Alias(es)',
        flex: 1,
      },
      // { field: 'tags', type: 'string', headerName: 'Tag(s)', flex: 1 },
      {
        field: 'ownerName',
        type: 'string',
        headerName: 'Owner',
        flex: 1,
      },
      {
        field: 'operatorName',
        type: 'string',
        headerName: 'Operator',
        flex: 1,
      },
      { field: 'city', type: 'string', headerName: 'City', flex: 1 },
      { field: 'state', type: 'string', headerName: 'State', flex: 1 },
      { field: 'type', type: 'string', headerName: 'Type', flex: 1 },
      {
        field: 'powerProduction',
        type: 'string',
        headerName: 'Production (kW)',
        flex: 1,
      },
      {
        field: 'complianceLevel',
        type: 'string',
        headerName: 'Compliance Level',
        flex: 1,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        type: 'actions',
        flex: 1,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<EditIcon color="info" />}
            label="Edit"
            disabled={!hasPermission('sites.summary', 'write')}
            onClick={editSite(params)}
            showInMenu
            key="editSite"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon color="info" />}
            label="Delete"
            disabled={!hasPermission('sites.summary', 'delete')}
            onClick={deleteSite(params)}
            showInMenu
            key="deleteSite"
          />,
        ],
      },
    ],
    [deleteSite, editSite, handleNameClick, handleFavoriteClick],
  );
  // Filter columns based on user role
  const filteredColumns = isEndUser()
    ? columns.filter((column) => {
        return (
          column.field !== 'actions' &&
          column.field !== 'type' &&
          column.field !== 'complianceLevel' &&
          column.field !== 'powerProduction'
        );
      })
    : columns;

  const [openFilter, setOpenFilter] = React.useState(false);
  const [filters, setFilters] = React.useState<SiteFilterData>({
    selectedCountries: [],
    selectedStates: [],
    city: '',
    powerProduction: powerProductionDefaultValues,
    selectedGroups: [],
    selectedOwners: [],
    selectedOperators: [],
    selectedCompliances: [],
    selectedSiteTypes: [],
  });

  const handleFilterClick = () => {
    setOpenFilter(true);
  };

  const handleApplyFilter = (filterValues: SiteFilterData) => {
    const statesIds = filterValues.selectedStates.map((val) => val.stateId);
    const countriesIds = filterValues.selectedCountries.map(
      (val) => val.countryId,
    );
    setFilters(filterValues);
    const filteredSites = props.data.filter((site) => {
      const isStateValid =
        statesIds.length > 0 ? statesIds.includes(site.stateId) : true;
      const isGroupValid =
        filterValues.selectedGroups.length > 0
          ? filterValues.selectedGroups.includes(site.groupId)
          : true;
      const isCountryValid =
        countriesIds.length > 0 ? countriesIds.includes(site.countryId) : true;
      const isPowerProductionValid = filterValues.powerProduction
        ? filterValues.powerProduction[0] <= parseFloat(site.powerProduction) &&
          parseFloat(site.powerProduction) <= filterValues.powerProduction[1]
        : true;
      const isSiteTypeValid =
        filterValues.selectedSiteTypes.length > 0
          ? filterValues.selectedSiteTypes.includes(site.typeId)
          : true;
      const isSiteOwnerValid =
        filterValues.selectedOwners.length > 0
          ? filterValues.selectedOwners.includes(site.ownerId)
          : true;
      const isOperatorValid =
        filterValues.selectedOperators.length > 0
          ? filterValues.selectedOperators.includes(site.operatorId)
          : true;
      const isComplianceValid =
        filterValues.selectedCompliances.length > 0
          ? isStringInArray(
              filterValues.selectedCompliances,
              site.complianceLevel,
            )
          : true;
      const isCityValid =
        filterValues.city && site.city
          ? site.city
              .trim()
              .toLocaleLowerCase()
              .includes(filterValues.city?.trim().toLocaleLowerCase())
          : true;
      return (
        isStateValid &&
        isGroupValid &&
        isCountryValid &&
        isPowerProductionValid &&
        isSiteTypeValid &&
        isSiteOwnerValid &&
        isOperatorValid &&
        isComplianceValid &&
        isCityValid
      );
    });
    setFilteredRows(filteredSites);
    getSortedRows(filteredSites, selectedSortOption);
  };

  const handleClearFilter = () => {
    setFilteredRows(props.data);
    getSortedRows(props.data, selectedSortOption);
  };

  const handleCancelFilter = () => {
    setFilteredRows(props.data);
    getSortedRows(props.data, selectedSortOption);
    setOpenFilter(false);
  };

  const handleSortOptionChange = (selectedValue: FilterDisplayOption) => {
    setSelectedSortOption(selectedValue);
    getSortedRows([...filteredRows], selectedValue);
  };

  return (
    <>
      {openFilter && (
        <Card elevation={0} className={classes.contentSection}>
          <SitesFilter
            onApplyFilter={handleApplyFilter}
            handleClearFilter={handleClearFilter}
            handleCancelFilter={handleCancelFilter}
            filters={filters}
            siteFormMasterData={props.siteFormMasterData}
          />
        </Card>
      )}
      <DataGrid
        columns={filteredColumns}
        rows={rows}
        getRowId={(row) => row.siteId}
        pageSizeOptions={constants.PAGE_SIZE_OPTIONS}
        initialState={{
          pagination: { paginationModel: constants.PAGINATION_MODEL },
        }}
        slots={{
          toolbar: () => (
            <CustomToolbar
              handleFilterClick={handleFilterClick}
              isDisplaySortOptions
              handleSortOptionChange={(selectedOption) =>
                handleSortOptionChange(selectedOption)
              }
              sortOptionValue={selectedSortOption}
            />
          ),
          noRowsOverlay: () => (
            <NoRowsOverlay
              hasAccess={hasPermission('sites.summary', 'read')}
              name="Sites"
            />
          ),
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        autoHeight
        sx={{
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: '600',
          },
        }}
        disableRowSelectionOnClick
      />
    </>
  );
};

export default SitesTable;
