import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type LoginSettingsType = {
  availableLogins: string[];
  isInitializeSettings: boolean;
};
const initialState: LoginSettingsType = {
  availableLogins: [],
  isInitializeSettings: false,
};

const loginSettings = createSlice({
  name: 'loginSettings',
  initialState,
  reducers: {
    setLoginSettings: (state, action: PayloadAction<string[]>) => {
      state.availableLogins = action.payload;
      state.isInitializeSettings = true;
    },
  },
});

export const { setLoginSettings } = loginSettings.actions;

export default loginSettings.reducer;
