import React, { useCallback, useState, useEffect, useMemo } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowSelectionModel,
} from '@mui/x-data-grid';
import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  DialogTitle,
  Link,
  Grid,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';

import {
  CreateDeviceGroup,
  Device,
  DeviceFormMasterData,
  DeviceGroup,
  DeviceGroupNameForm,
  DevicesGroupFilterData,
} from '../types';
import DevicesFilter from './DevicesFilter';
import { constants } from 'common/constants';
import GatewayDeviceDetails from '../deviceDetails/GatewayDeviceDetails';
import { deviceDefaultValues } from './Devices';
import { useContentStyles } from 'common/styles/useContentStyles';
import CustomToolbar from 'common/components/CustomToolbar';
import AddDeviceGroup from '../groups/AddDeviceGroup';
import {
  DeviceAccessMethod,
  DeviceGroupType,
  FeatureStatus,
  FilterDisplayOption,
} from 'common/enums';
import DeviceTypeDialog from '../groups/DeviceTypeDialog';
import { setLoader, setSnackbarToast } from 'redux/UiStateSlice';
import apiClient from 'common/apiClientAxios';
import {
  getFilteredDeviceRows,
  hasPermission,
  sortRows,
} from 'common/helpers/utils';
import { FeatureSetting } from 'pages/settings/types';
import assets from '../../../assets';
import RemoteAccessSSH from '../remoteAccess/RemoteAccessSSH';
import RemoteAccessRDP from '../remoteAccess/RemoteAccessRDP';
import VideoModal from 'common/components/VideoModal';
import GroupType from './GroupType';
import { isEndUser } from '../../../common/helpers/utils';
import NoRowsOverlay from 'common/components/NoRowsOverlay';
import RemoteAccessVNC from '../remoteAccess/RemoteAccessVNC';

type DevicesTableProps = {
  data: Device[];
  handleEditDevice: (device: Device) => void;
  handleDeleteDevice: (device: Device) => void;
  deviceFormMasterData: DeviceFormMasterData;
  showAddGroupForm: boolean;
  hideGroupForm: () => void;
  navigateToGroups: () => void;
  handleFavoriteDevice: (device: Device) => void;
};

const DevicesTable: React.FC<DevicesTableProps> = (props) => {
  const classes = useContentStyles();
  const [rows, setRows] = useState<Device[]>(
    isEndUser()
      ? props.data.filter(
          (dev) => dev.type.toLowerCase() !== constants.DEVICE_TYPE_GATEWAY,
        )
      : props.data,
  );
  const [filteredRows, setFilteredRows] = useState<Device[]>(
    isEndUser()
      ? props.data.filter(
          (dev) => dev.type.toLowerCase() !== constants.DEVICE_TYPE_GATEWAY,
        )
      : props.data,
  );

  const navigate = useNavigate();
  const [deviceGroupName, setDeviceGroupName] = useState('');
  const dispatch = useDispatch();
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [selectedDevice, setSelectedDevice] =
    useState<Device>(deviceDefaultValues);
  // const [isRemoteAccess, setRemoteAccess] = useState(false);
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const [openSSHAccessModal, setOpenSSHAccessModal] = useState(false);
  const [openRDPAccessModal, setOpenRDPAccessModal] = useState(false);
  const [openVNCAccessModal, setOpenVNCAccessModal] = useState(false);

  const [openFilter, setOpenFilter] = useState(false);
  const defaultFilterValues = useMemo(
    () => ({
      accessMethods: [],
      manufacturers: [],
      types: [],
      statuses: [],
      sites: [],
      interfaces: [],
      groups: [],
    }),
    [],
  );
  const [filters, setFilters] =
    useState<DevicesGroupFilterData>(defaultFilterValues);
  const [selectedSortOption, setSelectedSortOption] = React.useState(
    FilterDisplayOption.ALL,
  );

  const getSortedRows = React.useCallback(
    (inputRows: Device[], selectedValue?: FilterDisplayOption) => {
      const records = sortRows([...inputRows], selectedValue);
      setRows(records as Device[]);
    },
    [],
  );

  const handleCloseVideoModal = () => {
    setOpenVideoModal(false);
  };
  const handleCloseSSHAccessModal = () => {
    setOpenSSHAccessModal(false);
  };
  const handleCloseRDPAccessModal = () => {
    setOpenRDPAccessModal(false);
  };
  const handleCloseVNCAccessModal = () => {
    setOpenVNCAccessModal(false);
  };
  const handleAccessMethodClick = useCallback(
    async (accessMethod: string, device: Device) => {
      //If feature is code_devices.remote_access, then show access modal else show video modal
      const featuresResponse = await apiClient.get('/settings/features');
      const featureList = featuresResponse.data.data as FeatureSetting[];
      const remoteAccessSettings = featureList.find(
        (feature) => feature.featureId === 'code_devices.remote_access',
      );
      if (remoteAccessSettings?.status === FeatureStatus.ENABLED) {
        setSelectedDevice(device);
        if (accessMethod.toLocaleLowerCase() === DeviceAccessMethod.SSH) {
          setOpenSSHAccessModal(true);
        } else if (
          accessMethod.toLocaleLowerCase() === DeviceAccessMethod.RDP
        ) {
          setOpenRDPAccessModal(true);
        } else if (
          accessMethod.toLocaleLowerCase() === DeviceAccessMethod.HTTP ||
          accessMethod.toLocaleLowerCase() === DeviceAccessMethod.HTTPS
        ) {
          setOpenVNCAccessModal(true);
        }
      } else {
        setVideoUrl(
          accessMethod === DeviceAccessMethod.SSH
            ? assets.videos.SSHVideo
            : assets.videos.RDPSCADA,
        );
        setOpenVideoModal(true);
      }
    },
    [],
  );

  useEffect(() => {
    setFilteredRows(props.data);
    getSortedRows(props.data);
  }, [getSortedRows, props.data]);

  const editDevice = useCallback(
    (gridRow: any) => () => {
      props.handleEditDevice(gridRow.row);
    },
    [props],
  );

  const deleteDevice = useCallback(
    (gridRow: any) => () => {
      props.handleDeleteDevice(gridRow.row);
    },
    [props],
  );
  const handleNameClick = useCallback(
    (device: Device) => {
      if (
        device.type &&
        device.type.toLowerCase() === constants.DEVICE_TYPE_GATEWAY
      ) {
        setSelectedDevice(device);
        setOpenDetailsModal(true);
      } else {
        navigate(`/portal/devices/summary/${device.deviceId}`, {
          state: {
            device: device,
            deviceFormMasterData: props.deviceFormMasterData,
          },
        });
      }
    },
    [navigate, props.deviceFormMasterData],
  );

  const handleFavoriteClick = React.useCallback(
    (device: Device) => {
      props.handleFavoriteDevice(device);
    },
    [props],
  );

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'name',
        type: 'string',
        headerName: 'Device Name',
        flex: 1,
        renderCell: (params) =>
          isEndUser() ? (
            <>
              <IconButton
                disabled={props.showAddGroupForm}
                onClick={() => handleFavoriteClick(params.row)}
                sx={{ paddingLeft: 0 }}>
                {params.row.isFavorite ? (
                  <StarIcon color="secondary" />
                ) : (
                  <StarOutlineIcon />
                )}
              </IconButton>
              {params.row.name}
            </>
          ) : (
            <>
              <IconButton
                disabled={props.showAddGroupForm}
                onClick={() => handleFavoriteClick(params.row)}
                sx={{ paddingLeft: 0 }}>
                {params.row.isFavorite ? (
                  <StarIcon color="secondary" />
                ) : (
                  <StarOutlineIcon />
                )}
              </IconButton>
              <Link
                component="button"
                sx={{
                  color: (theme) => theme.palette.info.main,
                  textDecorationColor: (theme) => theme.palette.info.main,
                }}
                onClick={() => handleNameClick(params.row)}>
                {params.row.name}
              </Link>
            </>
          ),
      },
      {
        field: 'alias',
        type: 'string',
        headerName: 'Alias(es)',
        flex: 1,
      },
      {
        field: 'groups',
        type: 'string',
        headerName: 'Group(s)',
        flex: 1,
        valueGetter: (params) => {
          const groups = (params.row.groups || []) as DeviceGroup[];
          return groups.map((obj) => obj.name).join(', ');
        },
      },
      {
        field: 'siteName',
        type: 'string',
        headerName: 'Site',
        flex: 1,
      },
      { field: 'type', type: 'string', headerName: 'Type', flex: 1 },
      {
        field: 'accessMethods',
        type: 'string',
        headerName: 'Access Methods',
        flex: 1,
        valueGetter: (params) => {
          const accessMethods = (params.row.accessMethods || []) as string[];
          return accessMethods.join(', ');
        },
        renderCell: (params) =>
          (params.row.accessMethods || []).map((method: string) => (
            <React.Fragment key={`${method}${params.row.deviceId}`}>
              <Link
                component="button"
                sx={{
                  color: (theme) => theme.palette.info.main,
                  textDecorationColor: (theme) => theme.palette.info.main,
                }}
                onClick={() => handleAccessMethodClick(method, params.row)}>
                {method}
              </Link>
              &nbsp;
            </React.Fragment>
          )),
      },
      {
        field: 'manufacturerName',
        type: 'string',
        headerName: 'Manufacturer',
        flex: 1,
      },
      {
        field: 'interfaces',
        type: 'string',
        headerName: 'Interface',
        flex: 1,
        valueGetter: (params) => {
          const interfaces = (params.row.interfaces || []) as string[];
          return interfaces.join(', ');
        },
      },
      {
        field: 'status',
        type: 'string',
        headerName: 'Status',
        flex: 1,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        type: 'actions',
        flex: 1,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<EditIcon color="info" />}
            label="Edit"
            disabled={!hasPermission('devices.summary', 'write')}
            onClick={editDevice(params)}
            showInMenu
            key="editDevice"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon color="info" />}
            label="Delete"
            disabled={!hasPermission('devices.summary', 'delete')}
            onClick={deleteDevice(params)}
            showInMenu
            key="deleteDevice"
          />,
        ],
      },
    ],
    [
      deleteDevice,
      editDevice,
      handleAccessMethodClick,
      handleFavoriteClick,
      handleNameClick,
      props.showAddGroupForm,
    ],
  );
  // Filter columns based on user role
  const filteredColumns = isEndUser()
    ? columns.filter(
        (column) =>
          column.field !== 'actions' &&
          column.field !== 'groups' &&
          column.field !== 'interfaces',
      )
    : columns;

  const handleFilterClick = () => {
    setOpenFilter(true);
  };
  const handleApplyFilter = (filterValues: DevicesGroupFilterData) => {
    setFilters(filterValues);
    const filteredDevices = getFilteredDeviceRows(props.data, filterValues);
    setFilteredRows(filteredDevices);
    getSortedRows(filteredDevices, selectedSortOption);
  };

  const handleClearFilter = () => {
    setFilteredRows(props.data);
    getSortedRows(props.data, selectedSortOption);
  };

  const handleCancelFilter = () => {
    setFilteredRows(props.data);
    getSortedRows(props.data, selectedSortOption);
    setOpenFilter(false);
    setFilters(defaultFilterValues);
  };

  const handleCloseModal = () => {
    setOpenDetailsModal(false);
  };
  handleFilterClick;

  const onCancelGroup = () => {
    getSortedRows(props.data, selectedSortOption);
    setFilteredRows(props.data);
    setFilters(defaultFilterValues);
    setOpenFilter(false);
    props.hideGroupForm();
  };

  const onSaveGroup = (data: DeviceGroupNameForm) => {
    if (rowSelectionModel.length > 0) {
      setDeviceGroupName(data.name);
      //get type confirmation
      setOpenDeviceTypeDialogue(true);
    } else {
      dispatch(
        setSnackbarToast({
          message: 'Please select the devices',
          open: true,
          severity: 'error',
        }),
      );
    }
  };

  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);

  const [openDeviceTypeDialogue, setOpenDeviceTypeDialogue] = useState(false);

  const onConfirmType = async () => {
    const deviceGroup: CreateDeviceGroup = {
      name: deviceGroupName,
      type: groupTypeSwitchValue,
      devicesCount: rowSelectionModel.length,
      accessMethods: filters.accessMethods,
      devices: rowSelectionModel as string[],
      deviceStatuses: filters.statuses,
      deviceTypes: filters.types,
      interfaces: filters.interfaces,
      manufacturers: filters.manufacturers,
      sites: filters.sites,
    };
    //save group
    try {
      dispatch(setLoader({ loaderMessage: 'Please wait', openLoader: true }));
      const groupResponse = await apiClient.post(
        `/devices-groups`,
        deviceGroup,
      );
      dispatch(setLoader({ loaderMessage: 'Please wait', openLoader: false }));
      dispatch(
        setSnackbarToast({
          message: groupResponse.data.meta.message,
          open: true,
          severity: 'success',
        }),
      );
    } catch (error: any) {
      dispatch(setLoader({ loaderMessage: 'Please wait', openLoader: false }));
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: 'error',
        }),
      );
    }
    setOpenDeviceTypeDialogue(false);
    props.navigateToGroups();
  };

  useEffect(() => {
    if (props.showAddGroupForm) {
      setRows(props.data);
      setFilters(defaultFilterValues);
      setOpenFilter(true);
    }
  }, [defaultFilterValues, getSortedRows, props.data, props.showAddGroupForm]);

  const [groupTypeSwitchValue, setGroupTypeSwitchValue] = useState(
    DeviceGroupType.STATIC,
  );
  const onCancelGroupType = (type: DeviceGroupType) => {
    setGroupTypeSwitchValue(type);
  };

  const onConfirmSaveGroupType = (type: DeviceGroupType) => {
    if (type === DeviceGroupType.DYNAMIC) {
      const updatedRowsSelection = rows.map((row) => row.deviceId);
      setRowSelectionModel(updatedRowsSelection);
    }
    setGroupTypeSwitchValue(type);
  };

  const handleSortOptionChange = (selectedValue: FilterDisplayOption) => {
    setSelectedSortOption(selectedValue);
    getSortedRows([...filteredRows], selectedValue);
  };

  return (
    <>
      {props.showAddGroupForm && (
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <AddDeviceGroup
              onCancelGroup={onCancelGroup}
              onSaveGroup={onSaveGroup}></AddDeviceGroup>
          </Grid>
          <Grid item xs={3} alignContent={'center'}>
            <GroupType
              rows={rows}
              rowSelectionModel={rowSelectionModel as string[]}
              onCancel={onCancelGroupType}
              defaultGroupType={groupTypeSwitchValue as DeviceGroupType}
              onConfirmSave={onConfirmSaveGroupType}></GroupType>
          </Grid>
        </Grid>
      )}

      {openFilter && (
        <Card elevation={0} className={classes.contentSection}>
          <DevicesFilter
            onApplyFilter={handleApplyFilter}
            handleCancelFilter={handleCancelFilter}
            handleClearFilter={handleClearFilter}
            filters={filters}
            isResetForm={props.showAddGroupForm}
            isShowCancel={!props.showAddGroupForm}
            deviceFormMasterData={props.deviceFormMasterData}
          />
        </Card>
      )}
      <DataGrid
        disableVirtualization
        columns={filteredColumns}
        rows={rows}
        getRowId={(row) => row.deviceId}
        pageSizeOptions={constants.PAGE_SIZE_OPTIONS}
        initialState={{
          pagination: { paginationModel: constants.PAGINATION_MODEL },
        }}
        slots={{
          toolbar: () => (
            <CustomToolbar
              handleFilterClick={handleFilterClick}
              isDisplaySortOptions
              handleSortOptionChange={(selectedOption) =>
                handleSortOptionChange(selectedOption)
              }
              sortOptionValue={selectedSortOption}
            />
          ),
          noRowsOverlay: () => (
            <NoRowsOverlay
              hasAccess={hasPermission('devices.summary', 'read')}
              name="Devices"
            />
          ),
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        autoHeight
        sx={{
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: '600',
          },
        }}
        getRowClassName={(params) =>
          params.row.type.toLowerCase() === constants.DEVICE_TYPE_GATEWAY
            ? classes.rowColor
            : ''
        }
        isRowSelectable={() => groupTypeSwitchValue === DeviceGroupType.STATIC}
        checkboxSelection={props.showAddGroupForm}
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
        rowSelectionModel={rowSelectionModel}
        disableRowSelectionOnClick
      />

      <Dialog
        open={openDetailsModal}
        onClose={handleCloseModal}
        fullWidth
        maxWidth="xl">
        <DialogTitle sx={{ m: 0, p: 2 }}></DialogTitle>
        <IconButton
          onClick={handleCloseModal}
          sx={{
            position: 'absolute',
            right: 10,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
        <DialogContent className={classes.dialogModal}>
          <GatewayDeviceDetails device={selectedDevice}></GatewayDeviceDetails>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseModal}
            variant="outlined"
            color="info"
            sx={{
              right: 20,
            }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {openDeviceTypeDialogue && (
        <DeviceTypeDialog
          dialogContent={
            groupTypeSwitchValue === DeviceGroupType.STATIC
              ? constants.STATIC_GROUP_CONFIRM_MESSAGE
              : constants.DYNAMIC_GROUP_CONFIRM_MESSAGE
          }
          onConfirmType={onConfirmType}
          open={openDeviceTypeDialogue}
          onCancel={() => {
            setOpenDeviceTypeDialogue(false);
          }}></DeviceTypeDialog>
      )}
      {openSSHAccessModal && (
        <RemoteAccessSSH
          open={openSSHAccessModal}
          onClose={handleCloseSSHAccessModal}
          device={selectedDevice}
        />
      )}
      {openRDPAccessModal && (
        <RemoteAccessRDP
          open={openRDPAccessModal}
          onClose={handleCloseRDPAccessModal}
          device={selectedDevice}
        />
      )}
      {openVNCAccessModal && (
        <RemoteAccessVNC
          open={openVNCAccessModal}
          onClose={handleCloseVNCAccessModal}
          device={selectedDevice}
        />
      )}
      <VideoModal
        open={openVideoModal}
        onClose={handleCloseVideoModal}
        videoUrl={videoUrl}
      />
    </>
  );
};
export default DevicesTable;
