import * as React from 'react';
import { useEffect } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Avatar, Card, Grid, IconButton } from '@mui/material';
import BlockIcon from '@mui/icons-material/Block';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import green from '@mui/material/colors/green';
import grey from '@mui/material/colors/grey';
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowSelectionModel,
} from '@mui/x-data-grid';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { useDispatch } from 'react-redux';

import {
  CreateUserGroup,
  User,
  UserFormMasterData,
  UserGroup,
  UserGroupNameForm,
  UsersFilterData,
} from './types';
import UsersFilter from './UsersFilter';
import {
  getFilteredUserRows,
  hasPermission,
  sortRows,
  stringAvatar,
} from 'common/helpers/utils';
import { FilterDisplayOption, UserGroupType, UserStatus } from 'common/enums';
import { useContentStyles } from 'common/styles/useContentStyles';
import { constants } from 'common/constants';
import CustomToolbar from 'common/components/CustomToolbar';
import { setLoader, setSnackbarToast } from 'redux/UiStateSlice';
import apiClient from 'common/apiClientAxios';
import UserGroupTypeDialog from './groups/UserGroupTypeDialog';
import AddUserGroup from './groups/AddUserGroup';
import GroupType from './GroupType';
import NoRowsOverlay from 'common/components/NoRowsOverlay';

type UsersTableProps = {
  data: User[];
  handleEditUser: (user: User) => void;
  handleDeleteUser: (user: User) => void;
  userFormMasterData: UserFormMasterData;
  showAddGroupForm: boolean;
  hideGroupForm: () => void;
  navigateToGroups: () => void;
  handleFavoriteUser: (user: User) => void;
};

const UsersTable: React.FC<UsersTableProps> = (props) => {
  const classes = useContentStyles();
  const [rows, setRows] = React.useState<User[]>(props.data);
  const [filteredRows, setFilteredRows] = React.useState<User[]>(props.data);
  const [selectedSortOption, setSelectedSortOption] = React.useState(
    FilterDisplayOption.ALL,
  );

  const getSortedRows = React.useCallback(
    (inputRows: User[], selectedValue?: FilterDisplayOption) => {
      const records = sortRows([...inputRows], selectedValue);
      setRows(records as User[]);
    },
    [],
  );

  useEffect(() => {
    setFilteredRows(props.data);
    getSortedRows(props.data);
  }, [getSortedRows, props.data]);

  const editUser = React.useCallback(
    (gridRow: any) => () => {
      props.handleEditUser(gridRow.row);
    },
    [props],
  );

  const deleteUser = React.useCallback(
    (gridRow: any) => () => {
      props.handleDeleteUser(gridRow.row);
    },
    [props],
  );

  const handleFavoriteClick = React.useCallback(
    (user: User) => {
      props.handleFavoriteUser(user);
    },
    [props],
  );

  const columns = React.useMemo<GridColDef[]>(
    () => [
      {
        field: 'avatar',
        headerName: '',
        width: 100,
        renderCell: (params) => (
          <>
            <IconButton
              disabled={props.showAddGroupForm}
              onClick={() => handleFavoriteClick(params.row)}
              sx={{ paddingLeft: 0 }}>
              {params.row.isFavorite ? (
                <StarIcon color="secondary" />
              ) : (
                <StarOutlineIcon />
              )}
            </IconButton>
            <Avatar
              {...stringAvatar(
                `${params.row.firstName ?? ''} ${params.row.lastName ?? ''}`,
              )}
            />
          </>
        ),
      },
      { field: 'firstName', type: 'string', headerName: 'First Name', flex: 1 },
      { field: 'lastName', type: 'string', headerName: 'Last Name', flex: 1 },
      { field: 'email', type: 'string', headerName: 'Email', flex: 1 },
      { field: 'roleName', type: 'string', headerName: 'Role', flex: 1 },
      {
        field: 'groups',
        type: 'string',
        headerName: 'Group(s)',
        flex: 1,
        valueGetter: (params) => {
          const groups = (params.row.groups || []) as UserGroup[];
          return groups.map((obj) => obj.name).join(', ');
        },
      },
      { field: 'country', type: 'string', headerName: 'Country', flex: 1 },
      // { field: 'state', type: 'string', headerName: 'State', flex: 1 },
      // { field: 'city', type: 'string', headerName: 'City', flex: 1 },
      {
        field: 'organization',
        type: 'string',
        headerName: 'Organization',
        flex: 1,
        valueGetter: (params) => {
          return params.row.organization?.name ?? '';
        },
      },
      // { field: 'ndnIdentity', type: 'string', headerName: 'NDN Network',flex: 1 },
      // {
      //   field: 'fromTime',
      //   type: 'string',
      //   headerName: 'Access Time',
      //   flex: 1,
      //   renderCell: (params) => { return getFromTimeToTime(params.row.fromTime, params.row.toTime) },
      // },
      // {
      //   field: 'recurrence',
      //   type: 'string',
      //   flex: 1,
      //   headerName: 'Recurrence',
      // },
      // {
      //   field: 'accessMethods',
      //   type: 'string',
      //   headerName: 'Access Methods',
      //   flex: 1,
      //   valueGetter: (params) => {
      //     const interfaces = (params.row.accessMethods || []) as string[];
      //     return interfaces.join(', ');
      //   },
      // },
      {
        field: 'status',
        type: 'string',
        headerName: 'Status',
        width: 60,
        renderCell: (params) => {
          const status = params.row.status || '';
          if (status === UserStatus.ACTIVE) {
            return <CheckCircleOutlineIcon sx={{ color: green[500] }} />;
          } else if (status === UserStatus.INACTIVE) {
            return <BlockIcon sx={{ color: grey[500] }} />;
          } else {
            return status;
          }
        },
      },
      {
        field: 'actions',
        // headerName: 'Actions',
        type: 'actions',
        width: 40,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<EditIcon color="info" />}
            label="Edit"
            disabled={!hasPermission('users.summary', 'write')}
            onClick={editUser(params)}
            showInMenu
            key="editUser"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon color="info" />}
            label="Delete"
            disabled={!hasPermission('users.summary', 'delete')}
            onClick={deleteUser(params)}
            showInMenu
            key="deleteUser"
          />,
        ],
      },
    ],
    [deleteUser, editUser, handleFavoriteClick, props.showAddGroupForm],
  );

  const defaultFilterValues = React.useMemo(
    () => ({
      statuses: [],
      selectedCountries: [],
      city: '',
      organizations: [],
      roles: [],
      selectedStates: [],
      zipcode: '',
      // recurrences: [],
      groups: [],
    }),
    [],
  );
  const [openFilter, setOpenFilter] = React.useState(false);
  const [filters, setFilters] =
    React.useState<UsersFilterData>(defaultFilterValues);

  const handleFilterClick = () => {
    setOpenFilter(true);
  };
  const handleApplyFilter = (filterValues: UsersFilterData) => {
    setFilters(filterValues);
    const filteredUsers = getFilteredUserRows(props.data, filterValues);
    getSortedRows(filteredUsers, selectedSortOption);
    setFilteredRows(filteredUsers);
  };

  const handleClearFilter = () => {
    getSortedRows(props.data, selectedSortOption);
    setFilteredRows(props.data);
  };

  const handleCancelFilter = () => {
    getSortedRows(props.data, selectedSortOption);
    setFilteredRows(props.data);
    setOpenFilter(false);
    setFilters(defaultFilterValues);
  };

  const onCancelGroup = () => {
    getSortedRows(props.data, selectedSortOption);
    setFilteredRows(props.data);
    setFilters(defaultFilterValues);
    setOpenFilter(false);
    props.hideGroupForm();
  };
  const [userGroupName, setUserGroupName] = React.useState('');
  const dispatch = useDispatch();

  const onSaveGroup = (data: UserGroupNameForm) => {
    if (rowSelectionModel.length > 0) {
      setUserGroupName(data.name);
      //get type confirmation
      setOpenGroupTypeDialogue(true);
    } else {
      dispatch(
        setSnackbarToast({
          message: 'Please select the users',
          open: true,
          severity: 'error',
        }),
      );
    }
  };

  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridRowSelectionModel>([]);

  const [openGroupTypeDialogue, setOpenGroupTypeDialogue] =
    React.useState(false);

  const onConfirmType = async () => {
    const type = groupTypeSwitchValue;
    const userGroup: CreateUserGroup = {
      name: userGroupName,
      type,
      users: rowSelectionModel as string[],
      statuses: filters.statuses,
      roles: filters.roles,
      city: filters.city,
      countries: filters.selectedCountries,
      states: filters.selectedStates,
      organizations: filters.organizations,
      zipcode: filters.zipcode,
      // recurrences: filters.recurrences,
    };
    //save group
    try {
      dispatch(setLoader({ loaderMessage: 'Please wait', openLoader: true }));
      const groupResponse = await apiClient.post(`/users-groups`, userGroup);
      dispatch(setLoader({ loaderMessage: 'Please wait', openLoader: false }));
      dispatch(
        setSnackbarToast({
          message: groupResponse.data.meta.message,
          open: true,
          severity: 'success',
        }),
      );
    } catch (error: any) {
      dispatch(setLoader({ loaderMessage: 'Please wait', openLoader: false }));
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: 'error',
        }),
      );
    }
    setOpenGroupTypeDialogue(false);
    //handleCancelFilter();
    props.navigateToGroups();
  };

  useEffect(() => {
    if (props.showAddGroupForm) {
      // getSortedRows(props.data, selectedSortOption);
      setRows(props.data);
      setFilters(defaultFilterValues);
      setOpenFilter(true);
    }
  }, [defaultFilterValues, getSortedRows, props.data, props.showAddGroupForm]);

  const [groupTypeSwitchValue, setGroupTypeSwitchValue] = React.useState(
    UserGroupType.STATIC,
  );
  const onCancelGroupType = (type: UserGroupType) => {
    setGroupTypeSwitchValue(type);
  };

  const onConfirmSaveGroupType = (type: UserGroupType) => {
    if (type === UserGroupType.DYNAMIC) {
      const updatedRowsSelection = rows.map((row) => row.userId);
      setRowSelectionModel(updatedRowsSelection);
    }
    setGroupTypeSwitchValue(type);
  };

  const handleSortOptionChange = (selectedValue: FilterDisplayOption) => {
    setSelectedSortOption(selectedValue);
    getSortedRows([...filteredRows], selectedValue);
  };

  return (
    <>
      {props.showAddGroupForm && (
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <AddUserGroup
              onCancelGroup={onCancelGroup}
              onSaveGroup={onSaveGroup}></AddUserGroup>
          </Grid>
          <Grid item xs={3} alignContent={'center'}>
            <GroupType
              rows={rows}
              rowSelectionModel={rowSelectionModel as string[]}
              onCancel={onCancelGroupType}
              defaultGroupType={groupTypeSwitchValue as UserGroupType}
              onConfirmSave={onConfirmSaveGroupType}></GroupType>
          </Grid>
        </Grid>
      )}
      {(openFilter || props.showAddGroupForm) && (
        <Card elevation={0} className={classes.contentSection}>
          <UsersFilter
            onApplyFilter={handleApplyFilter}
            handleCancelFilter={handleCancelFilter}
            handleClearFilter={handleClearFilter}
            filters={filters}
            userFormMasterData={props.userFormMasterData}
            isShowCancel={!props.showAddGroupForm}
            isResetForm={props.showAddGroupForm}
          />
        </Card>
      )}
      <DataGrid
        disableVirtualization
        columns={columns}
        rows={rows}
        getRowId={(row) => row.userId}
        pageSizeOptions={constants.PAGE_SIZE_OPTIONS}
        initialState={{
          pagination: { paginationModel: constants.PAGINATION_MODEL },
        }}
        slots={{
          toolbar: () => (
            <CustomToolbar
              handleFilterClick={handleFilterClick}
              isDisplaySortOptions
              handleSortOptionChange={(selectedOption) =>
                handleSortOptionChange(selectedOption)
              }
              sortOptionValue={selectedSortOption}
            />
          ),
          noRowsOverlay: () => (
            <NoRowsOverlay
              hasAccess={hasPermission('users.summary', 'read')}
              name="Users"
            />
          ),
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        autoHeight
        sx={{
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: '600',
          },
        }}
        checkboxSelection={props.showAddGroupForm}
        isRowSelectable={() => groupTypeSwitchValue === UserGroupType.STATIC}
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
        rowSelectionModel={rowSelectionModel}
        disableRowSelectionOnClick
      />
      {openGroupTypeDialogue && (
        <UserGroupTypeDialog
          dialogContent={
            groupTypeSwitchValue === UserGroupType.STATIC
              ? constants.STATIC_GROUP_CONFIRM_MESSAGE
              : constants.DYNAMIC_GROUP_CONFIRM_MESSAGE
          }
          onConfirmType={onConfirmType}
          open={openGroupTypeDialogue}
          onCancel={() => {
            setOpenGroupTypeDialogue(false);
          }}></UserGroupTypeDialog>
      )}
    </>
  );
};
export default UsersTable;
