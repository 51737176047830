import React from 'react';
import { Controller, useFormContext, Control } from 'react-hook-form';
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
  Button,
} from '@mui/material';

interface SelectInputProps<T> {
  name: string;
  label: string;
  options: T[];
  optionKey: keyof T;
  optionLabel: keyof T;
  rules?: Record<string, any>;
  multiple?: boolean;
  defaultValue?: string | number;
  optionButtonClick: () => void;
  buttonIcon: React.ReactNode;
  buttonLabel: string;
  optionButtonDisabled?: boolean;
}

const TcSelectWithButtonLastOption = <T extends Record<string, any>>({
  name,
  label,
  options,
  optionKey,
  optionLabel,
  rules,
  multiple = false,
  defaultValue = '',
  optionButtonClick,
  buttonIcon,
  buttonLabel,
  optionButtonDisabled = false,
}: SelectInputProps<T>) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const isError = Boolean(errors[name]);

  return (
    <FormControl fullWidth variant="outlined" error={Boolean(errors[name])}>
      <InputLabel size="small">{label}</InputLabel>
      <Controller
        name={name}
        control={control as Control}
        rules={rules}
        defaultValue={defaultValue}
        render={({ field }) => (
          <Select
            data-testid={name}
            label={label}
            size="small"
            value={field.value}
            onChange={field.onChange}
            multiple={multiple}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: '200px',
                  overflowY: 'auto',
                },
              },
            }}>
            {options.map((option) => (
              <MenuItem key={option[optionKey]} value={option[optionKey]}>
                {option[optionLabel]}
              </MenuItem>
            ))}
            <MenuItem>
              <Button
                onClick={optionButtonClick}
                color="info"
                startIcon={buttonIcon}
                disabled={optionButtonDisabled}>
                {buttonLabel}
              </Button>
            </MenuItem>
          </Select>
        )}
      />
      {isError && (
        <FormHelperText error>
          {errors[name] ? (errors[name]?.message as unknown as string) : ''}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default TcSelectWithButtonLastOption;
