import React from 'react';
// import PowerSettingsNew from '@mui/icons-material/PowerSettingsNew';
import {
  List,
  // ListItemText,
  // ListItemIcon,
  // Divider,
  // ListItemButton,
  Box,
} from '@mui/material';
import SidebarItem from './SidebarItem';
import SidebarItemCollapse from './SidebarItemCollapse';
import assets from '../../assets';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import { constants } from 'common/constants';
// import { DrawerHeader } from './layout/MainLayout';
// import useAuth from 'pages/login/authService';
import useAppRoutes from 'routes/useAppRoutes';
import { DrawerHeader } from 'common/styles/styledComponents';

type SidebarProps = {
  open: boolean;
  handleDrawerClose: () => void;
};

const openedMixin = (theme: Theme): CSSObject => ({
  width: constants.DRAWER_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  background: 'primary.main',
  color: 'white',
  border: '0px',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  border: '0px',
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: constants.DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
  background: 'primary.main',
  color: 'white',
}));

const Sidebar = ({ open, handleDrawerClose }: SidebarProps) => {
  // const { logout } = useAuth();
  const { filteredAppRoutes } = useAppRoutes();
  return (
    <React.Fragment>
      <Drawer
        variant="permanent"
        open={open}
        sx={{
          '& .MuiDrawer-paper': {
            backgroundColor: 'primary.main',
            color: '#ffffff',
            overflowY: 'hidden',
          },
        }}>
        <DrawerHeader>
          {open && (
            <img
              src={assets.images.orangeLogo}
              alt="Your Logo"
              onClick={handleDrawerClose}
              style={{ cursor: 'pointer' }}
            />
          )}
        </DrawerHeader>
        <Box component="nav">
          <List disablePadding={true}>
            {filteredAppRoutes.map((route, index) => {
              if (!route.sidebarProps) {
                return null;
              }
              if (route.child && open) {
                return (
                  <SidebarItemCollapse
                    item={route}
                    key={`route_${route.state}_${index}`}
                  />
                );
              }
              return (
                <SidebarItem
                  item={route}
                  key={`route_${route.state}_${index}`}
                />
              );
            })}
            {/* <Divider sx={{ background: '#ffffff', marginTop: 20 }} /> */}
            {/* <ListItemButton
              sx={{
                '&:hover': {
                  backgroundColor: 'secondary.light', //ColorConfigs.sidebar.hoverBg,
                },
                paddingY: 1,
                paddingX: 3,
              }}
              onClick={() => logout()}>
              <ListItemIcon
              >
                <PowerSettingsNew sx={{ color: '#ffffff' }} />
              </ListItemIcon>
              <ListItemText
                // sx={{ color: '#ffffff' }}
                primary={'Sign Out'}></ListItemText>
            </ListItemButton> */}
          </List>
        </Box>
      </Drawer>
    </React.Fragment>
  );
};

export default Sidebar;
