import React, { FC, useState } from 'react';
import { Slider, TextField, Grid, SliderProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Controller, useFormContext } from 'react-hook-form';

const useStyles = makeStyles({
  root: {
    marginTop: 5,
  },
});

type TcSliderProps = {
  name: string;
  rules?: Record<string, any>;
  defaultValues: number[];
} & SliderProps;

const TcSlider: FC<TcSliderProps> = ({
  name,
  rules,
  min,
  max,
  step,
  defaultValues,
  ...otherProps
}) => {
  const { control, setValue } = useFormContext();

  const classes = useStyles();
  const [values, setValues] = useState<number[]>(defaultValues);
  const [minValue, maxValue] = values;

  const handleChange = (event: Event, newValue: number | number[]) => {
    setValues(newValue as number[]);
  };

  const handleMinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      const newValue = [parseInt(event.target.value, 10) ?? minValue, maxValue];
      setValues(newValue);
    } else {
      setValues([0, maxValue]);
    }
  };

  const handleMaxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      const newValue = [minValue, parseInt(event.target.value, 10) ?? maxValue];
      setValues(newValue);
    } else {
      setValues([minValue, 0]);
    }
  };

  React.useEffect(() => {
    setValue(name, values);
  }, [name, setValue, values]);

  React.useEffect(() => {
    setValues(defaultValues);
  }, [defaultValues]);

  return (
    <div className={classes.root}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValues}
        render={({ field }) => (
          <Slider
            {...field}
            {...otherProps}
            value={values}
            onChange={handleChange}
            valueLabelDisplay="auto"
            min={min}
            valueLabelFormat={(value) => `${value}`}
            step={step}
            marks
            max={max}
          />
        )}
        rules={rules}
      />
      <Grid container spacing={4}>
        <Grid item>
          <TextField
            type="number"
            label="Min"
            variant="outlined"
            value={minValue}
            size="small"
            onChange={handleMinChange}
          />
        </Grid>
        <Grid item>
          <TextField
            type="number"
            label="Max"
            variant="outlined"
            size="small"
            value={maxValue}
            onChange={handleMaxChange}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default TcSlider;
